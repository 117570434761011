import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AppModules } from '../../../../../../utils/const';
import { LocalStorageKeys, RequestState } from '../../../../../../utils/enums';
import { LocalStorageService } from '../../../../../../utils/services';
import type { TVerifyPhoneForm } from '../../../types/types';
import { AuthRoutePath } from '../../../utils/const/route-path';
import { useForgotPass } from '../../../utils/hooks/useForgotPass';

export function useHandlers(): { requestState: RequestState; handleSubmit: (data: TVerifyPhoneForm) => void } {
  const history = useHistory();
  const [requestState, onForgotPass] = useForgotPass();

  const handleSubmit = useCallback(
    async ({ phone, code, ...data }: TVerifyPhoneForm): Promise<void> => {
      const response: { data?: string; error?: string } = await onForgotPass({
        phone_number: `+${code}${phone}`,
        ...data,
        language: code === `380` ? 'uk' : 'en',
      });

      if (response.error) {
        return;
      }

      LocalStorageService.setItem(LocalStorageKeys.Code, code);
      LocalStorageService.setItem(LocalStorageKeys.Phone, phone);
      LocalStorageService.setItem(LocalStorageKeys.PhoneNumber, `+${code}${phone}`);
      history.push(`/${AppModules.auth}/${AuthRoutePath.NEW_PASSWORD}`);
    },
    [history, onForgotPass],
  );

  return { handleSubmit, requestState };
}
