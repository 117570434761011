import { useCallback } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../../utils/atoms';
import { AppModules } from '../../../../../utils/const';
import { LocalStorageKeys, RequestState } from '../../../../../utils/enums';
import { useIsMounted } from '../../../../../utils/hooks';
import { LocalStorageService } from '../../../../../utils/services';
import type { TSignupForm } from '../../types/types';
import { AuthRoutePath } from '../../utils/const/route-path';
import { useRegister } from '../../utils/hooks/useRegister';

export function useHandlers(): { handleSubmit: (data: TSignupForm) => Promise<void>; registerStatus: RequestState } {
  const isMounted = useIsMounted();
  const [registerStatus, onRegister] = useRegister();
  const history = useHistory();
  const handleError = useErrorHandler();
  const [, setToken] = useRecoilState(tokenState);

  const handleSubmit = useCallback(
    async ({ phone, code, ...data }: TSignupForm): Promise<void> => {
      const reg = await onRegister({
        phone_number: `+${code}${phone}`,
        ...data,
        language: code === `380` ? 'uk' : 'en',
      });
      if (!isMounted()) {
        return;
      }

      if (!!reg.error) {
        handleError(reg.error);

        return;
      }
      const { access, refresh } = reg.data;
      setToken((): { access: string; refresh: string } => ({ access, refresh }));
      LocalStorageService.setItem(LocalStorageKeys.Code, code);
      LocalStorageService.setItem(LocalStorageKeys.Phone, phone);
      LocalStorageService.setItem(LocalStorageKeys.PhoneNumber, `+${code}${phone}`);
      LocalStorageService.setItem(LocalStorageKeys.Access, reg.data.access);

      history.push(`/${AppModules.auth}/${AuthRoutePath.CODE_VERIFY}`);
    },

    [handleError, history, isMounted, onRegister, setToken],
  );

  return { handleSubmit, registerStatus };
}
