import React, { memo, useState } from 'react';
import { useData, useHandlers } from './hooks';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { CalendarWidgetMemo } from '../../../shared';
import { Container } from '@mui/material';
import { PetCalendarEventMemo } from '../PetCalendarEvent';
import { PetEventTypeSelectorContainer } from '../PetToSimilarEventButton/PetEventTypeSelectorContainer';
import { PetTab } from '../../enums';
import type { TCalendarEvent } from '../../types';
import add_button_icon from '../../../../../assets/images/pet/add_button.png';
import { styled } from '@mui/material';
import { useParams } from 'react-router';

export const StyledContainer = styled(Container)(({ theme }) => ({
  padding: '0 16px',
}));

export const PetCalendarMemo: React.FC = memo(function PetCalendar() {
  const params = useParams<{ id: string }>();
  const petId = params.id;
  const [needToCheckData, setNeedToCheckData] = useState<boolean>(false);
  const [needToCheckMonthData, setNeedToCheckMonthData] = useState<boolean>(false);

  const {
    onEventsBtnText,
    onMenuBtnText,
    noEventsText,
    dateValue,
    setDateValue,
    setYearMonth,
    loadingEventsText,
    isLastEventThisDay,
    transformedEvents,
    transformedMonthData,
    isCalendarLoad,
  } = useData({
    petId,
    needToCheckData,
    needToCheckMonthData,
    setNeedToCheckMonthData,
    setNeedToCheckData,
  });

  const [isAddEventShow, setIsAddEventShow] = useState<boolean>(false);
  const [btnText, setBtnText] = useState(onEventsBtnText);

  const { handleAddEvent, handleDayChanging } = useHandlers({
    isAddEventShow,
    setIsAddEventShow,
    setBtnText,
    onEventsBtnText,
    onMenuBtnText,
    initialValue: dateValue,
    setDateValue,
    setNeedToCheckData,
  });

  const isLastEvent = transformedEvents.length === 1;

  const petExistingEvents =
    transformedEvents === 'empty' ? (
      <Box display='flex' flexDirection='column' alignItems='center'>
        {noEventsText}
      </Box>
    ) : (
      <>
        {transformedEvents.map(({ key, time, date, icon, title, id, eventType }: TCalendarEvent) => {
          return (
            <PetCalendarEventMemo
              key={id}
              keyInfo={key}
              time={time}
              date={date}
              icon={icon}
              title={title}
              id={id}
              eventType={eventType}
              isOnlyOne={isLastEventThisDay}
              setNeedToCheckData={setNeedToCheckData}
              setNeedToCheckMonthData={setNeedToCheckMonthData}
              isLastEvent={isLastEvent}
              currentDay={dateValue}
            />
          );
        })}
        <Box borderTop='0.5px solid #a1a4b2'></Box>
      </>
    );
  return (
    <StyledContainer>
      {/* <RangeCalendarWidget /> */}
      <CalendarWidgetMemo
        isFormPart={false}
        handleDayChanging={handleDayChanging}
        hasSpecialMarks={true}
        eventdays={transformedMonthData}
        setYearMonth={setYearMonth}
        setDateValue={setDateValue}
        loading={isCalendarLoad}
        value={dateValue}
      />
      <Button variant='eventToggler' onClick={handleAddEvent}>
        <img src={add_button_icon} alt='add button' />
        <span>{btnText}</span>
      </Button>{' '}
      {isAddEventShow ? (
        <PetEventTypeSelectorContainer petId={petId} tabState={PetTab.CALENDAR} dateValue={dateValue} />
      ) : isCalendarLoad ? (
        <Box>{loadingEventsText}</Box>
      ) : (
        <Box margin='0 -17px 6px -17px'>{petExistingEvents}</Box>
      )}
    </StyledContainer>
  );
});
