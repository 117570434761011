import { IconButton } from '@mui/material';
import type { ReactElement } from 'react';
import React from 'react';
import GoogleLogin from 'react-google-login';
import googleLogo from '../../../../../../../assets/images/google_logo.svg';
import { useHandlers } from './useHandlers';

const clientId = '842584830154-9tnlomq92bq8sp10lhlrmp08kli5sgcl.apps.googleusercontent.com';

export function CustomGoogleLogin(): ReactElement {
  const { onSuccess, onFailure } = useHandlers();

  return (
    <GoogleLogin
      clientId={clientId}
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy='single_host_origin'
      // tslint:disable-next-line:typedef
      render={renderProps => (
        <IconButton onClick={renderProps.onClick} size='large' sx={{ background: 'rgba(243, 247, 248, 1)' }}>
          <img src={googleLogo} alt='google login button' width='25px' />
        </IconButton>
      )}
    />
  );
}
