import {
  PETS,
  PET_ALLERGY,
  PET_SERVICES,
  PET_TREATMENT,
  PET_VACCINATION,
  PET_VETERINARIAN,
} from '../../../requestKeys';
import type { TPath, TUseData } from '../types';
import { useCallback, useMemo } from 'react';

import type { InitialValues } from '../../../types';
import { PetProfileRoutePath } from '../../../utils/enums/routes-path';
import { PetTab } from '../../../enums';
import { useTranslation } from 'react-i18next';

const path: TPath = {
  allergy: PET_ALLERGY,
  vaccination: PET_VACCINATION,
  treatment: PET_TREATMENT,
  veterinarian: PET_VETERINARIAN,
  services: PET_SERVICES,
};

export function useData({ location, type, details, selectedDate }: TUseData) {
  const { t } = useTranslation();
  const placeholderText = t('PetProfilePage.commonEventForm.time');
  const submitBtnText = t('PetProfilePage.commonEventForm.save');
  const timeSetFieldName = 'timeSetField';
  const calendarWidgetName = 'dateField';
  const requestKey = path[type] ?? PETS;

  const getInitialValues = useCallback((): object => {
    const initialValuesObj: InitialValues = {};
  const eventTimeDefaultValue = 600;

    initialValuesObj.dateField = selectedDate || new Date();

    if (details.addPageSelectFields?.length) {
      details.addPageSelectFields.forEach(({fieldName})=> {
        initialValuesObj[fieldName] = '';
      })
    }

    if (details.addPageInputFields?.length) {
      details.addPageInputFields.forEach(({ fieldName }) => {
        initialValuesObj[fieldName] = '';
      });
    }

    if (details.addPageTimeSetField) {
      initialValuesObj.timeSetField = eventTimeDefaultValue;
    }

    if (details.addPageTextareaFields?.length) {
      details.addPageTextareaFields.forEach(({ fieldName }) => {
        initialValuesObj[fieldName] = '';
      });
    }

    return initialValuesObj;
  }, [selectedDate, details.addPageSelectFields, details.addPageInputFields, details.addPageTimeSetField, details.addPageTextareaFields]);

  const initialValues = getInitialValues();

  const locationStateFrom: string = useMemo(() => {
    const previousLocation =
      location === PetProfileRoutePath.ALLERGIES ||
      PetProfileRoutePath.VET ||
      PetProfileRoutePath.VACCINATION ||
      PetProfileRoutePath.TREATMENT ||
      PetProfileRoutePath.SERVICES ||
      PetTab.CALENDAR
        ? location
        : PetTab.CALENDAR;

    return previousLocation;
  }, [location]);

  return {
    placeholderText,
    submitBtnText,
    timeSetFieldName,
    calendarWidgetName,
    locationStateFrom,
    requestKey,
    initialValues,
  };
}
