import { ThemeProvider } from '@mui/material';
import React, { ComponentType, ReactElement, Suspense } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { theme } from './MUITheme';
import AuthRoutes from './pwa/modules/authentication/routes';
import ChatRoutes from './pwa/modules/chat/routes';
import ContactsRoutes from './pwa/modules/contacts/routes';
import DashboardRoutes from './pwa/modules/dashboard/routes';
import ErrorRoutes from './pwa/modules/errors/routes';
import PetProfileRoutes from './pwa/modules/pet/routes';
import ProfileRoutes from './pwa/modules/profile/routes';
import { FallbackErrorBoundary, HistoryListener, Loader } from './pwa/modules/shared';
import { Notification, Popup, TranslationWrapper } from './pwa/modules/shared/components';
import RouteGuard from './pwa/modules/shared/components/RouteGuard';
import StartPage from './pwa/modules/shared/views/Start/StartPage';

// tslint:disable-next-line:typedef
const RegularRouter = () => {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary FallbackComponent={FallbackErrorBoundary as ComponentType<FallbackProps>}>
        <TranslationWrapper>
          <Router>
            <HistoryListener />
            <RecoilRoot>
              <Suspense fallback={<Loader />}>
                <Switch>
                  <RouteGuard path={`/start`} Component={StartPage} />
                  <Route path='/auth' render={({ match: { url } }): ReactElement => <AuthRoutes url={url} />} />
                  <Route
                    path='/dashboard'
                    render={({ match: { url } }): ReactElement => <DashboardRoutes url={url} />}
                  />
                  <Route path='/profile' render={({ match: { url } }): ReactElement => <ProfileRoutes url={url} />} />
                  <Route path='/contacts' render={({ match: { url } }): ReactElement => <ContactsRoutes url={url} />} />
                  <Route path='/errors' render={({ match: { url } }): ReactElement => <ErrorRoutes url={url} />} />
                  <Route path='/chat' render={({ match: { url } }): ReactElement => <ChatRoutes url={url} />} />
                  <Route path='/pet' render={({ match: { url } }): ReactElement => <PetProfileRoutes url={url} />} />
                  <Redirect exact from='/' to='/start' />
                  <Redirect from={'*'} to={'/'} />
                </Switch>
              </Suspense>
              <Popup />
              <Notification />
            </RecoilRoot>
          </Router>
        </TranslationWrapper>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default RegularRouter;
