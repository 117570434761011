import i18n from 'i18next';
import React, { useCallback } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../../utils/atoms';
import { LocalStorageKeys, RequestState } from '../../../../../utils/enums';
import { useIsMounted } from '../../../../../utils/hooks';
import { LocalStorageService, setAuthToken } from '../../../../../utils/services';
import type { CodeState } from '../../types/types';
import { useCodeVerify } from '../../utils/hooks/useCodeVerify';
import { updateLocalStorageAfterAuthData } from '../../utils/hooks/useUpdateLocalStorageAfterAuthData';

export function useHandlers(code: CodeState): {
  handleSubmit: (e: React.MouseEvent) => Promise<void>;
  verifyStatus: RequestState;
} {
  const isMounted = useIsMounted();
  const [verifyStatus, onCodeVerify] = useCodeVerify();
  const history = useHistory();
  const handleError = useErrorHandler();
  const [, setToken] = useRecoilState(tokenState);
  const phone = LocalStorageService.getItem(LocalStorageKeys.PhoneNumber) || '';

  const handleSubmit = useCallback(
    async (e: React.MouseEvent): Promise<void> => {
      e.preventDefault();
      const response = await onCodeVerify({
        code: Object.values(code).join(''),
        phone_number: phone,
        language: String(phone).slice(0, 4) === `+380` ? 'uk' : 'en',
      });

      if (!isMounted()) {
        return;
      }

      if (!!response.error) {
        handleError(response.error);
        return;
      }
      const { access, refresh, language } = response.data;
      i18n.changeLanguage(language);
      setToken((): { access: string; refresh: string } => ({ access, refresh }));
      setAuthToken(access);
      updateLocalStorageAfterAuthData(
        { is_phone_number_verified: 'True', phone_number: phone, ...response.data },
        history,
      );
    },
    [code, handleError, history, isMounted, onCodeVerify, phone, setToken],
  );

  return { handleSubmit, verifyStatus };
}
