export default function formatDateByLocale(dateString: string, locale: any) {
  const date = new Date(dateString);

  const day = date.toLocaleString(locale, { day: '2-digit' });
  const month = date.toLocaleString(locale, { month: '2-digit' });
  const year = date.toLocaleString(locale, { year: 'numeric' });

  if (locale === 'uk' || locale === 'ru') {
    return `${day}-${month}-${year}`;
  } else {
    return `${year}-${month}-${day}`;
  }
}
