import { transformTimeToNumb  } from './transformTimeAndDate'
import { format } from 'date-fns';

export function isDateValid(time: number, date: string): boolean {
  const now = new Date()

  const [currentDate, currentTime] = format(now, 'yyyy-MM-dd/HH:mm').split('/')
  const currentNumberTime = transformTimeToNumb(currentTime)

  // Dates compare by line
  if(date < currentDate) return false

  // For time compare numbers (number of minutes since the beginning of the day) 
  if(date === currentDate 
    && time < currentNumberTime) {
      const inequality = currentNumberTime - time

      return inequality <= 15
  }
  return true
} 