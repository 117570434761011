import type { TOneTypeEventsData, TOneTypeEventsRequestData } from '../../../types';

import { getRequestKey } from '../../../helpFunctions';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useData({ type, petId }: TOneTypeEventsData) {
  const { t } = useTranslation();
  const altText = t(`PetProfilePage.commonEventForm.add}`) + t(`PetProfilePage.${type}Form.buttonAlt}`);
  const title = t(`PetProfilePage.${type}Form.pageTitle`);
  const requestKey = useMemo(() => getRequestKey(type, 'getOneType'), [type]);
  const requestData: TOneTypeEventsRequestData = useMemo(() => {
    return {
      pet_id: petId,
      limit: 20,
      offset: 0,
    };
  }, [petId]);

  return { title, requestKey, altText, requestData };
}
