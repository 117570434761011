import './PetSimilarEvent.scss';

import { memo, useMemo } from 'react';

import type { PetSimilarEventProps } from '../../types';
import { useParams } from 'react-router';
import { usePetEventHandlers } from '../../hooks';
import { useData } from './useData';

export const PetSimilarEventMemo: React.FC<PetSimilarEventProps> = memo(function PetSimilarEvent({
  title,
  timeName,
  timeAndDate,
  avatar,
  path: keyInfo,
  type: eventType,
  id: eventId,
}: PetSimilarEventProps) {
  const params = useParams<{ petId: string }>();
  const petId = params.petId;
  const { date, time } = useData({ timeAndDate });
  const { handleDeleteEvent, handleEditEvent, onEventClickHandler } = usePetEventHandlers({
    eventId,
    keyInfo,
    date,
    petId,
    eventType,
  });

  return (
    <div className='event-container'>
      <div className='event-container-info' onClick={onEventClickHandler}>
        {avatar ? (
          <div className='event-container-avatar'>{avatar !== 'none' && <img src={avatar} alt='avatar' />}</div>
        ) : null}
        <div className='event-container-content'>
          <p className='event-container-title'>{title}</p>
          <div className='event-container-details'>
            <span className='details-name'>{timeName}</span>
            <span className='details-value'>
              {date} {time}
            </span>
          </div>
        </div>
      </div>
      <div className='event-container-buttons'>
        <button className='event-button-edit' onClick={handleEditEvent}></button>
        <button className='event-button-delete' onClick={handleDeleteEvent}></button>
      </div>
    </div>
  );
});
