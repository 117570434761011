import { useTranslation } from 'react-i18next';
import {WithFooterMenu} from '../../../shared';
import { HeaderWithSearch } from '../../components/HeaderWithSearch';
import { PetEventForm } from '../../components/PetEventForm/PetEventForm';
import type { PetEventDetailsProps } from '../../types';
import './CreatePetEvent.scss';

export const CreatePetEvent = ({ eventDetails }: PetEventDetailsProps ) => {
  const { t } = useTranslation();
  return (
    <WithFooterMenu>
      <HeaderWithSearch
        title={ 
          t(`PetProfilePage.${eventDetails.type}Form.addPageTitle`)
        }
        path={eventDetails.pathForBack}
        withoutSearch={true}>
      </HeaderWithSearch>
      <div className='add-event'>
        <PetEventForm eventDetails={eventDetails}/>
      </div>
    </WithFooterMenu>
  );
};
