import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AbsoluteRoutes } from '../../../../../../utils/enums';

export function useRedirect({ paramsId }: { paramsId?: string }): {
  redirectToDashboard: () => void;
  redirectToEditPetProfile: () => void;
} {
  const history = useHistory();

  const redirectToDashboard = useCallback(() => {
    history.push(AbsoluteRoutes.Dashboard);
  }, [history]);

  const redirectToEditPetProfile = useCallback(() => {
    history.push(`${AbsoluteRoutes.EditPet}/${paramsId}`);
  }, [history, paramsId]);

  return { redirectToDashboard, redirectToEditPetProfile };
}
