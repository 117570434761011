import { Formik } from 'formik';
import type { ReactElement } from 'react';
import { RequestState } from '../../../../../utils/enums';
import { PageLoader } from '../../../shared';
import { AuthContent } from '../../components/AuthContent/AuthContent';
import type { TVerifyPhoneForm } from '../../types/types';
import { usePhoneValidationSchema } from '../../utils/validationSchemas/PhoneValidationSchema';
import { VerifyPhoneForm } from './components/VerifyPhoneForm/VerifyPhoneForm';
import { useData } from './useData';
import { useHandlers } from './useHandlers';

export const VerifyPhonePage = (): ReactElement => {
  const PhoneValidationSchema = usePhoneValidationSchema();
  const { handleSubmit, requestState } = useHandlers();
  const { initialValues } = useData();

  return (
    <>
      <AuthContent translationRootPath={'verifyPhonePage'} prevUrl={'/start'} content={'phone-page'}>
        <Formik<TVerifyPhoneForm>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={PhoneValidationSchema}>
          <VerifyPhoneForm requestState={requestState}></VerifyPhoneForm>
        </Formik>
      </AuthContent>
      {requestState === RequestState.Pending && <PageLoader />}
    </>
  );
};
