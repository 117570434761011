import { Button } from '@mui/material';
import { useDeletePet } from '../../hooks';
import { RequestState } from '../../../../../../../utils/enums';
import { PageLoader } from '../../../../../shared';
import { useTranslation } from 'react-i18next';

export function DeletePetButton({ petId }: { petId: number }) {
  const { t } = useTranslation();
  const { handleDeletePet, requestStatus } = useDeletePet({ petId: petId });
  const handleClick = () => {
    handleDeletePet();
  };

  return (
    <>
      <Button variant='customDeleteButton' onClick={handleClick}>
        {t('petForm.delete')}
      </Button>
      {requestStatus === RequestState.Pending && <PageLoader />}
    </>
  );
}
