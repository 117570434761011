import { Box, Stack, Typography, useTheme } from '@mui/material';
import { type PropsWithChildren, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import header_logo from '../../../../../assets/images/header_img.png';
import { useGoBack } from '../../../../../utils/hooks';
import { useCheckToken } from '../../../../../utils/hooks/useCheckToken';
import { BackButton } from '../../../shared';
import { useCheckLoggedInOnMount, useData } from '../../../shared/views/Start/hooks';

type AuthContentProps = PropsWithChildren<{
  translationRootPath: string;
  prevUrl: string;
  content: string;
  clearContent?: () => void;
}>;

export function AuthContent(props: AuthContentProps): ReactElement {
  const { children, translationRootPath, prevUrl, clearContent } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    backgroundImg,
    contentBorderRadius,
    backgroundSettings,
    contentBackground,
    phoneVerified,
    actionsBackground,
    contentPosition,
  } = useData();
  const { tokenVerified, tokenVerificationState } = useCheckToken();
  const goBack = useGoBack(prevUrl, clearContent);
  useCheckLoggedInOnMount(tokenVerificationState, tokenVerified, phoneVerified);
  return (
    <Box position='relative' height='100vh'>
      <Box left={16} position='absolute' top={16} zIndex={100}>
        <BackButton goBack={goBack} />
      </Box>
      <Box
        alignItems='center'
        display='flex'
        height='100vh'
        justifyContent='center'
        position='absolute'
        width='100vw'
        zIndex={0}>
        <img src={backgroundImg} alt='background' style={backgroundSettings} />
      </Box>
      <Stack
        gap={1}
        display='flex'
        flexDirection='column'
        margin={0}
        height='100%'
        padding={2}
        position={contentPosition}
        right={0}
        sx={contentBackground}
        top={0}
        width='100%'
        maxWidth={400}>
        <Box height='20%'>
          <Stack alignItems='center' direction='row' gap={2.2} justifyContent='center' marginTop='18%'>
            <img src={header_logo} alt='logo' />
            <Typography color={theme.palette.text.primary} fontFamily='Nunito' fontSize={41} fontWeight={800}>
              PETSLIFE
            </Typography>
          </Stack>
        </Box>
        <Box display='flex' alignItems='center' height='80%'>
          <Stack
            borderRadius={contentBorderRadius}
            display='block'
            gap={1.6}
            overflow='auto'
            padding={1.6}
            sx={actionsBackground}
            width='100%'
            maxHeight='100%'>
            <Typography color={theme.palette.text.primary} fontFamily='Nunito' fontSize={27} fontWeight={700}>
              {t(`${translationRootPath}.title`)}
            </Typography>
            {children}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
}
