import React, { ReactElement } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { DashboardRoutePath } from './utils/const/route-path';
import { Dashboard } from './views/Dashboard/DashboardPage';
import RouteGuard from '../shared/components/RouteGuard';

type PropsType = { url: string };

const DashboardRoutes = ({ url }: PropsType): ReactElement => {
  return (
    <>
      <Switch>
        <RouteGuard path={`${url}/${DashboardRoutePath.DASHBOARD}`} Component={Dashboard} />
        <Redirect from={'*'} to={'/'} />
      </Switch>
    </>
  );
};

export default DashboardRoutes;
