import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

// tslint:disable-next-line:typedef
export const useProfileUpdateValidationSchema = () => {
    const {t} = useTranslation();
    // tslint:disable-next-line:typedef
    return useMemo(() => {
        return Yup.object().shape({
            first_name: Yup.string()
                .min(2, t('validations.min'))
                .max(20, t('validations.max'))
                .matches(/^[A-Za-zа-яА-ЯіІїЇёЁъЪЫыєЄґҐ]+([-\s'`_][A-Za-zа-яА-ЯіІїЇёЁъЪЫыєЄґҐ]+)*$/, t('validations.name')),
            last_name: Yup.string()
                .min(2, t('validations.min'))
                .max(30, t('validations.max'))
                .matches(/^[A-Za-zа-яА-ЯіІїЇёЁъЪЫыєЄґҐ]+([-\s'`_][A-Za-zа-яА-ЯіІїЇёЁъЪЫыєЄґҐ]+)*$/, t('validations.name')),
            email: Yup.string().email(t('validations.email')).matches(/^(?!.*[а-яА-ЯіІїЇёЁъЪЫыєЄґҐ])/, t('validations.email')),
            birthday: Yup.date()
                .nullable()
        });
    }, [t]);
};
