import { format } from 'date-fns';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cities from '../../../../../assets/i18n/cities.json';
import { EN_LOCALE_DATE_FORMAT, UK_LOCALE_DATE_FORMAT } from '../../../../../utils/const';
import type {
  TCityAndCountry,
  TCityData,
  TRadioButtonGroupOptions,
  TRadioButtonGroupValue,
  TUserData,
} from '../../utils/types/types';

const USER_LOCALE = navigator.language;

export function useData(user: TUserData): {
  formattedBirthday: string;
  radioButtonGroupOptions: TRadioButtonGroupOptions[];
  cityAndCountry: TCityAndCountry;
  radioButtonGroupValue: TRadioButtonGroupValue;
} {
  const { t } = useTranslation();
  const formattedBirthday = useMemo((): string => {
    return user.birthday
      ? USER_LOCALE === 'uk' || USER_LOCALE === 'ru'
        ? format(new Date(user.birthday), UK_LOCALE_DATE_FORMAT)
        : format(new Date(user.birthday), EN_LOCALE_DATE_FORMAT)
      : '';
  }, [user]);

  const cityAndCountry = useMemo((): TCityAndCountry => {
    const city = cities.city.data.find((i: TCityData): boolean => i.id === user.city);

    return {
      country: city?.country === 224 ? t('ProfilePage.ukraine') : '',
      // @ts-ignore
      city: city ? city[`name_${USER_LOCALE}`] : '',
    };
  }, [t, user.city]);
  const radioButtonGroupOptions = useMemo((): TRadioButtonGroupOptions[] => {
    return [
      { value: 'male', label: t('ProfilePage.placeholders.male') },
      { value: 'female', label: t('ProfilePage.placeholders.female') },
    ];
  }, [t]);
  const radioButtonGroupValue = useMemo((): TRadioButtonGroupValue => {
    return {
      value: user?.gender || '',
    };
  }, [user?.gender]);

  return { formattedBirthday, radioButtonGroupOptions, cityAndCountry, radioButtonGroupValue };
}
