import i18next from 'i18next';
import type { ReactElement } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import card_on_dashboard_slider from '../../../../../assets/images/dashboard/card_on_dashboard_slider.png';
import square_default_pet_avatar from '../../../../../assets/images/pet/square_default_pet_avatar.svg';
import { AppModules } from '../../../../../utils/const/modules';
import useGetUser from '../../../../../utils/hooks/useGetUser';
import { notificationSelector } from '../../../../../utils/selectors';
import { AdvHorizontal, DashboardCard, Header, PageLoader, WithFooterMenu } from '../../../shared';
import { useFetchPets } from '../../hooks/useFetchPets';
import { useHandlers } from '../../hooks/useHandlers';
import { DashboardSlider } from '../../slider/DashboardSlider';
import type { TDashboardItem } from '../../slider/types';
import { DashboardRoutePath } from '../../utils/const/route-path';
import './DashboardPage.scss';
import type { TPet } from '../../types';
import { RequestState } from '../../../../../utils/enums';

// @ts-ignore
export const Dashboard = ({ location }): ReactElement => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const petSliderContainerRef = useRef<any>(null);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const language = localStorage.getItem('language');
  const handleError = useErrorHandler();

  const { userData } = useGetUser();
  const [user, setUser] = useState(null);

  const sliderWidth = petSliderContainerRef.current?.offsetWidth;

  const { pets = [], fetchPets, requestState } = useFetchPets();

  const { handleAddPetClick, handleGoToPetPage, handleDeletePet, handleLimitNotification } = useHandlers({ fetchPets });

  useEffect((): (() => void) => {
    const handleResize = (): void => {
      setIsSmallScreen(window.innerHeight <= 700);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const sliderItems = useMemo((): TDashboardItem[] => {
    const petsForSlider: TDashboardItem[] = pets.map((p: TPet): TDashboardItem => {
      const { avatar, name, id } = p;
      return {
        imageSrc: avatar || square_default_pet_avatar,
        text: name,
        petId: id,
        onClick: (): void => {
          handleGoToPetPage(id);
        },
        backgroundPosition: avatar || avatar === square_default_pet_avatar ? 'center' : 'unset',
      };
    });

    if (pets.length === 10) {
      petsForSlider.unshift({
        imageSrc: card_on_dashboard_slider,
        opacity: 0.3,
        onClick: handleLimitNotification,
      });
    } else {
      petsForSlider.unshift({
        imageSrc: card_on_dashboard_slider,
        onClick: handleAddPetClick,
      });
    }

    if (petsForSlider.length % 2 !== 0) {
      petsForSlider.push({});
    }

    return petsForSlider;
  }, [pets]);

  useEffect((): void => {
    // @ts-ignore
    // tslint:disable-next-line:typedef
    i18n.changeLanguage(language).then(
      (): void => {},
      (error): void => {
        handleError(error);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n, language]);

  useEffect((): void => {
    const getUser = async (): Promise<void> => {
      try {
        // @ts-ignore
        // tslint:disable-next-line:typedef
        await userData().then(
          (value: any): void => {
            if (value.data) {
              setUser(value.data);
              localStorage.setItem('language', value.data.language);
              i18next.changeLanguage(value.data.language);
            }
            // tslint:disable-next-line:typedef
          },
          (error: unknown): void => {
            handleError(error);
          },
        );
      } catch (e) {
        console.log(e);
      }
    };
    // tslint:disable-next-line:typedef
    getUser().then(
      (): void => {},
      (error): void => {
        handleError(error);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header user={user} />
      <WithFooterMenu>
        <div className='dashboardPage'>
          <p className='dashboardPage-title'>{t('dashboard.pets')}</p>
          <div ref={petSliderContainerRef} className='dashboardCardsRowItemSlider'>
            {petSliderContainerRef?.current?.offsetWidth && (
              <div>
                {requestState === RequestState.Pending && <PageLoader />}
                <DashboardSlider items={sliderItems} sliderWidth={sliderWidth} onDelete={handleDeletePet} />
              </div>
            )}
          </div>
          <p className='dashboardPage-title dashboardPage-titleOwner'>{t('dashboard.owner')}</p>
          <div className='dashboardCardsRowItemContainer'>
            <div
              className='dashboardCardsRowItem'
              style={{
                width: `${sliderWidth / 2 - 40}px`,
                height: `${sliderWidth / 2 - 40}px`,
              }}>
              <DashboardCard obj={user} />
            </div>
          </div>
          <AdvHorizontal />
        </div>
      </WithFooterMenu>
    </>
  );
};
