import { useCallback, useState } from 'react';
import { useRecoilState } from 'recoil';
import { popupState } from '../../../../shared/components/Popup/utils/atoms/popup';
import delete_pet_icon from '../../../../../../assets/images/dashboard/delete_pet_icon.svg';
import { useTranslation } from 'react-i18next';
import { notificationSelector } from '../../../../../../utils/selectors';
import * as RequestService from '../../../../../../utils/services/request';
import { useErrorHandler } from 'react-error-boundary';
import { NotificationType, RequestState } from '../../../../../../utils/enums';
import { useIsMounted } from '../../../../../../utils/hooks';
import { useHistory } from 'react-router';
import { AbsoluteRoutes } from '../../../../../../utils/enums';
import { useTheme } from '@mui/material';
import { tokenState } from '../../../../../../utils/atoms';

export function useDeletePet({ petId }: { petId: number }) {
  const [, popupStateUpdate] = useRecoilState(popupState);
  const [token] = useRecoilState(tokenState)
  const [, addNotification] = useRecoilState(notificationSelector);
  const [requestStatus, setRequestStatus] = useState(RequestState.Idle);
  const isMounted = useIsMounted();
  const history = useHistory();
  const theme = useTheme();
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const handleGoBack = useCallback(() => {
    history.push(AbsoluteRoutes.Dashboard);
  }, [history]);

  const handleDeletePet = useCallback((): void => {
    popupStateUpdate({
      popup: {
        text: `${t('dashboard.deletePetNotificationText')}`,
        icon: delete_pet_icon,
        actions: [
          {
            text: t('Contacts.placeholders.cancel'),
            color: theme.palette.warning.light,
            onClick: () => {},
          },
          {
            text: t('Contacts.placeholders.yesDelete'),
            color: theme.palette.error.light,
            onClick: () => {
              handleDelete(petId);
            },
          },
        ],
      },
    });
  }, [popupStateUpdate, t, petId]);

  const handleDelete = useCallback(
    async (petId: number): Promise<void> => {
      setRequestStatus(RequestState.Pending);
      const response = await RequestService.remove('DELETE_PET', petId.toString(), token);
      if (!isMounted()) {
        setRequestStatus(RequestState.Idle);
        return;
      }
      if (response.error) {
        setRequestStatus(RequestState.Error);
        response.error.response.status === 400
          ? addNotification({
              title: response.error.response.status,
              text: t('appErrorNotification.trouble'),
              type: NotificationType.Error,
            })
          : handleError(response.error);
      }
      handleGoBack();
      setRequestStatus(RequestState.Success);
    },
    [addNotification, handleError, handleGoBack, t],
  );

  return { handleDeletePet, requestStatus };
}
