import { Field, useFormikContext } from 'formik';

import { CustomSelect } from '../../../../shared';
import type { PetSelectFieldProps } from '../../../types';
import type { ReactElement } from 'react';

export function PetFormikDropdownField({ selectFieldDetails, ...props }: PetSelectFieldProps): ReactElement {
  const { errors, touched } = useFormikContext<any>();
  if (!selectFieldDetails) {
    return <></>;
  }

  const { fieldName, required, options, title } = selectFieldDetails;
  
  return (
    <Field
      {...props}
      name={fieldName}
      defaultValue=''
      component={CustomSelect}
      required={required}
      choices={options}
      label={title}
      errors={touched[fieldName] && errors[fieldName]}
      errorType='transparent'
    />
  );
}
