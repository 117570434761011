import { useHistory } from 'react-router';
import type { TCreatePetData, TPetForm } from '../components/PetForm/types/types';
import { post } from '../../../../utils/services/request';
import { useCallback } from 'react';
import { createFormData } from '../../../../utils/helpers';
import { useRecoilState } from 'recoil';
import { notificationSelector } from '../../../../utils/selectors';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { tokenState } from '../../../../utils/atoms';

const convertArrayOfMinutesToHoursString = (minutesArray: number[]): string => {
  const formattedTime = minutesArray.map(minutes => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    const seconds = 0;

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(remainingMinutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  });

  return formattedTime.join(', ');
};

const prepareDataForRequest = (data: TPetForm): TCreatePetData => {
  if (!data.birth) {
    throw new Error('birthday is required');
  }

  if (!data.breed) {
    delete data.breed;
  }

  if (!data.avatar) {
    delete data.avatar;
  }

  return {
    ...data,
    food: data.food === 'feed' ? 'manufactured' : data.food,
    id_type: data.id_type?.toLowerCase() ?? '',
    birth: data.birth.toISOString().split('T')[0],
    outdoor_schedule: convertArrayOfMinutesToHoursString(data.outdoor_schedule),
    name: data.name.replace(/^[^a-zA-Zа-яА-ЯґҐєЄіІїЇ']+|[,-](?=[,-]+$)/g, '').trim(),
    color: data.color
      .replace(/^[^a-zA-Zа-яА-ЯґҐєЄіІїЇ']+|[,-](?=[,-]+$)|-+/g, '')
      .replace(/ {2,}/g, ' ')
      .trim(),
    special_signs: data.special_signs
      .replace(/^[^a-zA-Zа-яА-ЯґҐєЄіІїЇ\d']+|[,-](?=[,-]+$)|-+/g, '')
      .replace(/ {2,}/g, ' ')
      .trim(),
  };
};

export function useHandlers() {
  const { t } = useTranslation();
  const [, addNotification] = useRecoilState(notificationSelector);
  const handleError = useErrorHandler();
  const history = useHistory();

  const handleGoBack = useCallback(() => {
    history.push('/dashboard/main');
  }, [history]);

  const [token] = useRecoilState(tokenState);

  const handleSubmit = useCallback(
    async (data: TPetForm) => {
      try {
        const typeFormData = createFormData(prepareDataForRequest(data));

        await post('PETS', typeFormData, token);

        handleGoBack();
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          addNotification({
            title: error.response.status,
            text: t('appErrorNotification.trouble'),
            type: 'error',
          });
          return;
        }
        handleError(error);
      }
    },
    [addNotification, handleError, handleGoBack, t],
  );

  return { handleSubmit, handleGoBack };
}
