import { PetEventsTypes } from '../../utils/enums/eventTypes';
import { useTranslation } from 'react-i18next';

export function useData(type: PetEventsTypes) {
  const { t } = useTranslation();

  const periodName = t(`PetProfilePage.${type}Form.periodName`);
  const emptyListMarker = t(`PetProfilePage.${type}Form.emptyListMarker`);
 
  return { periodName, emptyListMarker };
}
