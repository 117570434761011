import type { ReactElement } from 'react';
import adv from '../../../../../assets/images/adv-min.png';
import './AdvHorizontal.scss';

export function AdvHorizontal(): ReactElement {
  return (
    <div className='advHorizontal'>
      <img src={adv} alt='advertisement' />
    </div>
  );
}
