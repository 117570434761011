import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import delete_pet_icon from '../../../../assets/images/dashboard/delete_pet_icon.svg';
import { useTranslation } from 'react-i18next';
import { remove } from '../../../../utils/services/request';
import { notificationSelector } from '../../../../utils/selectors';
import { useErrorHandler } from 'react-error-boundary';
import { popupState } from '../../shared/components/Popup/utils/atoms/popup';
import { tokenState } from '../../../../utils/atoms';

export function useHandlers({ fetchPets }: { fetchPets: () => void }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [, popupStateUpdate] = useRecoilState(popupState);
  const [, addNotification] = useRecoilState(notificationSelector);
  const handleError = useErrorHandler();
  const [token] = useRecoilState(tokenState)

  const handleDeletePet = useCallback(
    (petId: number): void => {
      popupStateUpdate({
        popup: {
          text: `${t('dashboard.deletePetNotificationText')}`,
          icon: delete_pet_icon,
          actions: [
            {
              text: t('Contacts.placeholders.cancel'),
              color: '#47CFDE',
              onClick: () => {},
            },
            {
              text: t('Contacts.placeholders.yesDelete'),
              color: '#FF1500',
              onClick: () => {
                handleDelete(petId);
              },
            },
          ],
        },
      });
    },
    [popupStateUpdate, t],
  );

  const handleDelete = useCallback(
    async (petId: number): Promise<void> => {
      try {
        await remove('DELETE_PET', petId.toString(), token);
        await fetchPets();
      } catch (error: any) {
        if (error.response && error.response.status === 400) {
          addNotification({
            title: error.response.status,
            text: t('appErrorNotification.trouble'),
            type: 'error',
          });
          return;
        }
        handleError(error);
      }
    },
    [addNotification, fetchPets, handleError, t],
  );
  const handleAddPetClick = useCallback((): void => {
    history.push('/pet/create');
  }, [history]);

  const handleGoToPetPage = useCallback(
    (petId: number): void => {
      history.push(`/pet/${petId}/main`);
    },
    [history],
  );

  const handleLimitNotification = useCallback((): void => {
    addNotification({
      title: t('dashboard.limitForCreatingPetsNotificationTitle'),
      text: t('dashboard.limitForCreatingPetsNotification'),
      type: 'warning',
    });
  }, [addNotification, t]);

  return { handleAddPetClick, handleGoToPetPage, handleDeletePet, handleLimitNotification };
}
