import type { ReactElement, Ref } from 'react';
import React, { useCallback, useMemo, useRef } from 'react';
import errorImg from '../../../../../../assets/images/icon_warning.svg';
import clearImg from '../../../../../../assets/images/input_icon_delete.svg';
import './CustomInput.scss';

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { ErrorInput } from '../ErrorInput/ErrorInput';
import { Box } from '@mui/material';

export function CustomInput({
  field,
  form,
  errors,
  clear,
  InputProps,
  errorType,
  children,
  SelectProps,
  focused,
  ...props
}: any): ReactElement {
  const textRef: Ref<{ value: string }> = useRef({ value: '' });
  const isDisabled = props.disabled;
  const shrink = useMemo((): boolean => {
    if (!!field?.value || !!props.value) {
      return true;
    } else {
      return false;
    }
  }, [field?.value, props.value]);

  const handleClearClick = useCallback((e) => {
    if (field) {
      clear(field.name);
    }
  }, [clear, field]);

  return (
    <div className={`custom-input ${shrink ? 'shrink' : ''}`}>
      <TextField
        {...props}
        variant={'outlined'}
        InputLabelProps={{
          variant: 'filled',
          shrink: shrink,
          sx: {
            ml: props['icons-start'] ? 5 * props['icons-start'].length : 0,
          },
        }}
        error={Boolean(errors)}
        inputRef={textRef}
        focused={focused === undefined ? undefined : !!focused}
        select={!!SelectProps}
        SelectProps={SelectProps}
        autoComplete={props.type === 'password' ? 'new-password' : 'off'}
        className={`MuiFormControl-root ${Boolean(errors) ? 'error' : ''} ${isDisabled ? 'disabled' : ''}`}
        notched={'false'}
        InputProps={{
          ...field,
          ...InputProps,
          ...props,
          onBlur: (e): void => {
            if (form) {
              form.setTouched({ ...form.touched, [field.name]: true });
            }
          },
          endAdornment: (
            <Box 
            display='flex'
            justifyContent='center'>
              {props['icons-end'] &&
                props['icons-end'].map((item: any, index: number): ReactElement => {
                  return (
                    <InputAdornment key={`inputEndIcon${index}`} position='end'>
                      {' '}
                      {item}{' '}
                    </InputAdornment>
                  );
                })}
              {Boolean(clear) && field.value && (
                <InputAdornment
                  key='inputClearIcon'
                  position='end'
                  >
                  <IconButton onClick={handleClearClick} edge='end'>
                    {' '}
                    <img src={clearImg} alt='' />
                  </IconButton>
                </InputAdornment>
              )}
              {Boolean(errors) && (
                <InputAdornment key='inputErrorIcon' position='end' onClick={(): void => {}}>
                  <img src={errorImg} alt={''} />
                </InputAdornment>
              )}
            </Box>
          ),
          startAdornment: props['icons-start'] && (
            <>
              {props['icons-start'].map((item: any, index: number): ReactElement => {
                return (
                  <InputAdornment key={`inputStartIcon${index}`} position='start'>
                    {' '}
                    {item}{' '}
                  </InputAdornment>
                );
              })}
            </>
          ),
        }}>
        {children && children}
      </TextField>
      <ErrorInput error={errors} type={errorType}></ErrorInput>
    </div>
  );
}
