import { Box, Button, Link, Stack, Typography, useTheme } from '@mui/material';
import { Field, Form, useFormikContext } from 'formik';
import React, { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestState } from '../../../../../../../utils/enums';
import { CustomCheckbox, Phone } from '../../../../../shared';
import type { TVerifyPhoneForm } from '../../../../types/types';
import { useRecaptchaVerify } from '../../../../utils/hooks/useRecaptchaVerify';
import { useHandlers } from './useHandlers';

export function ForgotPasswordForm({ requestState, ...props }: { requestState: RequestState }): ReactElement {
  const { t } = useTranslation();
  const { errors, touched, setFieldValue, values } = useFormikContext<TVerifyPhoneForm>();
  const theme = useTheme();
  const { handleClearPhone } = useHandlers();

  useRecaptchaVerify(values.terms, setFieldValue);

  return (
    <Form {...props}>
      <Stack marginBottom={0.8} gap={3.2}>
        <Typography color={theme.palette.text.primary} fontFamily='Nunito' fontSize={16} fontWeight={400}>
          {t('forgotPage.description')}
        </Typography>
        <Phone
          setField={setFieldValue}
          required
          clear={handleClearPhone}
          errorsPhone={touched.phone && errors.phone}
          errorsCode={touched.code && errors.code}
        />
        <Box>
          <Field
            as={CustomCheckbox}
            name='terms'
            labelPlacement='start'
            label={
              <Typography>
                {t('signupPage.terms.text')}{' '}
                <Link color={theme.palette.text.primary} href='/auth/policy'>
                  {t('signupPage.terms.link')}
                </Link>
              </Typography>
            }
          />
          {values.terms && <Box marginTop={1} width='100%' id='recaptcha-container' />}
        </Box>
        {requestState === RequestState.Error && (
          <Box marginTop={1}>
            <Typography>{t('forgotPage.errors.trouble')}</Typography>
          </Box>
        )}
        <Box marginTop={1}>
          <Button disabled={!values.terms} type='submit' variant='primaryContained'>
            {t('forgotPage.sendCode')}
          </Button>
        </Box>
      </Stack>
    </Form>
  );
}
