import type { TDayStatus, TMonthRequestData } from '../types';
import { RequestState } from '../../../../utils/enums';
import { UrlsType, get } from '../../../../utils/services/request';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { notificationSelector } from '../../../../utils/selectors';
import { useIsMounted } from '../../../../utils/hooks/useIsMounted';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../utils/atoms';

export function useFetchMonthDataOnMount(
  requestKey: UrlsType,
  eventsData: TMonthRequestData,
  setNeedToCheckMonthData: Dispatch<SetStateAction<boolean>>
): [RequestState, TDayStatus[]] {
  const isMounted = useIsMounted();
  const [requestStatus, setRequestStatus] = useState<RequestState>(RequestState.Idle);
  const [fetchedEventsData, setFetchedEventsData] = useState<(TDayStatus[]) | []>(
    [],
  );
  const [, addNotification] = useRecoilState(notificationSelector);
  const [token] = useRecoilState(tokenState);

  const fetchEventsData = useCallback(async () => {
    setRequestStatus(RequestState.Pending);
    if (!token) {
      addNotification({
        title: 'Error',
        text: 'Access denied',
        type: 'error',
      });
      setRequestStatus(RequestState.Error);
      return;
    }
    const response = await get(requestKey, eventsData, token, '');
    if (!isMounted()) return;

    if (response.error) {
      addNotification({
        title: response.error.response?.status,
        text: response.error.response?.data,
        type: 'error',
      });
      setRequestStatus(RequestState.Error);
      return response.error;
    }
    setRequestStatus(RequestState.Success);
    setFetchedEventsData(response.data as TDayStatus[]);
    setNeedToCheckMonthData(false)
  }, [addNotification, eventsData, isMounted, requestKey, setNeedToCheckMonthData, token]);

  useEffect(() => {
    fetchEventsData();
    return
  }, [fetchEventsData]);
  return [requestStatus, fetchedEventsData];
}
