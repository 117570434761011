import { useCallback, useEffect, useState } from 'react';
import { get } from '../../../../utils/services/request';
import { useRecoilState } from 'recoil';
import { notificationSelector } from '../../../../utils/selectors';
import { useTranslation } from 'react-i18next';
import { useErrorHandler } from 'react-error-boundary';
import { NotificationType, RequestState } from '../../../../utils/enums';
import { useIsMounted } from '../../../../utils/hooks';
import type { TPet } from '../types';
import { tokenState } from '../../../../utils/atoms';


export function useFetchPets() {
  const [pets, setPets] = useState<TPet[]>([]);
  const [token] = useRecoilState(tokenState)
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const isMounted = useIsMounted();
  const [requestState, setRequestState] = useState(RequestState.Idle);

  const fetchPets = useCallback(async () => {
    if (!token) return;

    setRequestState(RequestState.Pending);
    const { data, error } = await get('GET_PET_NAME_AVATAR', {}, '', token);

    if (!isMounted()) return;

    switch (true) {
      case !!data:
        setPets(data);
        setRequestState(RequestState.Success);
        break;
      case !!error:
        error.response.status === 400
          ? addNotification({
              title: error.response.status,
              text: t('appErrorNotification.trouble'),
              type: NotificationType.Error,
            })
          : handleError(error);
        setRequestState(RequestState.Error);
        break;
      default:
        setRequestState(RequestState.Idle);
        break;
    }
  }, [token, isMounted, addNotification, t, handleError]);

  useEffect(() => {
    fetchPets();
  }, [token]);

  return { pets, requestState, fetchPets };
}
