import { useMemo } from 'react';
import type { PetFormTextField, PetFormTextLayout } from '../types';

import type { PetEventsTypes } from '../utils/enums/eventTypes';
import type { TFunction } from 'i18next';

export const useGetFieldLabels = (
  inputs: PetFormTextLayout[],
  textareas: PetFormTextLayout[],
  formType: PetEventsTypes,
  t: TFunction,
): [PetFormTextField[], PetFormTextField[]] => {
  
  const inputLabels: PetFormTextField[] = useMemo(() => {
    return inputs.map(
    ({ i18KeyName, required, requestFieldName }: PetFormTextLayout) => ({
      fieldName: i18KeyName,
      title: t(`PetProfilePage.${formType}Form.addPageInputFields.${i18KeyName}`),
      required,
      requestFieldName,
    }),
  )}, [formType, inputs, t]);

  const textareaLabels: PetFormTextField[] = useMemo(() => {
    return textareas.map(
    ({ i18KeyName, required, requestFieldName }: PetFormTextLayout) => ({
      fieldName: i18KeyName,
      title: t(`PetProfilePage.${formType}Form.addPageTextareaFields.${i18KeyName}`),
      required,
      requestFieldName,
    }),
    )}, [formType, t, textareas]);

  return [inputLabels, textareaLabels];
};
