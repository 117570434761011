import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PageLoader, WithFooterMenu } from '../../../shared';
import { PetDetails } from '../../components/PetDetails';
import { useFetchPetData } from '../../hooks/useFetchPetData';
import './PetProfilePage.scss';
import type { TParams } from '../../types';
import { useRedirect, useCalculateTabState } from './hooks/index';
import { PetTabs } from '../../components/PetTabs/PetTabs';

export function PetProfilePage() {
  const params = useParams<TParams>();
  const { t } = useTranslation();
  const { petData } = useFetchPetData(params.id as string);
  const { redirectToDashboard, redirectToEditPetProfile } = useRedirect({ paramsId: params.id });
  const tabState = useCalculateTabState();

  return (
    <WithFooterMenu>
      <section className='pet-profile'>
        <header className='pet-profile-header'>
          <button className='btn-back' onClick={redirectToDashboard}></button>
          <h1 className='pet-profile-title'>{t('PetProfilePage.title')}</h1>
          <button className='pet-profile-edit' onClick={redirectToEditPetProfile}></button>
        </header>
        <div className='pet-profile-content'>
          {petData ? (
            <>
              <PetDetails petData={petData} />
              <PetTabs tabState={tabState} petData={petData} />
            </>
          ) : (
            <PageLoader />
          )}
        </div>
      </section>
    </WithFooterMenu>
  );
}
