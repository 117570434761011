import { useCallback, useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { firebase } from '../../../../../firebase';
import { LocalStorageKeys } from '../../../../../utils/enums';
import { LocalStorageService } from '../../../../../utils/services';

const recaptchaContainer = 'recaptcha-container';

export function useRecaptchaVerify(display: boolean, setFieldValue: (key: string, value: string) => void): void {
  const handleError = useErrorHandler();

  const recaptchaVerify = useCallback(async (): Promise<void> => {
    const refRecaptcha = document.getElementById(recaptchaContainer);

    if (display && !refRecaptcha?.firstChild) {
      if (refRecaptcha) {
        refRecaptcha.style.transform = `scale(${(refRecaptcha?.offsetWidth || 0) / 304})`;
        refRecaptcha.style.transformOrigin = '0 0';
        refRecaptcha.style.width = 'fit-content';
      }
      await new firebase.auth.RecaptchaVerifier(recaptchaContainer).verify().then(
        (value: string): void => {
          LocalStorageService.setItem(LocalStorageKeys.Token, value);
          setFieldValue('recaptcha_token', value);
        },
        (error): void => handleError(error),
      );
    } else if (display) {
      setFieldValue('recaptcha_token', '');
    }
  }, [handleError, setFieldValue, display]);

  useEffect((): void => {
    recaptchaVerify();
  }, [recaptchaVerify, display]);
}
