import { useHistory } from 'react-router'
import { PetProfileRoutePath } from '../../../utils/enums/routes-path'
import type { TEventCardHandlers } from '../types'

export function useHandlers({petId, eventId}: TEventCardHandlers) {
const history = useHistory()

  function onEditHandler() {
    history.push(`${PetProfileRoutePath.PET}/${petId}/${PetProfileRoutePath.EDIT_ALLERGY}/${eventId}`)
}
  return {onEditHandler}
}