import React, { ReactElement, useEffect, useReducer, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RequestState } from '../../../../../utils/enums';
import { PageLoader } from '../../../shared';
import { AuthContent } from '../../components/AuthContent/AuthContent';
import type { CodeState } from '../../types/types';
import { hidePhone } from '../../utils/hooks/useHidePhone';
import { useHandlers } from './useHandlers';
import './VerifyPhoneCode.scss';
import { PhoneCode } from './_components/PhoneCode/PhoneCode';

export const initCodeState = {
  codeDigit1: '',
  codeDigit2: '',
  codeDigit3: '',
  codeDigit4: '',
  codeDigit5: '',
  codeDigit6: '',
};

export const VerifyPhoneCode = (): ReactElement => {
  const [code, dispatch] = useReducer(
    (s: CodeState, a: Partial<CodeState>): CodeState => ({ ...s, ...a }),
    initCodeState,
  );
  const phone = useRef(localStorage.getItem('phone_number'));
  const { verifyStatus, handleSubmit } = useHandlers(code);
  const { t } = useTranslation();
  const history = useHistory();
  const [disable, setDisable] = useState(true);
  const [codeError, setCodeError] = useState(false);
  const [nextEl, setNextEl] = useState('false');
  useEffect((): void => {
    if (verifyStatus === RequestState.Error) {
      return setCodeError(true);
    } else {
      setCodeError(false);
    }
  }, [handleSubmit, verifyStatus]);
  useEffect((): void => {
    setDisable(true);
    if (Object.values(code).join('').length === 6) {
      setDisable(false);
    }
  }, [code]);

  useEffect((): void => {
    if (nextEl !== 'false') {
      document.getElementById('hidden')?.focus();
      setNextEl('false');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleResend = (): void => {
    history.push('/auth/phoneverify');
  };
  return (
    <>
      {' '}
      {phone.current ? (
        <AuthContent translationRootPath={'verifyPhoneCode'} prevUrl={'/auth/phoneverify'} content={'code-verify'}>
          <form className='code-verify'>
            {/*@ts-ignore*/}
            <p className='subtitle'>
              {t('verifyPhoneCode.subtitle')}
              {hidePhone(phone.current)}
            </p>
            {/*@ts-ignore*/}
            <PhoneCode setNextEl={setNextEl} codeChange={dispatch} codeError={codeError} />
            <input id={'hidden'} type='text' />
            <button type='submit' className='btn btn-primary rounded-pill' onClick={handleSubmit} disabled={disable}>
              {t('verifyPhoneCode.verify')}
            </button>
            <p className='resend'>{t('verifyPhoneCode.resendMessage')}</p>
            <button onClick={handleResend} className='resend_button'>
              <span>{t('verifyPhoneCode.resend')}</span>
            </button>
          </form>
        </AuthContent>
      ) : (
        history.push('/auth/phoneverify')
      )}
      {verifyStatus === RequestState.Pending ? <PageLoader /> : false}
    </>
  );
};
