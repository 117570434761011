import './PetCalendarEvent.scss';

import { memo, useMemo, useRef } from 'react';

import type { PetEventCalendarProps } from '../../types';
import { PetTab } from '../../enums';
import { useEventHandlers } from './helpFunctions';
import { useParams } from 'react-router';
import { usePetEventHandlers } from '../../hooks';

export const PetCalendarEventMemo: React.FC<PetEventCalendarProps> = memo(function PetEventCalendar({
  time,
  date,
  icon,
  title,
  id: eventId,
  keyInfo,
  eventType,
  currentDay,
  setNeedToCheckData,
  setNeedToCheckMonthData,
  isLastEvent,
}: PetEventCalendarProps) {
  const params = useParams<{ id: string }>();
  const petId = useMemo(() => {
    return params.id;
  }, [params.id]);

  const eventRef = useRef<HTMLDivElement>(null);

  const { handleDeleteEvent, handleEditEvent, onEventClickHandler } = usePetEventHandlers({
    eventId,
    keyInfo,
    previousLocation: PetTab.CALENDAR,
    eventType,
    petId,
    currentDay,
    setNeedToCheckData,
    setNeedToCheckMonthData,
    isLastEvent,
  });
  const { startMove, move, endMove } = useEventHandlers(eventRef);

  return (
    <div className='pet-event' ref={eventRef} onTouchStart={startMove} onTouchMove={move} onTouchEnd={endMove}>
      <div className='pet-event-visible-part' onClick={onEventClickHandler}>
        <span className='pet-event-time'>{time}</span>
        <img src={icon} alt='event type' className='pet-event-icon' />
        <span className='pet-event-title'>{title}</span>
      </div>
      <div className='pet-event-buttons'>
        <button className='pet-event-edit' onClick={handleEditEvent}></button>
        <button className='pet-event-delete' onClick={handleDeleteEvent}></button>
      </div>
    </div>
  );
});
