import type { TPetInfo } from '../../types';
import { PageLoader } from '../../../shared';
import './PetDetails.scss';
import { useTranslation } from 'react-i18next';
import { useData } from './hooks';

export function PetDetails({ petData }: { petData: TPetInfo }) {
  const { t } = useTranslation();
  const {
    avatarSrc,
    name,
    kindNameById,
    formattedBirth,
    genderHasContentClass,
    breedHasContentClass,
    genderFieldValue,
    breedFieldValue,
    isLoading,
  } = useData({ petData });

  return (
    <>
      {isLoading && <PageLoader />}
      <section className='pet-details'>
        <div className='pet-details-content'>
          <div className='pet-details-avatar'>
            <img src={avatarSrc} alt='pet_photo' />
          </div>
          <h2 className='pet-details-name'>{name}</h2>
          <div className='pet-details-infobox'>
            <div className='pet-details-kind'>
              <span className='details-name'>{t('petForm.placeholders.kind')}: </span>
              <span className='details-value'>{kindNameById}</span>
            </div>
            <div className='pet-details-birth'>
              <span className='details-name'>{t('petForm.placeholders.birthday')}: </span>
              <span className='details-value'>{formattedBirth}</span>
            </div>
            <div className='pet-details-down-container'>
              <span className='pet-details-sex'>
                <span className={`details-name-sex ${genderHasContentClass}`}>{t('petForm.placeholders.gender')}:</span>
                <span className={`details-value ${genderHasContentClass}`}>{genderFieldValue}</span>
              </span>
              <span className='pet-details-breed'>
                <span className={`details-name-breed ${breedHasContentClass}`}>{t('petForm.placeholders.breed')}:</span>
                <span className={`details-value ${breedHasContentClass}`}>{breedFieldValue}</span>
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
