import ga4 from 'react-ga4';

const TRACKING_ID = 'G-JYFHCF0PM1';
const isProduction = true; // process.env.NODE_ENV === 'production';

export const init = () =>
  ga4.initialize(TRACKING_ID, {
    testMode: !isProduction,
  });

export const sendPageview = (path: string) =>
  ga4.send({
    hitType: 'pageview',
    page: path,
  });
