import { URLS } from '../../../../utils/const/urls';
import { 
  DEL_ALLERGY,
  DEL_VACCINATION,
  DEL_TREATMENT,
  DEL_VETERINARIAN,
  DEL_SERVICES,
  PETS_EVENTS,
  PET_ALLERGY,
  PET_VACCINATION,
  PET_TREATMENT,
  PET_VETERINARIAN,
  PET_SERVICES
} from '../requestKeys'

type UrlsType = keyof typeof URLS;

export const getRequestKey = (key: string, requestType: string): UrlsType => {
  
  if(requestType === 'deleteOne') {
    if (key.includes('allerg')) return DEL_ALLERGY;
    if (key.includes('vaccination')) return DEL_VACCINATION;
    if (key.includes('treatment')) return DEL_TREATMENT;
    if (key.includes('veterinarian')) return DEL_VETERINARIAN;
    if (key.includes('services')) return DEL_SERVICES;
  }
  if(requestType === 'getOneType') {
    if (key.includes('allerg')) return PET_ALLERGY;
    if (key.includes('vaccination')) return PET_VACCINATION;
    if (key.includes('treatment')) return PET_TREATMENT;
    if (key.includes('veterinarian')) return PET_VETERINARIAN;
    if (key.includes('services')) return PET_SERVICES;
  }
  if(requestType === 'getAll') return PETS_EVENTS;

  return PETS_EVENTS;
}
