import { PetEventsTypes } from '../../../utils/enums/eventTypes';
import { PetProfileRoutePath } from '../../../utils/enums/routes-path';
import { PetTab } from '../../../enums';
import allergy from '../../../../../../assets/images/pet/allergy.svg';
import allergy_red from '../../../../../../assets/images/pet/allergy_red.svg';
import doctor from '../../../../../../assets/images/pet/doc.svg';
import parasite from '../../../../../../assets/images/pet/bug.svg';
import service from '../../../../../../assets/images/pet/service.svg';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import vaccine from '../../../../../../assets/images/pet/vaccine.svg';

const link: Record<PetTab, Record<PetEventsTypes, PetProfileRoutePath> | null> = {
  [PetTab.MEDICAL]: {
    [PetEventsTypes.ALLERGY]: PetProfileRoutePath.ALLERGIES,
    [PetEventsTypes.SERVICE]: PetProfileRoutePath.SERVICES,
    [PetEventsTypes.VACCINATION]: PetProfileRoutePath.VACCINATION,
    [PetEventsTypes.TREATMENT]: PetProfileRoutePath.TREATMENT,
    [PetEventsTypes.VETERINARIAN]: PetProfileRoutePath.VET,
  },
  [PetTab.CALENDAR]: {
    [PetEventsTypes.ALLERGY]: PetProfileRoutePath.ADD_ALLERGY,
    [PetEventsTypes.SERVICE]: PetProfileRoutePath.ADD_SERVICES,
    [PetEventsTypes.VACCINATION]: PetProfileRoutePath.ADD_VACCINATION,
    [PetEventsTypes.TREATMENT]: PetProfileRoutePath.ADD_TREATMENT,
    [PetEventsTypes.VETERINARIAN]: PetProfileRoutePath.ADD_VET,
  },
  [PetTab.INFO]: null,
  [PetTab.GALLERY]: null,
};

export function useData({ tabState, hasAllergy }: { tabState: PetTab; hasAllergy?: boolean }) {
  const { t } = useTranslation();
  const allergyIcon: string = tabState === PetTab.MEDICAL && hasAllergy ? allergy_red : allergy;
  const allergyWarning = (tabState === PetTab.MEDICAL) && hasAllergy;

  const toEventBtns = useMemo(
    () => [
      {
        icon: [allergyIcon],
        title: t('PetProfilePage.calendar.allergies'),
        link: link[tabState]?.[PetEventsTypes.ALLERGY],
        warning: allergyWarning,
      },
      {
        icon: [service],
        title: t('PetProfilePage.calendar.services'),
        link: link[tabState]?.[PetEventsTypes.SERVICE],
        warning: false,
      },
      {
        icon: [vaccine],
        title: t('PetProfilePage.calendar.vaccination'),
        link: link[tabState]?.[PetEventsTypes.VACCINATION],
        warning: false,
      },
      {
        icon: [parasite],
        title: t('PetProfilePage.calendar.treatment'),
        link: link[tabState]?.[PetEventsTypes.TREATMENT],
        warning: false,
      },
      {
        icon: [doctor],
        title: t('PetProfilePage.calendar.vet'),
        link: link[tabState]?.[PetEventsTypes.VETERINARIAN],
        warning: false,
      },
    ],
    [allergyIcon, allergyWarning, t, tabState],
  );

  return { toEventBtns };
}
