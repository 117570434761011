import i18n from 'i18next';
import { useCallback } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../../utils/atoms';
import { RequestState } from '../../../../../utils/enums';
import { useIsMounted } from '../../../../../utils/hooks';
import { setAuthToken } from '../../../../../utils/services';
import type { TLoginForm } from '../../types/types';
import { useLogin } from '../../utils/hooks/useLogin';
import { updateLocalStorageAfterAuthData } from '../../utils/hooks/useUpdateLocalStorageAfterAuthData';
import type { TToken } from '../../../../../utils/types';

export function useHandlers(): { handleSubmit: (data: TLoginForm) => Promise<void>; loginStatus: RequestState } {
  const isMounted = useIsMounted();
  const [loginStatus, onLogin] = useLogin();
  const history = useHistory();
  const handleError = useErrorHandler();
  const [, setToken] = useRecoilState(tokenState);

  const handleSubmit = useCallback(
    async (data: TLoginForm): Promise<void> => {
      const response = await onLogin({ phone_number: `+${data.code}${data.phone}`, password: data.password });

      if (!isMounted()) {
        return;
      }

      if (!!response.error) {
        handleError(response.error);

        return;
      }
      const { access, refresh, language } = response.data;
      i18n.changeLanguage(language);

      setToken((token: TToken) => {
        token.access = access
        token.refresh = refresh
        return token
      }
        );
      setAuthToken(access);
      updateLocalStorageAfterAuthData({ ...data, ...response.data }, history);
    },
    [handleError, history, isMounted, onLogin, setToken],
  );

  return { handleSubmit, loginStatus };
}
