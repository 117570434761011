import * as RequestService from '../../../../../../utils/services/request';
import { createFormData } from '../../../../../../utils/helpers';
import { prepareDataForRequest } from '../functions/dataUtils';
import type { TPetEditForm } from '../types';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { NotificationType, RequestState } from '../../../../../../utils/enums';
import { useRecoilState } from 'recoil';
import { notificationSelector } from '../../../../../../utils/selectors';
import { useTranslation } from 'react-i18next';
import { useErrorHandler } from 'react-error-boundary';
import { useIsMounted } from '../../../../../../utils/hooks';
import { tokenState } from '../../../../../../utils/atoms';


export function useHandlers() {
  const [, addNotification] = useRecoilState(notificationSelector);
  const [updateStatus, setUpdateStatus] = useState(RequestState.Idle);
const [token] = useRecoilState(tokenState)
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const handleError = useErrorHandler();
  const history = useHistory();
  const handleGoBack = useCallback(
    data => {
      history.push(`/pet/${data.id}/main`);
    },
    [history],
  );

  const handleSubmit = useCallback(
    async (data: TPetEditForm) => {
      setUpdateStatus(RequestState.Pending);
      const response = await RequestService.put(
        'PETS_PUT',
        data.id,
        createFormData(prepareDataForRequest(data)),
        token,
      );
      if (!isMounted()) {
        setUpdateStatus(RequestState.Idle);
        return;
      }
      if (response.error) {
        setUpdateStatus(RequestState.Error);
        response.error.response.status === 400
          ? addNotification({
              title: response.error.response.status,
              text: t('appErrorNotification.trouble'),
              type: NotificationType.Error,
            })
          : handleError(response.error);
      }
      handleGoBack(data);
      setUpdateStatus(RequestState.Success);
    },
    [addNotification, handleError, handleGoBack, isMounted, t],
  );

  return { handleSubmit, updateStatus };
}
