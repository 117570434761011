import { PetSimilarEventMemo } from '../PetSimilarEvent/PetSimilarEvent';
import Stack from '@mui/material/Stack';
import type { TPetSimilarEventContainerProps } from '../../types';
import { TYPE_PROPS } from '../PetSimilarEvent/similarEventData';
import { useData } from './useData';

export function PetSimilarEventContainer({
  eventsList,
  eventDetails,
  type,
}: TPetSimilarEventContainerProps): JSX.Element {
  const { periodName, emptyListMarker } = useData(eventDetails.type);
  const path = eventDetails.pathForEdit;
  const propNames = TYPE_PROPS[type];

  return (
    <Stack flexDirection='column'>
      {Array.isArray(eventsList.results) && !!eventsList.results.length
        ? eventsList.results.map(event => {
            return (
              <PetSimilarEventMemo
                key={event.id}
                id={event.id}
                //@ts-ignore
                title={event[propNames.title]}
                timeName={periodName}
                timeAndDate={event.event_date}
                path={path}
                type={type}
                eventInfo={event}
              />
            );
          })
        : emptyListMarker}
    </Stack>
  );
}
