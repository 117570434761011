import React, { type ReactElement, useState } from 'react';

import { Box, MenuItem } from '@mui/material';
import arrowImg from '../../../../../../assets/images/down-anchor.png';
import { CustomInput } from '../CustomInput/CustomInput';

export function CustomSelect({ placeholder, choices, field, form, ...props }: any): ReactElement {
  const [focused, setFocus] = useState(false);
  const handleChange = (event: React.ChangeEvent<{ value: any }>): void => {
    const { value } = event.target;
    form.setFieldValue(field.name, value);
  };

  return (
    <Box className='custom-select'>
      <CustomInput
        {...props}
        {...field}
        onChange={handleChange}
        focused={focused}
        id={field.name}
        SelectProps={{
          onClose: (): void => {
            setFocus(false);
          },
          onOpen: (): void => {
            setFocus(true);
            form.setFieldTouched(field.name);
          },
          IconComponent: (): ReactElement => <img src={arrowImg} alt='' />,
        }}>
        {!props.required && <MenuItem value=''></MenuItem>}
        {choices?.map(
          (item: any, index: number): ReactElement => (
            <MenuItem key={'option' + index} value={item.id}>
              {item.name}
            </MenuItem>
          ),
        )}
      </CustomInput>
    </Box>
  );
}
