import { Button, Stack } from '@mui/material';
import { Field, Form, useFormikContext } from 'formik';
import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { RequestState } from '../../../../../../../utils/enums';
import { SecureInput } from '../../../../../shared';
import type { TNewPasswordForm } from '../../../../types/types';
import { useHandlers } from './useHandlers';

export const NewPasswordForm = ({
  state,
  codeState,
  ...props
}: {
  state: RequestState;
  codeState: boolean;
}): ReactElement => {
  const { errors, touched, isValid } = useFormikContext<TNewPasswordForm>();
  const { t } = useTranslation();
  const { handleClearPassword, handleClearRePassword } = useHandlers();

  return (
    <Form {...props}>
      <Stack gap={3.2}>
        <Field
          name='password'
          component={SecureInput}
          required
          errors={touched.password && errors.password}
          clear={handleClearPassword}
          label={t('signupPage.placeholders.password')}
        />
        <Field
          name='re_password'
          component={SecureInput}
          required
          errors={touched['re_password'] && errors['re_password']}
          clear={handleClearRePassword}
          label={t('signupPage.placeholders.re_password')}
        />
        <Button disabled={!isValid || !codeState} type='submit' variant='primaryContained'>
          {t('resetPassPage.reset')}
        </Button>
      </Stack>
    </Form>
  );
};
