import { useTranslation } from 'react-i18next';
import type { TPetInfo } from '../../../types';
import type { UseDataReturnValue } from './types';
import { useMemo } from 'react';
import { firstCharToUppercase } from '../../../helpFunctions';
import { PetFields } from '../../../enums';
import { useGetTimeFormat } from '../../../hooks';
import { convertTimeFormat } from '../../../helpFunctions/convertTimeFormat';

export function useData({ petData }: { petData: TPetInfo | null }): UseDataReturnValue {
  const { t } = useTranslation();
  const { is12HourFormat } = useGetTimeFormat();
  const {
    color,
    special_signs,
    food,
    sterilization,
    living_place,
    outdoor_schedule,
    id_chip_stigma,
    temperament,
    id_type,
  } = petData as TPetInfo;

  const outdoorScheduleValue = useMemo(() => {
    if (typeof outdoor_schedule === 'string') {
      return outdoor_schedule.replace(/,/g, ` |`).replace(/:00(?!:)/g, '');
    }
    return [];
  }, [outdoor_schedule]);

  const convertedOutdoorSchedule = useMemo(() => {
    return is12HourFormat ? convertTimeFormat(outdoorScheduleValue as string) : outdoorScheduleValue;
  }, [is12HourFormat, outdoorScheduleValue]);

  const petFields = useMemo(() => {
    const idChipField =
      id_type !== PetFields.NoChip
        ? { label: t(`petInfo.${id_type}`), value: id_chip_stigma }
        : { label: t('petForm.placeholders.animal_id'), value: id_chip_stigma };
    const foodFieldValue = food === PetFields.Manufactured ? t(`petForm.feed`) : food && t(`petForm.${food}`);
    const sterelizationFieldValue = sterilization === PetFields.NotChosen ? '' : t(`petForm.${sterilization}`);
    return [
      { label: t('petForm.placeholders.color'), value: firstCharToUppercase(color) },
      { label: t('petForm.placeholders.special_signs'), value: firstCharToUppercase(special_signs) },
      { label: t('petForm.placeholders.food'), value: foodFieldValue },
      { label: t('petForm.placeholders.sterilization'), value: sterelizationFieldValue},
      { label: t('petForm.placeholders.living_place'), value: living_place && t(`selects.place.${living_place}`) },
      { label: t('petForm.placeholders.outdoor_schedule'), value: convertedOutdoorSchedule },
      idChipField,
      { label: t('petForm.placeholders.temperament'), value: temperament && t(`selects.temperament.${temperament}`) },
    ];
  }, [
    id_type,
    t,
    id_chip_stigma,
    food,
    color,
    special_signs,
    sterilization,
    living_place,
    convertedOutdoorSchedule,
    temperament,
  ]);

  const petSortedFields = useMemo(() => {
    const petFilledFields = petFields.filter(field => field.value !== undefined && field.value !== '');
    const petEmptyFields = petFields
      .filter(field => field.value === undefined || field.value === '')
      .map(field => ({ ...field, isEmpty: true }));
    return [...petFilledFields, ...petEmptyFields];
  }, [petFields]);

  return { petSortedFields };
}
