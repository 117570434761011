import Box from '@mui/material/Box';
import { CustomIcon } from '../../../shared/components/CustomIcon/CustomIcon';
import { PetCalendarMemo } from '../PetCalendar';
import { PetEventTypeSelectorContainer } from '../PetToSimilarEventButton/PetEventTypeSelectorContainer';
import { PetGallery } from '../PetGallery';
import { PetInformation } from '../PetInformation';
import { PetTab } from '../../enums';
import type { TPetTabsProps } from './types';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TabsTheme } from '../../../../../MUITheme';
import { ThemeProvider } from '@mui/material';
import { useData } from './hooks';
import { useParams } from 'react-router';
import { useState } from 'react';
import { useTabHandlers } from './hooks/useTabHandlers';
import warningMR from '../../../../../assets/images/pet/warningMR.svg';

export function PetTabs({ tabState, petData }: TPetTabsProps) {
  const [toggleState, setToggleState] = useState<PetTab>(tabState || PetTab.INFO);
  const params = useParams<{ id: string }>();
  const petId = params.id;
  const hasAllergy = petData?.has_allergy;

  const { tabsArr, tabLabels } = useData({ hasAllergy });
  const { handleTabChange } = useTabHandlers({ setToggleState, petId });

  const tabContent = {
    [PetTab.INFO]: <PetInformation petData={petData} />,
    [PetTab.GALLERY]: <PetGallery />,
    [PetTab.MEDICAL]: <PetEventTypeSelectorContainer petId={petId} tabState={PetTab.MEDICAL} hasAllergy={hasAllergy} />,
    [PetTab.CALENDAR]: <PetCalendarMemo />,
  };

  return (
    <section className='pet-tabs'>
      <Box>
        <ThemeProvider theme={TabsTheme}>
          <Tabs
            value={toggleState}
            onChange={handleTabChange}
            variant='scrollable'
            orientation='horizontal'
            visibleScrollbar={false}
            scrollButtons={false}>
            {tabsArr.map(tab => (
              <Tab
                key={tab.id}
                value={tab.id}
                icon={tab.icon ? <CustomIcon iconImg={warningMR} /> : undefined}
                iconPosition={tab.iconPosition}
                label={tabLabels[tab.id]}
              />
            ))}
          </Tabs>
        </ThemeProvider>
      </Box>

      <div className='pet-tabs-content'>{tabContent[toggleState]}</div>
    </section>
  );
}
