import { RequestState } from '../../../../../../utils/enums';
import { post } from '../../../../../../utils/services';
import { useCallback, useState } from 'react';

import type { TEventFormHandlers } from '../types';
import { notificationSelector } from '../../../../../../utils/selectors';
import { transformData } from '../../../helpFunctions';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { tokenState } from '../../../../../../utils/atoms';

export function useEventFormHandlers({
  type,
  needToDisablePast,
  petId,
  requestKey,
  locationStateFrom,
}: TEventFormHandlers) {
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();
  const history = useHistory();
  const isMounted = useIsMounted();
  const [requestStatus, setCreateStatus] = useState<RequestState>(RequestState.Idle);
  const [fetchedData, setFetchedData] = useState<any>([]);
  const [token] = useRecoilState(tokenState)
  
  const handleSubmit = useCallback(
    async formikData => {
      const eventData = transformData({ formikData, eventType: type, needToCheck: needToDisablePast, petId }); //need to refactor function transformData()
      if (eventData === 'invalid') {
        addNotification({
          title: t('PetProfilePage.notifications.invalidDataTitle'),
          text: t('PetProfilePage.notifications.invalidDataText'),
          type: 'error',
        });
        return;
      }
      const response = await post(requestKey, eventData, token);

      if (!isMounted() || !response) {
        setCreateStatus(RequestState.Idle);
        return;
      }
      if (response.error) {
        setCreateStatus(RequestState.Error);
        addNotification({
          title: response.error.response?.status,
          text: response.error.response?.data,
          type: 'error',
        });
        throw Error(response.error);
      }
      setCreateStatus(RequestState.Success);
      addNotification({
        title: t('PetProfilePage.notifications.successTitle'),
        text: `${t('PetProfilePage.notifications.successText')} ${t(`PetProfilePage.${type}Form.addedText`)}`,
        type: 'success',
      });
      setFetchedData(response.data);
      locationStateFrom === 'calendar'
        ? history.push(`/pet/${petId}/main/${locationStateFrom}`, { from: locationStateFrom })
        : history.push(`/pet/${petId}/${locationStateFrom}`, { from: locationStateFrom });

      return;
    },
    [addNotification, history, isMounted, locationStateFrom, needToDisablePast, petId, requestKey, t, token, type],
  );

  return { handleSubmit };
}
