import type {} from '@mui/x-date-pickers/themeAugmentation';

import { createTheme } from '@mui/material';

declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    buttonBg: true;
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primaryContained: true;
    secondaryContained: true;
    secondaryOutlined: true;
    customDeleteButton: true;
    eventToggler: true;
    stringButton: true;
  }
}
declare module '@mui/material/styles' {
  interface Palette {
    buttonBg: PaletteOptions['primary'];
  }

  interface PaletteOptions {
    buttonBg: { main: string };
  }

  interface DefaultPaletteOptions extends PaletteOptions {
    buttonBg: { main: string };
  }
}

export const theme = createTheme({
  palette: {
    primary: {
      main: '#3cccdb',
      light: '#A1A4B282',
    },
    secondary: {
      main: '#2BA8B2',
      light: '#F2F3F7',
    },
    info: {
      main: '#A1DFFB',
      dark: '#3f414e',
      light: '#3f414e99',
    },
    error: {
      main: '#C33124',
      light: '#FF1500',
    },
    warning: {
      main: '#F98365',
      light: '#47CFDE',
    },
    success: {
      main: '#88DF3E',
    },
    text: {
      primary: '#fff',
      secondary: '#3cccdb',
      disabled: '#A1A4B2',
    },
    buttonBg: {
      main: '#F3F7F8',
    },
  },
  typography: {
    fontFamily: ['Nunito-Regular, sans-serif'].join(','),
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'primaryContained' },
          style: {
            alignItems: 'center',
            alignSelf: 'stretch',
            backdropFilter: 'blur(2px)',
            background: '#47CFDE',
            color: '#fff',
            borderRadius: '28px',
            display: 'flex',
            gap: '10px',
            height: '50px',
            justifyContent: 'center',
            padding: '24px 20px',
            textTransform: 'capitalize',
            width: '100%',
          },
        },
        {
          props: { variant: 'secondaryContained' },
          style: {
            alignItems: 'center',
            alignSelf: 'stretch',
            backdropFilter: 'blur(2px)',
            background: 'rgba(255, 255, 255, 0.90)',
            borderRadius: '28px',
            color: '#47CFDE',
            display: 'flex',
            gap: '10px',
            height: '50px',
            justifyContent: 'center',
            padding: '24px 20px',
            textTransform: 'capitalize',
            width: '100%',
          },
        },
        {
          props: { variant: 'customDeleteButton' },
          style: {
            borderRadius: '1.75rem',
            backgroundColor: '#FF1500',
            color: 'white',
            padding: '0.63rem 1.25rem',
            height: '3.125rem',
            width: '100%',
            fontFamily: 'Nunito-Bold, sans-serif',
            textTransform: 'none',
            fontSize: '1rem',
          },
        },
        {
          props: { variant: 'secondaryOutlined' },
          style: {
            alignItems: 'center',
            alignSelf: 'stretch',
            backdropFilter: 'blur(2px)',
            background: 'rgba(255, 255, 255, 0)',
            border: '2px solid #fff',
            borderRadius: '28px',
            color: '#47cfde',
            display: 'flex',
            gap: '10px',
            height: '50px',
            justifyContent: 'center',
            padding: '24px 20px',
            textTransform: 'capitalize',
            width: '100%',
          },
        },
        {
          props: { variant: 'eventToggler' },
          style: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '6px 12px',
            gap: '10px',
            backgroundColor: '#47cfde',
            backdropFilter: 'blur(2px)',
            borderRadius: '28px',
            border: 'none',
            margin: '16px auto',
            minWidth: '174px',
            fontWeight: '700',
            fontSize: '16px',
            lineHeight: '22px',
            color: 'white',
            letterSpacing: '-0.01rem',
            textTransform: 'none',
            fontFamily: 'Nunito-Regular, sans-serif',
          },
        },
      ],
      styleOverrides: {
        root: {
          '&.MuiButton-primaryContained': {
            '&.Mui-disabled': {
              opacity: '60%',
              background: '#47CFDE',
              color: '#fff',
            },
            '&:hover': {
              background: '#47CFDE',
              color: '#fff',
            },
          },
          '&.MuiButton-secondaryContained': {
            '&.Mui-disabled': {
              opacity: '60%',
              background: 'rgba(255, 255, 255, 0.90)',
              color: '#47CFDE',
            },
            '&:hover': {
              background: 'rgba(255, 255, 255, 0.90)',
              color: '#47CFDE',
            },
          },
          '&.MuiButton-secondaryOutlined': {
            '&.Mui-disabled': {
              opacity: '60%',
              background: 'rgba(255, 255, 255, 0)',
              color: '#47CFDE',
            },
            '&:hover': {
              background: 'rgba(255, 255, 255, 0)',
              color: '#47CFDE',
            },
          },
          '&.MuiButton-eventToggler': {
            '&:hover': {
              background: '#47CFDE',
            },
          },
        },
      },
    },
  },
});

export const MultilineFieldTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          padding: '20px 20px 0 20px',
          border: `1px solid ${theme.palette.primary.main}`,
          borderRadius: '35px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.primary.main,
          padding: '20px 20px 0 25px',
        },
        standard: {
          padding: '0 20px 0 20px',
          top: '-0.3rem',
        },
        shrink: {
          fontSize: '0.8rem',
          padding: '30px 20px 0 27px !important',
        },
        asterisk: {
          color: theme.palette.error.light,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: theme.palette.info.dark,
          border: 'none',
          '&::before': {
            border: 'none !important',
          },
          marginTop: '6px !important',
        },
        input: {
          color: theme.palette.info.dark,
          WebkitTextFillColor: `${theme.palette.info.dark} !important`,
        },
      },
    },
  },
});

export const OneLineFieldTheme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          padding: '0px 20px 0 20px',
          borderBottom: `1px solid ${theme.palette.primary.main}`,
          marginBottom: '1rem',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: theme.palette.text.disabled,
          padding: '0px 20px 0 25px',
        },
        asterisk: {
          color: theme.palette.error.light,
        },
        standard: {
          padding: '0 20px 0 20px',
          top: '-0.7rem',
        },
        shrink: {
          fontSize: '0.8rem',
          padding: '10px 20px 0 27px !important',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: theme.palette.info.dark,
          border: 'none',
          '&::before': {
            border: 'none !important',
          },
          marginTop: '6px !important',
        },
        input: {
          color: theme.palette.info.dark,
          WebkitTextFillColor: `${theme.palette.info.dark} !important`,
        },
      },
    },
  },
});

export const CalendarWidgetTheme = createTheme({
  components: {
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          fontFamily: 'Nunito-Regular, sans-serif',
          border: 'none',
          fontWeight: '600',
          lineHeight: '19px',
          margin: '0 auto',
          paddingBottom: '2%',
          minHeight: '325px',

          // here is so applying of style because MUI in android looses classes in some divs
          '& > div > div[role="grid"], > div.MuiPickersSlideTransition': {
            background: 'rgba(71, 207, 222, 0.1)',
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          marginBottom: '0',
        },
        labelContainer: {
          fontWeight: '700',
          fontSize: '16px',
          lineHeight: '150%',
          color: theme.palette.info.dark,
          position: 'relative',
          left: '29%',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        edgeEnd: {
          color: theme.palette.warning.light,
          position: 'relative',
          left: '-270%',
        },
        edgeStart: {
          color: theme.palette.warning.light,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: theme.palette.warning.light,
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        header: {
          background: 'rgba(71, 207, 222, 0.1)',
          marginBottom: '6px',
          height: '39px',
        },
        loadingContainer: {
          background: '#47cfde1a',
          minHeight: '240px',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: '0.9rem',
          fontWeight: '600',
          fontFamily: 'Nunito-Regular',
          color: `${theme.palette.info.light} !important`,
        },
      },
    },
    MuiPickersSlideTransition: {
      styleOverrides: {
        root: {
          background: 'rgba(71, 207, 222, 0.1)',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          'MuiPickersDay-root': {
            fontWeight: '600',
            fontFamily: 'Nunito-SemiBold',
            color: `${theme.palette.info.dark}`,
            fontSize: '0.9rem !important',
          },
          '&[aria-selected="true"]': {
            color: `${theme.palette.info.dark} !important`,
            backgroundColor: `${theme.palette.warning.light} !important`,
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        today: {
          border: `1px solid ${theme.palette.warning.light}`,
        },
        dayOutsideMonth: {
          '& MuiButtonBase-root MuiPickersDay-root MuiPickersDay-dayWithMargin ': {
            color: theme.palette.info.light,
          },
        },
      },
    },
  },
});

export const TabsTheme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        root: {
          minHeight: '39px',
          maxHeight: '39px',
        },
        flexContainer: {
          height: '39px',
        },
        indicator: {
          height: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          lineHeight: '1rem',
          color: theme.palette.info.dark,
          textDecoration: 'none',
          textTransform: 'none',
          minWidth: '6rem',
          width: '30vw',
          padding: '0',
          minHeight: '39px',
          backgroundColor: theme.palette.secondary.light,
          borderBottom: `0.5px solid ${theme.palette.text.disabled}`,
          borderRight: `0.5px solid ${theme.palette.text.disabled}`,
          '&.Mui-selected': {
            color: theme.palette.info.dark,
            fontWeight: 700,
            textDecoration: 'none',
            textTransform: 'none',
            backgroundColor: theme.palette.text.primary,
            width: '35vw',
            borderBottom: 'none',
            borderLeft: `0.5px solid ${theme.palette.text.disabled}`,
            borderTop: `0.5px solid ${theme.palette.text.disabled}`,
            borderRight: `0.5px solid ${theme.palette.text.disabled}`,
            boxShadow: '2px 0px 3px 0px rgba(0,0,0,0.2);',
            webkitBoxShadow: '2px 0px 3px 0px rgba(0,0,0,0.2);',
            zIndex: 1,
          },
          '&.Mui-selected:not(:first-of-type)': {
            borderLeft: 'none',
          },
        },
        labelIcon: {
          padding: '12px 16px',
          '& img': {
            width: '20px',
            height: '20px',
          },
        },
      },
    },
  },
});

export const CountryTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '&.focused': {
            '.MuiFormControl-root': {
              height: '3.5rem',
            },
          },
          '&.Mui-expanded .MuiFormControl-root': {
            border: `1px solid ${theme.palette.warning.light}`,
            borderRadius: '1.75rem 1.75rem 0 0',
            borderBottom: 'none',
          },
          '& .MuiOutlinedInput-root': {
            paddingRight: '1rem',
          },
          '.MuiFormControl-root': {
            zIndex: '2',
            border: `1px solid ${theme.palette.secondary.light}`,
            borderRadius: '1.75rem',
            color: '#3f414e',
            background: 'white',
            width: '100%',
            height: '3.5rem',
            '&:focus-within': {
              border: `1px solid ${theme.palette.warning.light}`,
              borderBottom: 'none',
            },
            '&:hover': {
              border: `1px solid ${theme.palette.warning.light}`,
              borderBottom: 'none',
            },
            '.MuiFormLabel-root': {
              fontFamily: theme.typography.fontFamily,
              color: '#A1A4B2',
              pointerEvents: 'none',
              transition: '0.1s',
              zIndex: '2',
              marginTop: '-2px',
              fontSize: '0.6rem',
              marginLeft: '2.7rem',
              '&.MuiInputLabel-shrink': {
                left: '4%',
                top: '0px',
                fontSize: '0.8rem',
                marginTop: '0px',
                marginLeft: '0',
                padding: '0',
                '&.Mui-focused': {
                  left: '2.8rem',
                  top: '0.5rem',
                },
              },
              '.MuiFormLabel-asterisk': {
                color: 'red',
              },
            },
            '.MuiInputBase-root': {
              padding: '0.3rem 1rem 0px 1rem',
              '&:not(.Mui-focused)': {
                paddingTop: '0.5rem',
              },
              fieldset: {
                top: '0',
                border: 'none',
                '&.error_border': {
                  border: '1px solid rgb(255, 21, 0) !important',
                },
                '&:focus': {
                  border: 'none',
                },
                legend: {
                  display: 'none',
                },
              },
              '&.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot':
                {
                  paddingRight: '1rem',
                },
              '.MuiInputAdornment-root': {
                marginRight: '3px',
                marginLeft: '1px',
              },
              '.MuiAutocomplete-endAdornment': {
                position: 'initial',
                display: 'flex',
                right: '0px',
                '.MuiAutocomplete-popupIndicator': {
                  display: 'none',
                },
              },
              '&.Mui-focused': {
                backgroundColor: '#F2F3F7',
                margin: '12px 0px 0px 12px',
                borderRadius: '2rem',
                width: '93%',
                '& input': {
                  margin: '0 0 0 1.3rem',
                  padding: '0.7rem 0rem 0.4rem 0rem',
                  fontSize: '0.8rem',
                },
              },
              input: {
                position: 'relative',
                zIndex: '2',
                border: 'none',
                fontFamily: theme.typography.fontFamily,
                fontSize: '1rem',
                outline: 'none',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                lineHeight: 'normal',
                padding: '1rem 0 0 0',
                color: theme.palette.info.dark,
              },
            },
          },
        },
        listbox: {
          padding: '0',
        },
        popper: {
          '& .MuiPaper-root': {
            boxShadow: 'none',
            borderRadius: '0 0 1.75rem 1.75rem',
            border: `1px solid ${theme.palette.warning.light}`,
            borderTop: 'none',
            margin: '0.15rem -3.8% 0 -4%',
            '& li': {
              color: theme.palette.info.dark,
            },
          },
        },
        option: {
          textAlign: 'center',
          margin: '0',
          left: '19px',
          top: '13px',

          '& img': {
            objectFit: 'cover',
            width: '22.5px',
            height: '22.5px',
            borderRadius: '50%',
            border: `1px solid ${theme.palette.info.dark}`,
            marginRight: '0.5rem',
          },
          '&:not(:first-of-type)': {
            borderTop: `0.5px solid ${theme.palette.primary.light}`,
          },
        },
      },
    },
  },
});

export const PopUpTheme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            paddingRight: '1rem',
          },
          '&.focused .MuiFormControl-root': {
            border: `0.5px solid ${theme.palette.warning.light}`,
          },
        },
        endAdornment: {
          position: 'initial',
          display: 'flex',
          right: '0px',
        },
        popupIndicator: {
          display: 'none',
        },
        popper: {
          '&.MuiPopper-root': {
            width: '94vw !important',
            top: '2rem !important',
            left: '1rem !important',
            transform: 'none !important',
          },
        },
        listbox: {
          minHeight: '90vh',
          maxHeight: '94vh',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: theme.typography.fontFamily,
          transition: '0.1s',
          fontSize: '0.5rem',
          marginLeft: '0.4rem',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(12px, 10px) scale(0.6) !important',
            color: theme.palette.text.disabled,
          },
        },
        asterisk: {
          color: 'red',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          zIndex: '2',
          border: `1px solid ${theme.palette.secondary.light}`,
          borderRadius: '1.75rem',
          color: '#3f414e',
          background: 'white',
          width: '100%',
          height: '3.2rem',
          '&:focus-within': {
            border: `1px solid ${theme.palette.warning.light}`,
          },
          '&:hover': {
            border: `1px solid ${theme.palette.warning.light}`,
          },
          '&.MuiTextField-root': {
            border: `1px solid ${theme.palette.secondary.light}`,
            borderRadius: '1.75rem',
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: '0.3rem 1rem 0px 1rem',
          '&:not(.Mui-focused)': {
            paddingTop: '0.5rem',
          },
          fieldset: {
            top: '0',
            border: 'none',
            '&.error_border': {
              border: '1px solid rgb(255, 21, 0) !important',
            },
            '&:focus': {
              border: 'none',
            },
            legend: {
              display: 'none',
            },
          },
          '&.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedStart.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot':
            {
              paddingRight: '1rem',
            },
        },
        input: {
          position: 'relative',
          zIndex: '2',
          border: 'none',
          fontFamily: theme.typography.fontFamily,
          fontSize: '1rem',
          outline: 'none',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          lineHeight: 'normal',
          padding: '0.9rem 0rem 0.6rem 0.1rem!important',
          color: theme.palette.info.dark,
          marginLeft: '0.5rem',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: { margin: '0px 0.5rem 0.5rem 0' },
      },
    },
  },
});
