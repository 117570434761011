import React, { ReactElement, SyntheticEvent, useCallback, useEffect, useState } from 'react';

import { AutocompleteRenderOptionState, Box } from '@mui/material';
import { CountryTheme } from '../../../../../MUITheme';
import { CustomSelectNew } from '../Inputs/CustomSelect/CustomSelectNew';
import { Field } from 'formik';
import { PhoneInput } from '../Inputs/PhoneInputs/PhoneInput';
import { SelectTypes } from '../../../../../utils/enums';
import type { TCountryOption, TPhoneFieldProps } from '../../types';
import ua from '../../../../../assets/images/flags/ua.svg';
import usa from '../../../../../assets/images/flags/usa.svg';
import { useTranslation } from 'react-i18next';

export function Phone({ setField, errorsCode, values, errorsPhone, ...props }: TPhoneFieldProps): ReactElement {
  const { t } = useTranslation();
  const locale =
    navigator.language.split('-')[0] === 'uk' || navigator.language.split('-')[0] === 'en'
      ? navigator.language.split('-')[0]
      : 'uk';
  const localeCountry: string = `long_name_${locale}`;
  const countriesList: TCountryOption[] = [
    {
      iso3: 'UKR',
      long_name: 'Ukraine',
      long_name_en: 'Ukraine',
      long_name_uk: 'Україна',
      num_code: '804',
      phone_code: '380',
      short_name: 'UA',
      src: ua,
      pk: 224,
    },
    {
      iso3: 'USA',
      long_name: 'United States',
      long_name_en: 'United States',
      long_name_uk: 'Сполучені Штати Америки',
      num_code: '840',
      phone_code: '1',
      short_name: 'US',
      src: usa,
      pk: 227,
    },
  ];
  const [selected, setOption] = useState<TCountryOption>(
    values?.code === '380' ? countriesList[0] : countriesList[locale === 'en' ? 1 : 0],
  );
  const [inputValue, setInputValue] = useState(selected[localeCountry]);

  const changeNumber = (e: SyntheticEvent<Element, Event>, value: TCountryOption): void => {
    if (value) {
      setOption(value);
      setField && setField('code', value.phone_code);
    }
  };

  useEffect((): void => {
    setField && setField('code', selected.phone_code);
  }, [setField, selected.phone_code]);

  const getOptionLabel = useCallback((option): void => option[localeCountry], [localeCountry]);

  const onInputChange = useCallback((event, newInputValue): void => setInputValue(newInputValue), []);

  const onClose = useCallback((event): void => setInputValue(() => selected[localeCountry]), [localeCountry, selected]);

  return (
    <>
      <div className='phone-input'>
        <Field
          noValidate
          component={CustomSelectNew}
          selectType={SelectTypes.DropDownSelect}
          options={countriesList}
          onChange={changeNumber}
          name='code'
          label={t('Phone.countryTitle')}
          required
          disableCloseOnSelect
          errors={errorsCode}
          value={selected}
          inputValue={inputValue}
          onInputChange={onInputChange}
          onClose={onClose}
          noOptionsText='text'
          hasSearchIcon
          defaultValue={values?.code === '380' ? countriesList[0] : countriesList[locale === 'en' ? 1 : 0]}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option: TCountryOption, value: TCountryOption): boolean => option.pk === value.pk}
          renderOption={(params: AutocompleteRenderOptionState, option: TCountryOption): ReactElement => (
            <Box component='li' {...params}>
              <img loading='lazy' width='20' src={option.src} alt='' />
              {option[localeCountry]}
            </Box>
          )}
        />
        <Field
          className='phone-container'
          component={PhoneInput}
          {...props}
          errors={errorsPhone}
          setField={setField}
          name={'phone'}
          code={selected ? selected.phone_code : ''}
          label={`${t('Phone.phonePlaceholder')}`}
        />
      </div>
      {props.status && <div className='hollow' onClick={(): void => props.setIsOpen ? props.setIsOpen(false) : undefined} />}
    </>
  );
}
