import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material';
import { OneLineFieldTheme } from './../../../../../MUITheme';
import { useData } from './useData';

export function OneLineField({ ...props }: any): JSX.Element {
  const { readOnly } = useData()
  return (
    <ThemeProvider theme={OneLineFieldTheme}>
      <TextField 
        {...props} 
        variant='standard' 
        disabled 
        fullWidth 
        inputProps={readOnly} 
      />
    </ThemeProvider>
  );
}
