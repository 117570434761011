import type {
  TransformData,
  AllergyRequestParams,
  ServicesRequestParams,
  VaccineRequestParams,
  TreatmentRequestParams,
  VeterinaryRequestParams,
} from '../types';
import { isDateValid } from './isDateValid';
import { transformDateToUTC } from './transformTimeAndDate';
import { PetEventsTypes } from '../utils/enums/eventTypes';

export function transformData({ formikData, eventType, needToCheck, petId}: TransformData): object | 'invalid' {
  const canContinue = needToCheck 
    ? isDateValid(formikData.timeSetField, formikData.dateField)
    : true 

  if(!canContinue) return 'invalid'

  if (eventType === PetEventsTypes.ALLERGY) {
    const {dropdownMenuList, drug, dateField, timeSetField, notes} = formikData

    const requestObj: AllergyRequestParams = {
      allergy_type: dropdownMenuList,
      drug_name: drug,
      event_date: transformDateToUTC(dateField, timeSetField),
      additional_info: notes,
      pet_id: petId,
    };
    return requestObj;
  }
  if (eventType === PetEventsTypes.SERVICE) {
    const {name, address, institution, dateField, timeSetField, notes} = formikData
    const requestObj: ServicesRequestParams = {
      service_name: name,
      service_address: address,
      institution_name: institution,
      event_date:transformDateToUTC(dateField, timeSetField),
      additional_info: notes,
      pet_id: petId,
    };
    return requestObj;
  }
  if (eventType === PetEventsTypes.VACCINATION) {
    const {name, drug, dateField, timeSetField, notes} = formikData
    const requestObj: VaccineRequestParams = {
      vaccination_name: name,
      drug_name: drug,
      event_date: transformDateToUTC(dateField, timeSetField),
      additional_info: notes,
      pet_id: petId,
    };
    return requestObj;
  }
  if (eventType === PetEventsTypes.TREATMENT) {
    const {reason, address, drug, dateField, timeSetField, notes} = formikData
    const requestObj: TreatmentRequestParams = {
      treatment_reason: reason,
      vaccination_name: drug,
      event_date: transformDateToUTC(dateField, timeSetField),
      veterinary_address: address,
      additional_info: notes,
      pet_id: petId,
    };
    return requestObj;
  }
  if (eventType === PetEventsTypes.VETERINARIAN) {
    const {procedure, address, name, dateField, timeSetField, notes} = formikData
    const requestObj: VeterinaryRequestParams = {
      procedure_name: procedure,
      petclinic_name: name,
      event_date: transformDateToUTC(dateField, timeSetField),
      veterinary_address: address,
      additional_info: notes,
      pet_id: petId,
    };
    return requestObj;
  }
  return 'invalid';
}
