import { BoxProps, createFilterOptions, styled } from '@mui/material';

import { CountryTheme, PopUpTheme, theme } from '../../../../../../../MUITheme';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { TCustomSelectDataArgs } from '../../types';
import { SelectTypes } from '../../../../../../../utils/enums';

const imgBoxProps: BoxProps = {
  textAlign: 'center',
  margin: '0',
  left: '19px',
  top: '13px',
};

const StyledImg = styled('img')(() => ({
  objectFit: 'cover',
  width: '22.5px',
  height: '22.5px',
  borderRadius: '50%',
  border: `1px solid ${theme.palette.info.dark}`,
}));
const StyledSearchImg = styled('img')(() => ({
  width: '1.5rem',
  height: '1.5rem',
  marginLeft: '0.5rem',
  marginTop: '-1rem',
}));

const filterOptions = createFilterOptions({
  matchFrom: 'start',
});

const themeObj = {
  [SelectTypes.DropDownSelect]: CountryTheme,
  [SelectTypes.PopUpSelect]: PopUpTheme,
}


export function useData({ currValue, selectType }: TCustomSelectDataArgs) {
  const { t } = useTranslation();
  const noOptionsText = useMemo(() => t('signupPage.noCountriesFound'), [t]);

  const theme = themeObj[selectType]

  const InputLabelProps: {
    variant: 'filled' | 'outlined' | 'standard' | undefined;
    shrink?: boolean;
  } = {
    variant: 'filled',
    shrink: !!currValue,
  };

  return { InputLabelProps, imgBoxProps, StyledImg, StyledSearchImg, noOptionsText, filterOptions, theme };
}
