import { CustomTextarea } from '../../../../shared';
import type { PetEventDetailsProps } from '../../../types';
import type { ReactElement } from 'react';

export function PetFormikTextareaArray({ eventDetails }: PetEventDetailsProps): ReactElement {

  return (
    <div>
      {eventDetails.addPageTextareaFields?.map((inputField: any, index: number) => {
        if (!eventDetails.addPageTextareaFields) { return <></>; }
        const name = eventDetails.addPageTextareaFields[index].fieldName;
        const title = eventDetails.addPageTextareaFields[index].title;
        return (
          <CustomTextarea
            label={title}
            key={index}
            name={name}
            required={false}
            multiline={true}
            minRows={4}
            maxRows={4}
          />
        );
      })}
    </div>
  );
}
