import { CustomButton, Loader, WithFooterMenu } from '../../../shared';
import { useData, useGetEventData, useHandlers } from './hooks';
import { useLocation, useParams } from 'react-router';

import type { AnyOneEventResponse } from '../../types';
import Box from '@mui/material/Box';
import type { EventCardProps } from './types';
import { HeaderWithSearch } from '../../components/HeaderWithSearch';
import { PetEventInfo } from '../../components/PetEventInfo';
import { RequestState } from '../../../../../utils/enums';

export function PetEventCard({ eventDetails, url }: EventCardProps): JSX.Element {
  const location = useLocation<{ from: string }>();
  const params = useParams<{ petId: string; eventId: string }>();
  const [requestStatus, eventData] = useGetEventData(params.eventId, url);
  //@ts-ignore need to ignore construction eventData[eventDetails.mainField]
  const title = requestStatus === RequestState.Success ? eventData[eventDetails.mainField] : '';

  const { capitalizedTitle, editBtnText, petId, eventId } = useData(title, eventDetails.type);
  const { onEditHandler } = useHandlers({ petId, eventId });
  return (
    <WithFooterMenu>
      <HeaderWithSearch title={capitalizedTitle} path={location?.state?.from} withoutSearch>
        {}
      </HeaderWithSearch>
      <Box display='flex' position='relative' flexDirection='column' top='4rem' width='100%' padding={2}>
        {requestStatus === RequestState.Success ? (
          <PetEventInfo eventData={eventData as AnyOneEventResponse} eventDetails={eventDetails} />
        ) : (
          <Loader />
        )}
        <Box marginTop='1rem'>
          <CustomButton className='rounded-pill' onClick={onEditHandler}>
            {editBtnText}
          </CustomButton>
        </Box>
      </Box>
    </WithFooterMenu>
  );
}
