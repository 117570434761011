import axios from 'axios';
import type { AxiosError } from 'axios';
import { config } from '../config';
import { URLS } from '../const/urls';
import api from './api';

export type UrlsType = keyof typeof URLS;

// tslint:disable-next-line:cyclomatic-complexity
export async function get<ParamsType, ResponseType>(
  key: UrlsType,
  params: ParamsType,
  phone: string,
  token?: string,
  // @ts-ignore
): Promise<Core.RequestResponse<ResponseType>> {
  let response = null;
  let error = null;
  try {
    const headers = {
      Accept: 'application/json, text/plain, */*',
      // @ts-ignore
      Authorization: `JWT ${token.token}`,
    };
    // @ts-ignore
    if (phone.phone) {
      // @ts-ignore
      response = await api.get<ResponseType>(`${config.host}/${URLS[key]}${phone.phone}/`, { headers: headers });
      return { data: response.data ? response.data : response, error: null };
      // @ts-ignore
    } else if (token.token) {
      response = await api.get<ResponseType>(`${config.host}/${URLS[key]}/`, {
        params,
        headers: headers,
      });
      return { data: response.data ? response.data : response, error: null };
    } else {
      response = await api.get<ResponseType>(`${config.host}/${URLS[key]}/`, { params });
      return { data: response.data ? response.data : response, error: null };
    }
  } catch (e: unknown) {
    if (typeof e === 'object' && e?.hasOwnProperty('isAxiosError')) {
      error = e as AxiosError;
    } else {
      error = {
        config: {},
        isAxiosError: false,
        toJSON: (): object => ({}),
        name: '',
        message: '',
      };
    }

    return { data: null, error };
  }
}

export async function post<RequestParams, ResponseType>(
  key: UrlsType,
  params: RequestParams,
  token?: string | null,
  // @ts-ignore
): Promise<Core.RequestResponse<ResponseType>> {
  let response = null;
  let error = null;

  const headers: Record<string, string> = {};

  if (token) {
    headers['Authorization'] = token;
  }

  if (params instanceof FormData) {
    headers['Content-Type'] = 'multipart/form-data';
  }

  try {
    const headers = {
      Authorization: token,
    };

    if (token !== '') {
      response = await api.post<ResponseType>(`${config.host}/${URLS[key]}/`, params, {
        headers: headers,
      });
    } else {
      response = await axios.post<ResponseType>(`${config.host}/${URLS[key]}/`, params);
    }
    return { data: response.data ? response.data : response, error: null };
  } catch (e: unknown) {
    if (typeof e === 'object' && e?.hasOwnProperty('isAxiosError')) {
      error = e as AxiosError;
    } else {
      error = {
        config: {},
        isAxiosError: false,
        toJSON: (): object => ({}),
        name: '',
        message: '',
      };
    }

    return { data: null, error };
  }
}

export async function put<RequestParams, ResponseType>(
  key: UrlsType,
  requested_id: string | number | null,
  params: RequestParams,
  token: string | null,
  // @ts-ignore
): Promise<Core.RequestResponse<ResponseType>> {
  let response = null;
  let error = null;
  try {
    const headers = {
      Authorization: token,
    };
    if (requested_id) {
      response = await api.put<ResponseType>(`${config.host}/${URLS[key]}${requested_id}/`, params, {
        headers: headers,
      });
    } else {
      response = await api.put<ResponseType>(`${config.host}/${URLS[key]}/`, params, {
        headers: headers,
      });
    }
    return { data: response.data ? response.data : response, error: null };
  } catch (e: unknown) {
    if (typeof e === 'object' && e?.hasOwnProperty('isAxiosError')) {
      error = e as AxiosError;
    } else {
      error = {
        config: {},
        isAxiosError: false,
        toJSON: (): object => ({}),
        name: '',
        message: '',
      };
    }

    return { data: null, error };
  }
}

export async function patch<RequestParams, ResponseType>(
  key: UrlsType,
  requested_id: string | number | null,
  params: RequestParams,
  token: string | null,
  // @ts-ignore
): Promise<Core.RequestResponse<ResponseType>> {
  let response = null;
  let error = null;

  try {
    const headers = {
      Authorization: token,
    };
    if (requested_id) {
      response = await api.patch<ResponseType>(`${config.host}/${URLS[key]}${requested_id}/`, params, {
        headers: headers,
      });
    } else {
      response = await api.patch<ResponseType>(`${config.host}/${URLS[key]}/`, params, {
        headers: headers,
      });
    }
    return { data: response.data ? response.data : response, error: null };
  } catch (e: unknown) {
    if (typeof e === 'object' && e?.hasOwnProperty('isAxiosError')) {
      error = e as AxiosError;
    } else {
      error = {
        config: {},
        isAxiosError: false,
        toJSON: (): object => ({}),
        name: '',
        message: '',
      };
    }

    return { data: null, error };
  }
}

export async function remove<ResponseType>(
  key: UrlsType,
  id: string | number | null,
  token: string | null,
  // @ts-ignore
): Promise<Core.RequestResponse<ResponseType>> {
  let response = null;
  let error = null;

  try {
    const headers = {
      Authorization: token,
    };
    if (id) {
      response = await api.delete<ResponseType>(`${config.host}/${URLS[key]}/${id}/`, {
        headers: headers,
      });
    } else {
      response = await api.delete<ResponseType>(`${config.host}/${URLS[key]}/`, {
        headers: headers,
      });
    }
    return { data: response.data ? response.data : response, error: null };
  } catch (e: unknown) {
    if (typeof e === 'object' && e?.hasOwnProperty('isAxiosError')) {
      error = e as AxiosError;
    } else {
      error = {
        config: {},
        isAxiosError: false,
        toJSON: (): object => ({}),
        name: '',
        message: '',
      };
    }

    return { data: null, error };
  }
}

export type TRequestResponse<ResponseType> = {
  data: ResponseType | null;
  error: AxiosError | null;
};

export async function getById<ResponseType>(
  key: UrlsType,
  id: number | null,
  token: string | null,
): Promise<TRequestResponse<ResponseType>> {
  try {
    const response = await api.get<ResponseType>(`${config.host}/${URLS[key]}/${id}`, {
      headers: {
        Accept: 'application/json, text/plain, */*',
        Authorization: `JWT ${token}`,
      },
    });
    return { data: response.data, error: null };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return { data: null, error };
    }

    return { data: null, error: null };
  }
}
