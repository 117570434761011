import { useEffect, useMemo, useState } from 'react';
import arrow from '../../../../../assets/images/pet/arrow-change.png';
import { ReactComponent as OutdoorScheduleDeleteIcon } from '../../../../../assets/images/pet/outdoor_schedule_delete.svg';
import { getTimeFormatByGeolocation } from '../../../../../utils/helpers/getTimeFormatByGeolocation';
import './PetTimeSet.scss';

type Props = {
  placeholder?: string | number;
  time: number;
  onChange?: (value: number) => void;
  onClear?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  incrementAmount?: number;
  decrementAmount?: number;
  minTime?: number;
  maxTime?: number;
};

const getPadTime = (t: number) => t.toString().padStart(2, '0');

function convertMinTo12HourFormat(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const amPm = hours < 12 ? 'AM' : 'PM';
  const formattedHours = hours % 12 || 12;
  const formattedMinutes = remainingMinutes.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes} ${amPm}`;
}

function convertMinTo24HourFormat(minutes: number): string {
  const hours = getPadTime(Math.floor(minutes / 60));
  const calculatedMinutes = getPadTime(minutes - Number(hours) * 60);

  return `${hours}:${calculatedMinutes}`;
}

export function PetTimeSet(props: Props) {
  const { placeholder, time, onChange, onClear, incrementAmount, decrementAmount, minTime, maxTime } = props;
  const [toggleClass, setToggleClass] = useState('inactive');
  const [currentTime, setCurrentTime] = useState(time);
  const [is12HourFormat, setIs12HourFormat] = useState(false);

  useEffect(() => {
    const getTimeFormat = async () => {
      try {
        const timeFormat = await getTimeFormatByGeolocation();
        setIs12HourFormat(timeFormat === '12-hour');
      } catch (error) {
        console.error('Error retrieving geoposition:', error);

        const userLanguage = navigator.language;
        if (userLanguage === 'ru') {
          setIs12HourFormat(false);
        } else {
          const format = new Intl.DateTimeFormat(userLanguage, {
            hour: 'numeric',
            hour12: true,
          }).format(new Date());
          setIs12HourFormat(format.includes('AM') || format.includes('PM'));
        }
      }
    };
    getTimeFormat();
  }, []);

  const isIncrementButtonDisabled = useMemo(() => Boolean(maxTime && currentTime >= maxTime), [maxTime, currentTime]);

  const isDecrementButtonDisabled = useMemo(() => Boolean(minTime && currentTime <= minTime), [minTime, currentTime]);

  const timeFormatter = is12HourFormat ? convertMinTo12HourFormat : convertMinTo24HourFormat;

  useEffect((): void => {
    if (Math.floor(currentTime / 60) >= 24 || Math.floor(currentTime / 60) < 0) {
      setCurrentTime(0);
    }
  }, [currentTime]);

  const handleTimeIncrease = () => {
    const newTime = incrementAmount ? currentTime + incrementAmount : currentTime + 5;
    setCurrentTime(newTime);
    onChange?.(newTime);
  };

  const handleTimeDecrease = () => {
    const newTime = decrementAmount ? currentTime - decrementAmount : currentTime - 5;
    if (newTime < 0) {
      setCurrentTime(() => 1435);
      return;
    }
    setCurrentTime(newTime);
    onChange?.(newTime);
  };

  return (
    <div
      onMouseEnter={(): void => {
        setToggleClass('active');
      }}
      onMouseLeave={(): void => {
        setToggleClass('inactive');
      }}
      className={`PetTimeSet ${toggleClass} `}>
      <span className='PetTimeSet-index'>{placeholder}</span>
      <span className='PetTimeSet-choice'>
        <button
          type='button'
          className='time-decrease'
          disabled={isDecrementButtonDisabled}
          onClick={handleTimeDecrease}>
          <img src={arrow} alt='decrease' />
        </button>

        <span className='PetTimeSet-time'>{timeFormatter(currentTime)}</span>

        <button
          type='button'
          className='time-uncrease'
          onClick={handleTimeIncrease}
          disabled={isIncrementButtonDisabled}>
          <img src={arrow} alt='uncrease' />
        </button>
        {onClear && (
          <button type='button' className='PetTimeSet-remove' onClick={onClear}>
            <OutdoorScheduleDeleteIcon />
          </button>
        )}
      </span>
    </div>
  );
}
