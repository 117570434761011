import type { PetEventTypeSelectorContainerProps } from '../../types';
import { PetToSimilarEventButtonMemo } from '.';
import Stack from '@mui/material/Stack/Stack';
import { useData } from './hooks/useData';

export function PetEventTypeSelectorContainer({ petId, tabState, dateValue, hasAllergy }: PetEventTypeSelectorContainerProps) {
  const { toEventBtns } = useData({ tabState, hasAllergy });
  return (
    <Stack spacing={1} direction='column' justifyContent='center' alignItems='flex-start' padding='14px 16px 0 16px'>
      {toEventBtns.map(({ icon, title, link, warning }, index) => (
        <PetToSimilarEventButtonMemo key={index} icon={icon} title={title} link={link} petId={petId} tabState={tabState} date={dateValue} warning={warning}/>
      ))}
    </Stack>
  );
}
