import { ReactElement, useState } from 'react';
import './InputSearch.scss';

export function InputSearch(): ReactElement {
    const [search, setSearch] = useState('');
    return (
        <div className='InputSearch'>
            <input type='search' placeholder='Search...' value={search}
                autoComplete='off'
                onChange={(e): void => setSearch(e.target.value)} />
            <span className='InputSearch_icon'> </span>
        </div>
    );
}
