import { FormikValues } from 'formik';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import cities from '../../../../../../assets/i18n/cities.json';
import type { TCityData, TRadioButtonGroupOptions } from '../../../utils/types/types';
const USER_LOCALE = navigator.language;
export function useData({ values }: { values: FormikValues }): {
  radioButtonGroupOptions: TRadioButtonGroupOptions[];
  getValueSearchSelect: string;
} {
  const { t } = useTranslation();

  const radioButtonGroupOptions = useMemo((): TRadioButtonGroupOptions[] => {
    return [
      { value: 'male', label: t('ProfilePage.placeholders.male') },
      { value: 'female', label: t('ProfilePage.placeholders.female') },
    ];
  }, [t]);

  const getValueSearchSelect = useMemo((): string => {
    const city = cities.city.data.find((city: TCityData): boolean => city.id === values.city);
    // @ts-ignore
    return city ? city[`name_${USER_LOCALE}`] : '';
  }, [values.city]);

  return { radioButtonGroupOptions, getValueSearchSelect };
}
