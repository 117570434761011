import cn from 'classnames';
import React, { type ReactElement, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { MAX_OUTDOOR_TIME, MIN_OUTDOOR_TIME } from '../../../../../utils/const';
import { notificationSelector } from '../../../../../utils/selectors';
import { PetTimeSet } from '../../../pet/components/PetTimeSet';
import './FormOutdoorSchedule.scss';

type TProps = {
  label?: string;
  field: any;
  form: any;
  values?: number[];
};

export function FormOutdoorSchedule({ label, field, form, values }: TProps): ReactElement {
  const { t } = useTranslation();
  const [, addNotification] = useRecoilState(notificationSelector);
  const { name, value } = field;
  const valueArray = values !== undefined ? values : value;

  const lastScheduleItem = useMemo((): number => valueArray[valueArray.length - 1], [valueArray]);

  const canAddNewSchedule = useMemo(
    (): boolean => !lastScheduleItem || lastScheduleItem < MAX_OUTDOOR_TIME,
    [lastScheduleItem],
  );

  const isThereAnySchedule = useMemo((): boolean => value.length > 0, [value.length]);

  const handlePetTimeChange = (index: number, newValue: number): void => {
    const updatedSchedule = [...valueArray];
    const updatedItem = newValue;

    for (let i = index - 1; i >= 0; i--) {
      if (updatedSchedule[i] === updatedItem) {
        updatedSchedule.splice(i, 1);
        index--;
      } else {
        break;
      }
    }

    for (let i = index + 1; i < updatedSchedule.length; i++) {
      if (updatedSchedule[i] === updatedItem) {
        updatedSchedule.splice(i, 1);
      } else {
        break;
      }
    }

    updatedSchedule[index] = updatedItem;
    form.setFieldValue(name, updatedSchedule);
  };

  const handleAddOutdoorTime = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();

    if (valueArray.length === 4) {
      addNotification({
        text: t('petForm.limit_notification'),
        type: 'warning',
      });
    } else {
      const prevListItem = valueArray[valueArray.length - 1] || MIN_OUTDOOR_TIME;
      const newListItem = prevListItem + 60;
      const newSchedule = [...valueArray, newListItem];

      form.setFieldValue(name, newSchedule);
    }
  };

  const handleDeleteOutdoorTime = (index: number): void => {
    const newSchedule = valueArray.filter((item: any, itemIndex: number): boolean => itemIndex !== index);
    form.setFieldValue(name, newSchedule);
  };

  return (
    <div
      className={cn('scheduleContainer', {
        scheduleContainerWithItems: isThereAnySchedule,
      })}>
      <p className='scheduleTitle'>{label}</p>
      <div key={valueArray.length}>
        {valueArray?.map(
          (outdoorTime: any, index: number): ReactElement => (
            <PetTimeSet
              placeholder={index + 1}
              key={index}
              time={outdoorTime}
              onChange={(newValue: number): void => handlePetTimeChange(index, newValue)}
              onClear={(): void => handleDeleteOutdoorTime(index)}
              incrementAmount={60}
              decrementAmount={60}
              minTime={5 * 60}
              maxTime={MAX_OUTDOOR_TIME}
            />
          ),
        )}
        {canAddNewSchedule && (
          <div className='scheduleAddTime'>
            <button className='scheduleAddTimeButton' type='button' onClick={handleAddOutdoorTime}></button>
            <span className='scheduleAddTimeButtonText' role='button' onClick={handleAddOutdoorTime}>
              {t('petForm.add')}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}
