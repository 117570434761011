import { Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useFetchBreed } from '../../../hooks';
import './CustomKindSelectWithPagination.scss';
import type { TProps } from './types/types';
import { PageLoader } from '../../../../shared';

let limit = 100;

const CustomKindSelectWithPagination = ({ label, field, kindId, form, breedId }: TProps) => {
  const {breeds, loadMoreBreeds, loading: breedsIsLoading} = useFetchBreed(kindId, limit, breedId);

  const language = localStorage.getItem('language');

  const shrinkStyle = {
    top: '-5px',
    fontSize: '8px',
    marginTop: '-4px',
    padding: 0,
    lineHeight: '10.91px',
  };

  const handleScroll = (event: { target: HTMLElement }) => {
    const { target } = event;
    if (target.scrollTop + target.clientHeight + 100 >= target.scrollHeight) {
      loadMoreBreeds();
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;
    form.setFieldValue(field.name, value);
  };

  return (
    <>
    {breedId && breedsIsLoading && <PageLoader/>}
    <FormControl style={{ width: '100%' }}>
      <InputLabel
        shrink={!!field.value || field.value === ''}
        style={{
          color: '#A1A4B2',
          fontFamily: 'Nunito-Regular',
          marginLeft: '20px',
          transform: 'translateY(18px)',
          ...(field.value ? shrinkStyle : {}),
        }}>
        {label}
      </InputLabel>
      <Select
        className={`petFormSelect ${!kindId ? 'disabled' : ''}`}
        {...field}
        value={field.value || ''}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            onScroll: handleScroll,
          },
        }}
        disabled={!kindId}>
        <MenuItem value=''></MenuItem>
        {breeds.map(breed => (
          <MenuItem key={breed.id} value={breed.id}>
            <Typography
              component='div'
              noWrap
              className='petFormSelectTypography'
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}>
              {language === 'uk' ? breed.breed_name_ua : breed.breed_name_en}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    </>
  );
};

export default CustomKindSelectWithPagination;
