import { getMonthFromDate, today, transformDateToFormat } from '../../../../../../utils/helpers/dateHelper';

import type { Dayjs } from 'dayjs';
import type { TCalendarWidgetHandlers } from '../../../types';
import { YEAR_MONTH_FORMAT } from '../../../../../../utils/const';
import { useCallback } from 'react';
import { useFormikContext } from 'formik';

export function useHandlers({
  date,
  setDate,
  setDateValue,
  handleDayChanging,
  isFormPart,
  fieldName,
  setYearMonth,
  setReturnedToToday,
}: TCalendarWidgetHandlers) {
  const context = useFormikContext();
  const onDayChange = useCallback(
    (value): void => {
      const currentMonth = getMonthFromDate(date);
      const newMonth = getMonthFromDate(value);

      setDate(value);
      if (handleDayChanging) {
        handleDayChanging(value);
      }
      if (currentMonth !== newMonth && setYearMonth) setYearMonth(transformDateToFormat(value, YEAR_MONTH_FORMAT));
      if (isFormPart && fieldName) {
        context.setFieldValue(fieldName, value?.toDate());
      }
    },
    [context, date, fieldName, handleDayChanging, isFormPart, setDate, setYearMonth],
  );

  const onMonthChange = useCallback(
    (month: Dayjs): void => {
      setDate(month);
      if (setYearMonth) setYearMonth(transformDateToFormat(month, YEAR_MONTH_FORMAT));
      if (setDateValue) setDateValue(month);
    },
    [setDate, setDateValue, setYearMonth],
  );

  const onYearChange = useCallback(
    (year: Dayjs): void => {
      setDate(year);
      if (setYearMonth) setYearMonth(transformDateToFormat(year, YEAR_MONTH_FORMAT));
    },
    [setDate, setYearMonth],
  );

  const onTodayClick = useCallback(
    (form: any): void => {
      setDate(today);
      if (handleDayChanging) {
        handleDayChanging(today);
      }
      if (isFormPart && fieldName) {
        form.setFieldValue(fieldName, today.toDate());
      }
      setReturnedToToday(true);
    },
    [fieldName, handleDayChanging, isFormPart, setDate, setReturnedToToday],
  );

  return { onDayChange, onMonthChange, onYearChange, onTodayClick };
}
