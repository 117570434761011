import React, { type ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import './FooterMenu.scss';

export function FooterMenu(): ReactElement {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('');
  const history = useHistory();
  const handleClick = (id: string): void => {
    if (id !== 'custom') {
      setActiveTab(id);
      // @ts-ignore
      setTimeout((): void => (document.getElementById(id).style.color = '#3cccdb'), 100);
      history.push(`/${id}/main`);
    } else {
      if (history.location.pathname !== `/dashboard/main`) {
        history.push(`/dashboard/main`);
      } else {
        window.history.go();
      }
    }
  };
  useEffect((): void => {
    if (activeTab !== '') {
      localStorage.setItem('activeTab', activeTab);
      // @ts-ignore
      document.getElementById(localStorage.getItem('activeTab', activeTab)).style.color = '#3cccdb';
    }
  }, [activeTab]);

  return (
    <div className='footerMenu'>
      <div id={'errors'} className={`footerMenuTab inactive`}>
        {/*onClick={(): void => handleClick('errors')}>*/}
        <div className={'calendar_icon'}>
          <i className={`bi bi-calendar-fill`}></i>
        </div>
        <p>{t('shared.footerMenu.calendar')}</p>
      </div>
      <div id={'map'} className={`footerMenuTab inactive`}>
        {/*onClick={(): void => handleClick('map')}>*/}
        <div className={'map_icon'}>
          <i className={`bi bi-pin-map-fill`}></i>
        </div>
        <p>{t('shared.footerMenu.map')}</p>
      </div>
      <div id={'custom'} className={`footerMenuTab custom`} onClick={(): void => handleClick('custom')}>
        <p>{'custom'}</p>
      </div>
      <div id={'chat'} className={`footerMenuTab`} onClick={(): void => handleClick('chat')}>
        <div className={'chat_icon'}>
          <i className={`bi bi-chat-fill`}></i>
        </div>
        <p>{t('shared.footerMenu.chat')}</p>
      </div>
      <div id={'contacts'} className={`footerMenuTab`} onClick={(): void => handleClick('contacts')}>
        <div className={'contacts_icon'}>
          <i className={`bi bi-person-fill`}></i>
        </div>
        <p>{t('shared.footerMenu.contacts')}</p>
      </div>
    </div>
  );
}
