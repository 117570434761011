import React, { type ReactElement } from 'react';
import './ErrorInput.scss';

export function ErrorInput(props: any): ReactElement {
  const { error } = props;

  return (
    <>
      {error && (
        <div className='error-container'>
          <p className='error' {...props}>
            {error}
          </p>
        </div>
      )}
    </>
  );
}
