import {
  EN_LOCALE_DATE_WITH_TIME_FORMAT,
  EN_LOCALE_TIME_FORMAT,
  UK_LOCALE_DATE_POINTS_FORMAT_DAYJS,
  EN_LOCALE_DATE_POINTS_FORMAT_DAYJS,
  UK_LOCALE_DATE_WITH_TIME_FORMAT,
  UK_LOCALE_TIME_FORMAT,
  USER_LOCALE,
} from '../../../../utils/const';
import { getDateWithTimeFromUTC, transformDateToFormat, transformRangeDateToUTC } from '../../../../utils/helpers/dateHelper';
import { useCallback, useEffect, useState } from 'react';

import type { Dayjs } from 'dayjs';
import { Locales } from '../../../../utils/enums/Locales';

export function useDateFunction({
  initialDate,
  dateFrom,
  dateTo,
}: {
  initialDate?: string;
  dateFrom?: string | Dayjs | null;
  dateTo?: string | Dayjs | null;
}) {
  const [dateTimeFormat, setDateTimeFormat] = useState(UK_LOCALE_DATE_WITH_TIME_FORMAT);
  const [dateFormat, setDateFormat] = useState(UK_LOCALE_DATE_POINTS_FORMAT_DAYJS);
  const [timeFormat, setTimeFormat] = useState(UK_LOCALE_TIME_FORMAT);

  useEffect(() => {
    if (Locales.UK.includes(USER_LOCALE) || Locales.RU.includes(USER_LOCALE)) {
      setDateTimeFormat(UK_LOCALE_DATE_WITH_TIME_FORMAT);
      setDateFormat(UK_LOCALE_DATE_POINTS_FORMAT_DAYJS);
      setTimeFormat(UK_LOCALE_TIME_FORMAT);
    } else {
      setDateTimeFormat(EN_LOCALE_DATE_WITH_TIME_FORMAT);
      setDateFormat(EN_LOCALE_DATE_POINTS_FORMAT_DAYJS);
      setTimeFormat(EN_LOCALE_TIME_FORMAT);
    }
    return;
  }, []);

  const getFormattedDateWithTime = useCallback(() => {
    if (!initialDate) return;
    return transformDateToFormat(initialDate, dateTimeFormat);
  }, [dateTimeFormat, initialDate]);

  const transformRangeDateToUTCHook = useCallback((): [UTCStartDate: string, UTCFinishDate: string] => {
    if (!dateFrom) return ['', ''];

    return transformRangeDateToUTC(dateFrom, dateTo || dateFrom);
  }, [dateFrom, dateTo]);

  const getDateFromUTCHook = useCallback(
    (utcDate: string): [date: string, time: string] => {
      return getDateWithTimeFromUTC(utcDate, dateFormat, timeFormat)
    },
    [dateFormat, timeFormat],
  );

  return { getFormattedDateWithTime, transformRangeDateToUTCHook, getDateFromUTCHook };
}
