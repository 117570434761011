import { PetGalleryPhoto } from '../PetGalleryPhoto';
import './PetGalleryDateBlock.scss';

type Props = {
    date: number,
    month: string,
    withAddButton?: boolean,
    photosForThatDate: string[]
}

// tslint:disable-next-line:typedef
export function PetGalleryDateBlock(props: Props) {
    const { date, month, withAddButton = false, photosForThatDate } = props

    return (
        <div className="PetGalleryDateBlock">
            <div className='gallery-date-divider'>
                <div className=''>
                    <span className='date-divider-day'>
                        {date}
                    </span>
                    <span className='date-divider-month'>
                        {month}
                    </span>
                </div>
            </div>
            <div className='pet-gallery-row'>
                {withAddButton && (
                    <div className='pet-gallery-add'>
                        <button className='add-button'></button>
                    </div>
                )}
                {photosForThatDate.map((photo, index) => (
                    <PetGalleryPhoto key={index}/>
                ))}
            </div>
        </div>
    )
}