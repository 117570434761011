import { useMemo } from 'react';
import wide_placeholder from '../../../../../../assets/images/bg.jpg';
import placeholder from '../../../../../../assets/images/cropped.jpg';
import { LocalStorageKeys, ScreenSizes } from '../../../../../../utils/enums';
import { useWindowSize } from '../../../../../../utils/hooks';

const phoneVerified = !!localStorage.getItem(LocalStorageKeys.IsPhoneNumberVerified);

export function useData(): {
  phoneVerified: boolean;
  backgroundImg: string;
  contentBorderRadius: string;
  backgroundSettings: object;
  contentBackground: { background: string };
  actionsBackground: { background: string };
  contentPosition: 'relative' | 'absolute';
} {
  const [windowWidth] = useWindowSize();
  const backgroundImg = useMemo(
    (): string => (windowWidth < ScreenSizes.Small ? placeholder : wide_placeholder),
    [windowWidth],
  );
  const contentBorderRadius = useMemo((): string => (windowWidth < ScreenSizes.Small ? '16px' : '0'), [windowWidth]);
  const backgroundSettings = useMemo(
    (): object =>
      windowWidth < ScreenSizes.Small
        ? {
            width: 'inherit',
            height: 'inherit',
            maxHeight: '100vh',
          }
        : {
            width: 'inherit',
            height: 'inherit',
            maxWidth: '100vw',
          },
    [windowWidth],
  );
  const contentBackground = useMemo(
    (): { background: string } =>
      windowWidth < ScreenSizes.Small
        ? { background: '' }
        : {
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), rgba(255, 255, 255, 0.35)',
          },
    [windowWidth],
  );
  const contentPosition = useMemo(
    (): 'relative' | 'absolute' => (windowWidth < ScreenSizes.Small ? 'relative' : 'absolute'),
    [windowWidth],
  );
  const actionsBackground = useMemo(
    (): { background: string } =>
      windowWidth < ScreenSizes.Small
        ? {
            background:
              'linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), rgba(255, 255, 255, 0.35)',
          }
        : {
            background: '',
          },
    [windowWidth],
  );

  return {
    phoneVerified,
    backgroundImg,
    contentBorderRadius,
    backgroundSettings,
    contentBackground,
    contentPosition,
    actionsBackground,
  };
}
