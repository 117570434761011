import type { TPetEditForm } from '../types';
import { PetFields } from '../../../enums';

export const prepareDataForRequest = (data: TPetEditForm) => {
  if (!data.avatar || typeof data.avatar === 'string') {
    delete data.avatar;
  }

  return {
    ...data,
    food: data.food === PetFields.Feed ? PetFields.Manufactured : data.food,
    id_type: data.id_type?.toLowerCase() ?? '',
    birth: new Date(data.birth).toISOString().split('T')[0],
    name: data.name.replace(/^[^a-zA-Zа-яА-ЯґҐєЄіІїЇ']+|[,-](?=[,-]+$)/g, '').trim(),
    color: data.color
      .replace(/^[^a-zA-Zа-яА-ЯґҐєЄіІїЇ']+|[,-](?=[,-]+$)|-+/g, '')
      .replace(/ {2,}/g, ' ')
      .trim(),
    special_signs: data.special_signs
      .replace(/^[^a-zA-Zа-яА-ЯґҐєЄіІїЇ\d']+|[,-](?=[,-]+$)|-+/g, '')
      .replace(/ {2,}/g, ' ')
      .trim(),
  };
};
