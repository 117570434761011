import React, {SyntheticEvent, useCallback, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {initCodeState} from '../../VerifyPhoneCode';
import './PhoneCode.scss';

// tslint:disable-next-line:no-any typedef cyclomatic-complexity
export const PhoneCode = (props: any) => {
    const {t} = useTranslation();
    const {codeChange} = props;
    const codeDigit1 = useRef(null);
    const codeDigit2 = useRef(null);
    const codeDigit3 = useRef(null);
    const codeDigit4 = useRef(null);
    const codeDigit5 = useRef(null);
    const codeDigit6 = useRef(null);
    const handleOnKeyup = useCallback(
        (event: SyntheticEvent): void => {
            const id = (event.nativeEvent.target as HTMLFormElement)?.dataset['id'] as keyof typeof initCodeState;
            const regex = /\D/g;
            // @ts-ignore
            if (event.key.match(regex)) {
                codeChange({[id]: ''});
                // @ts-ignore
                (event.target as HTMLInputElement).value = '';
            } else {
                // @ts-ignore
                codeChange({[id]: event.key});
                // @ts-ignore
                (event.target as HTMLInputElement).value = event.key;
                focusOnNext(id);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [codeChange],
    );
    // tslint:disable-next-line:cyclomatic-complexity
    const focusOnNext = (current: string): void => {
        switch (current) {
            case 'codeDigit1':
                (codeDigit2.current as unknown as HTMLElement).focus();
                break;
            case 'codeDigit2':
                (codeDigit3.current as unknown as HTMLElement).focus();
                break;
            case 'codeDigit3':
                (codeDigit4.current as unknown as HTMLElement).focus();
                break;
            case 'codeDigit4':
                (codeDigit5.current as unknown as HTMLElement).focus();
                break;
            case 'codeDigit5':
                (codeDigit6.current as unknown as HTMLElement).focus();
                break;
            case 'codeDigit6':
                props.setNextEl ? props.setNextEl('true') : (codeDigit1.current as unknown as HTMLElement).focus();
                break;
        }
    };
    return (
        <div className='phone-code-container'>
            <div onKeyUpCapture={handleOnKeyup} className={`phone-code`}>
                <input className={props.codeError ? ('incorrect') : ('')}
                       type='text' data-id='codeDigit1' maxLength={1} inputMode='numeric' pattern='[0-9]*' ref={codeDigit1} autoFocus required/>
                <input className={props.codeError ? ('incorrect') : ('')}
                       type='text' data-id='codeDigit2' maxLength={1} inputMode='numeric' pattern='[0-9]*' ref={codeDigit2} required/>
                <input className={props.codeError ? ('incorrect') : ('')}
                       type='text' data-id='codeDigit3' maxLength={1} inputMode='numeric' pattern='[0-9]*' ref={codeDigit3} required/>
                <input className={props.codeError ? ('incorrect') : ('')}
                       type='text' data-id='codeDigit4' maxLength={1} inputMode='numeric' pattern='[0-9]*' ref={codeDigit4} required/>
                <input className={props.codeError ? ('incorrect') : ('')}
                       type='text' data-id='codeDigit5' maxLength={1} inputMode='numeric' pattern='[0-9]*' ref={codeDigit5} required/>
                <input className={props.codeError ? ('incorrect') : ('')}
                       type='text' data-id='codeDigit6' maxLength={1} inputMode='numeric' pattern='[0-9]*' ref={codeDigit6} required/>
            </div>
            {props.codeError && (<div className='error-container'>
                <p className='error'>{t('verifyPhoneCode.error')}</p>
                <span> </span>
            </div>)}
        </div>
    );
};
