import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Fragment, useState } from 'react';
import camera from '../../../../../assets/images/pet/camera.png';
import delete_photo from '../../../../../assets/images/pet/delete_photo.png';
import delete_red from '../../../../../assets/images/pet/delete_red.png';
import docAvatar from '../../../../../assets/images/pet/doc_avatar.png';
import gallery from '../../../../../assets/images/pet/gallery.png';
import { HeaderWithSearch } from '../../components/HeaderWithSearch';
// import { PetFormikEventInput } from '../../components/PetEventInput';
// import { PetEventTextarea } from '../../components/PetEventTextarea';
// import { PetTimeSet } from '../../components/PetTimeSet';
import type { PetEventDetails } from '../../types';
import './EditPetAppointmentsVet.scss';
import {WithFooterMenu} from "../../../shared";

type Props = {
  eventsDetails: PetEventDetails;
};

// This file will be remake later

const avatars: string[] = [docAvatar, docAvatar, docAvatar, docAvatar];

// tslint:disable-next-line:typedef cyclomatic-complexity
export function EditPetAppointmentsVet(props: Props) {
  const { eventsDetails } = props;
  const maxDateForDP = new Date();
  const [dataState, setDataState] = useState('valid');
  // const [toggle, setToggle] = useState(true);
  const [avatarPopUpShow, setAvatarPopupShow] = useState(false);
  const [deletePhotoPopupShow, setDeletePhotoPopupShow] = useState(false);
  const [newData, setNewData] = useState({
    eventDate: null,
  });
  const [time] = useState(23 * 60);

  const handleAvatarPopupShow = (): void => {
    setAvatarPopupShow(true);
  };

  const handleDeletePhotoPopupShow = (): void => {
    setDeletePhotoPopupShow(true);
  };

  const handlePopupClose = (): void => {
    if (avatarPopUpShow) {
      setAvatarPopupShow(false);
    }
    if (deletePhotoPopupShow) {
      setDeletePhotoPopupShow(false);
    }
  };

  const toggleDataButton = (): void => {
    dataState === 'valid'
      ? setDataState('saved')
      : dataState === 'saved'
      ? setDataState('valid')
      : setDataState('invalid');
  };
 
  return (
    <Fragment>
      <WithFooterMenu>
        <HeaderWithSearch title='' path='main' withoutSearch={true}>
        {/* <HeaderWithSearch title={eventsDetails.addPageTitle} path='main' withoutSearch={true}> */}
          <></>
        </HeaderWithSearch>
        <div className='add-event'>
          <div className='pet-appointmentsVet-avatarBlock'>
            <div className='pet-appointmentsVet-avatars' onClick={handleAvatarPopupShow}>
              <img src={docAvatar} alt='avatar' />
            </div>
            <div className='pet-appointmentsVet-buttons'>
              <button className='pet-appointmentsVet-button'>
                <img src={camera} alt='camera' />
                <span>Camera</span>
              </button>
              <button className='pet-appointmentsVet-button'>
                <img src={gallery} alt='gallery' />
                <span>Gallery</span>
              </button>
            </div>
          </div>
          <form action='' className={`add-event-form ${dataState === 'saved' ? 'children_border-saved' : ''}`}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                className='custom-input'
                disableFuture
                value={newData?.eventDate ? newData?.eventDate : null}
                id='vet'
                name='vet'
                format='yyyy-MM-dd'
                openTo='date'
                maxDate={maxDateForDP.getTime() - 252455616000}
                placeholder={'Date'}
                required
                views={['date']}
                onChange={(value): void => {
                  if (value && value.getTime() < maxDateForDP.getTime() - 252455616000) {
                    const month = value.getMonth() + 1;
                    const day = value.getDate();
                    const oDay = day < 10 ? `0${day}` : day;
                    const year = value?.getFullYear();

                    // @ts-ignore
                    setNewData({ ...newData, eventDate: `${year}-${month}-${oDay}` });
                  }
                }}
              />
            </MuiPickersUtilsProvider>
            {/* tslint:disable-next-line:typedef */}
            {/* {eventsDetails.addPageInputFields?.map(inputField => ( */}
              {/* // <PetFormikEventInput placeholder={inputField.title} required /> */}
            {/* // ))} */}
            {/* <PetTimeSet time={time} placeholder={'Time'} /> */}
            {/* tslint:disable-next-line:typedef */}
            {/* {eventsDetails.addPageTextareaFields?.map(textareaField => (
              <PetEventTextarea placeholder={textareaField.title} />
            ))} */}
            <button className='save-event-button' disabled={dataState === 'invalid'} onClick={toggleDataButton}>
              {dataState !== 'saved' ? 'Submit' : 'Edit'}
            </button>
          </form>
        </div>
      </WithFooterMenu>
      {avatarPopUpShow && (
        <div className='edit-vetAvatar-popup-overlay' onClick={handlePopupClose}>
          <div className='edit-vetAvatar-popup'>
            <div className='edit-vetAvatar-popup-content'>
              <img src={docAvatar} alt='avatar' className='avatar-image' />
              <button className='delete-button' onClick={handleDeletePhotoPopupShow}>
                <img src={delete_red} alt='delete' />
                <span>Delete</span>
              </button>
              <div className='avatars-row'>
                {/* tslint:disable-next-line:typedef */}
                {avatars.map(avatar => (
                  <img src={avatar} alt='avatar' className='avatar-image-small' />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
      {deletePhotoPopupShow && (
        <div className='edit-vetAvatar-popup-overlay' onClick={handlePopupClose}>
          <div className='edit-vetAvatar-popup'>
            <div className='edit-vetAvatar-popup-content'>
              <img src={delete_photo} alt='delete icon' className='delete-icon' />
              <p className='delete-text'>Are you sure that you want to delete this photo?</p>
              <div className='delete-buttons'>
                <button className='cancel-button' onClick={handlePopupClose}>
                  Cancel
                </button>
                <button className='delete-photo-button'>Yes, delete</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
