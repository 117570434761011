import { Form, Formik } from 'formik';
import { PetFormikDropdownField, PetFormikInputArray, PetFormikTextareaArray } from './EventFormFields';
import { useData, useEventFormHandlers } from './hooks';
import { useLocation, useParams } from 'react-router';

import { CalendarWidgetMemo } from '../../../shared';
import type { Dayjs } from 'dayjs';
import type { PetEventDetailsProps } from '../../types';
import PetFormikTimeSet from '../PetTimeSet/PetFormikTimeSet';
import { today } from '../../../../../utils/helpers/dateHelper';
import { useEventValidSchema } from '../../hooks';

export function PetEventForm({ eventDetails }: PetEventDetailsProps) {
  const location = useLocation<{ from: string; date: Dayjs | null }>();
  const type = eventDetails.type;
  const needToDisablePast = eventDetails.needToDisablePast;
  const selectedDate = location?.state?.date || today;
  const params = useParams<{ petId: string }>();
  const petId = params.petId;

  const {
    placeholderText,
    submitBtnText,
    timeSetFieldName,
    calendarWidgetName,
    locationStateFrom,
    requestKey,
    initialValues,
  } = useData({
    location: location?.state?.from,
    type,
    details: eventDetails,
    selectedDate,
  });

  const { handleSubmit } = useEventFormHandlers({
    type,
    petId,
    needToDisablePast,
    requestKey,
    locationStateFrom,
  });

  return (
    <Formik initialValues={initialValues} validationSchema={useEventValidSchema(eventDetails)} onSubmit={handleSubmit}>
      {form => {
        return (
          <Form noValidate className='add-event-form'>
            <CalendarWidgetMemo
              disablePast={needToDisablePast}
              fieldName={calendarWidgetName}
              isFormPart
              value={selectedDate}
            />

            {eventDetails.addPageSelectFields?.length
              ? eventDetails.addPageSelectFields.map((field, index) => (
                  <PetFormikDropdownField selectFieldDetails={field} key={index} form={form} />
                ))
              : null}

            {eventDetails.addPageInputFields?.length ? (
              <PetFormikInputArray eventDetails={eventDetails} form={form} />
            ) : null}

            {eventDetails.addPageTimeSetField && (
              <PetFormikTimeSet
                required={eventDetails.addPageTimeSetField.required}
                name={timeSetFieldName}
                time={eventDetails.addPageTimeSetField.time}
                placeholder={placeholderText}
              />
            )}

            {eventDetails.addPageTextareaFields?.length && (
              <PetFormikTextareaArray eventDetails={eventDetails} form={form} />
            )}
            <button type='submit' className='save-event-button' disabled={form.isSubmitting || !form.isValid}>
              {submitBtnText}
            </button>
          </Form>
        );
      }}
    </Formik>
  );
}
