import { useCallback, useState } from 'react';
import { RequestState } from '../enums';
import * as RequestService from './../../utils/services/request';
import { useIsMounted } from './useIsMounted';
// @ts-ignore
export default function useGetUser(): { userStatus: RequestState; userData: () => Promise<Core.RequestResponse> } {
  const isMounted = useIsMounted();
  const token = localStorage.getItem('access') || '';
  const phone = localStorage.getItem('phone_number') || '';

  const [userStatus, setUserStatus] = useState(RequestState.Idle);
  const userData = useCallback(
    // @ts-ignore
    async (): Promise<Core.RequestResponse> => {
      setUserStatus(RequestState.Pending);
      // @ts-ignore

      const response = await RequestService.get(`USERS`, {}, { phone }, { token });

      if (!isMounted) {
        setUserStatus(RequestState.Idle);
        return;
      }

      if (response.error) {
        if (response.error?.response?.status === 401) {
          // @ts-ignore
          return await RequestService.get(`USERS`, {}, { phone }, { token });
        }
        setUserStatus(RequestState.Error);
      } else {
        setUserStatus(RequestState.Success);
      }

      return response;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMounted],
  );

  return { userStatus, userData };
}
