import React, {useState} from 'react';
import {Redirect} from 'react-router-dom';
import './ErrorPageComponent.scss';

type Props = {
    errorImage: string,
    errorType: number,
    errorText: string,
};

// tslint:disable-next-line:typedef cyclomatic-complexity
export function ErrorPageComponent(props: Props) {
    const {errorImage, errorType, errorText} = props;
    const [redirect, setRedirect] = useState('');
    const goLogIn = (): void => {
        localStorage.clear();
        setRedirect( '/auth/login');
    };

    const goBack = (): void => {
        if (errorType) {
            window.history.go();
        } else {
            window.history.pushState({}, '', '/dashboard/main');
            window.history.go();
        }
    };
    const handleButtonClick =
        errorType === 401 ?
            ((): void => {
                goLogIn();
            })
            :
            ((): void => {
                goBack();
            });
    const reloadPage = (): void => {
        // @ts-ignore
        if (!!(window.history && window.history.replaceState)) {
            window.history.go();
        } else {
            document.location.reload();
        }
    };
    if (redirect) {
        return <Redirect to={redirect} push/>;
    }
    return (
        <>
            {errorImage && (<section className='errorPage'>
                <div className='errorPage_wrapper'>
                    <img src={errorImage} alt='error icon'/>
                    {errorType && (<p className='errorPage_title'>Error</p>)}
                    {/*<h1 className='errorPage_type'>{errorType}</h1>*/}
                    <p className='errorPage_text'>{errorText}</p>
                    <div>
                        <button className='errorPage_button' onClick={handleButtonClick}>
                            {errorType === 401 ? 'Log in' : (!errorType ? 'To dashboard' : 'Go back')}
                        </button>
                        {!errorType && (<button className='errorPage_button white'
                                                onClick={reloadPage}>Refresh</button>)}
                    </div>
                </div>
            </section>)}
        </>

    );
}
