import './HeaderWithSearch.scss';

import { Fragment, ReactElement } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { Box } from '@mui/material';
import { InputSearch } from '../InputSearch';
import { PetTab } from '../../enums';
import type { THeaderWithSearchProps } from '../../types';

export const HeaderWithSearch = ({ children, title, path, withoutSearch = false }: THeaderWithSearchProps): ReactElement => {
  const history = useHistory();
  const location = useLocation<{ from: string; petId?: string }>();
  const params = useParams<{ petId: string }>();

  const petId = params.petId || location?.state?.petId;
  const onClickHandler = () => {
    if (location.state?.from === PetTab.CALENDAR) {
      history.push(`/pet/${petId}/main/${PetTab.CALENDAR}`, { from: PetTab.CALENDAR });
      return;
    }
    if (location.state?.from === PetTab.MEDICAL) {
      history.push(`/pet/${petId}/main/${PetTab.MEDICAL}`, { from: PetTab.MEDICAL });
      return;
    }
    if (path) {
      history.push(path);
      return;
    }
    history.goBack();
  };

  return (
    <Fragment>
      <header className='headerWithSearch-header'>
        <button className='btn-back' onClick={onClickHandler} />
        <Box
          fontWeight={700}
          position='absolute'
          padding='1.2rem 0 1rem'
          margin='0 6rem'
          width='60vw'
          display='flex'
          justifyContent='center'>
          <Box textOverflow='ellipsis' whiteSpace='nowrap' overflow='hidden'>
            {title}
          </Box>
        </Box>
        <div className='headerWithSearch-children'>{children}</div>
      </header>
      {withoutSearch || (
        <div className='headerWithSearch-content'>
          <InputSearch />
        </div>
      )}
    </Fragment>
  );
};
