import { IconButton, useTheme } from '@mui/material';
import { useCallback, type ReactElement } from 'react';
import BackIcon from '../../../../../assets/images/btn-back.png';
import { useStyles } from './styles';

type Props = {
  goBack?: () => void;
  onClick?: () => void;
};

export function BackButton(props: Props): ReactElement {
  const { goBack, onClick } = props;
  const theme = useTheme();
  const styles = useStyles(theme);
  const handleClick = useCallback(() => {
    if (onClick) {
      onClick();
    } else if (goBack) {
      goBack();
    }
  }, [goBack, onClick]);

  return (
    <IconButton {...props} className={styles.backButton} onClick={handleClick} size='large' sx={{}}>
      <img src={BackIcon} alt='back button' />
    </IconButton>
  );
}
