export const DEL_ALLERGY = 'DEL_PET_ALLERGY';
export const DEL_VACCINATION = 'DEL_PET_VACCINATION';
export const DEL_TREATMENT = 'DEL_PET_TREATMENT';
export const DEL_VETERINARIAN = 'DEL_PET_VETERINARIAN';
export const DEL_SERVICES = 'DEL_PET_SERVICES';

export const PETS_EVENTS = 'PETS_EVENTS';
export const PETS_EVENTS_MONTH = 'PETS_EVENTS_MONTH';

export const PET_ALLERGY = 'PET_ALLERGY';
export const PET_VACCINATION = 'PET_VACCINATION';
export const PET_TREATMENT = 'PET_TREATMENT';
export const PET_VETERINARIAN = 'PET_VETERINARIAN';
export const PET_SERVICES = 'PET_SERVICES';

export const PETS = 'PETS';