import { useCallback, useReducer, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppModules } from '../../../../../../utils/const';
import type { CodeState, TNewPasswordForm } from '../../../types/types';
import { AuthRoutePath } from '../../../utils/const/route-path';
import { useResetPass } from '../../../utils/hooks/useResetPass';
import { initCodeState } from '../../VerifyPhoneCode/VerifyPhoneCode';

export function useHandlers() {
  const [codeDigit, dispatch] = useReducer(
    (s: CodeState, a: Partial<CodeState>): CodeState => ({ ...s, ...a }),
    initCodeState,
  );
  const phone_number = useRef(localStorage.getItem('phone_number'));
  const [codeError, setCodeError] = useState(false);
  const [requestState, onResetPass] = useResetPass();
  const history = useHistory();

  const handleSubmit = useCallback(
    async ({ password }: TNewPasswordForm): Promise<void> => {
      const resetPassword = await onResetPass({
        code: Object.values(codeDigit).join(''),
        phone_number: phone_number.current?.toString() || '',
        new_password: password,
      });
      if (!resetPassword.error) {
        history.push(`/${AppModules.auth}/${AuthRoutePath.LOGIN}`);
      } else {
        setCodeError(true);
      }
    },
    [codeDigit, history, onResetPass],
  );

  const handleResend = useCallback((): void => {
    history.push('/auth/forgot');
  }, [history]);

  return { handleResend, handleSubmit, requestState, codeError, phone_number, codeDigit, dispatch };
}
