import IconButton from '@mui/material/IconButton';
import React, { ReactElement, useEffect, useState } from 'react';
import arrowDown from '../../../../../../assets/images/arrow_down.png';

import { Autocomplete, Box, useTheme } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import clearImg from '../../../../../../assets/images/clear.png';
import searchImg from '../../../../../../assets/images/search.png';
import { ErrorInput } from '../ErrorInput/ErrorInput';
import { TCustomAutocomplete } from '../types';
import { useTranslation } from 'react-i18next';

export function CustomAutocomplete({
  placeholder,
  label,
  required,
  value,
  errors,
  onClose,
  errorType,
  options,
  onClick,
  ...props
}: TCustomAutocomplete): ReactElement {
  const [isOpen, setOpen] = useState(false);
  const [currValue, setCurrValue] = useState(value);
  const { t } = useTranslation();
  const theme = useTheme();
  useEffect((): void => {
    setCurrValue(value);
  }, [value]);

  return (
    <div className='custom-autocomplete'>
      <Autocomplete
        {...props}
        options={options}
        clearIcon={<img src={clearImg} alt={''} />}
        onOpen={(_): void => {
          setOpen(true);
          setCurrValue(null);
        }}
        onClose={(event): void => {
          setOpen(false);
          onClose();
          setCurrValue(value);
        }}
        open={isOpen}
        blurOnSelect={true}
        clearOnBlur={true}
        autoSelect={true}
        value={currValue}
        noOptionsText={<Box color={theme.palette.text.disabled}>{t('signupPage.noCountriesFound')}</Box>}
        renderInput={(params): ReactElement => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
            required={required ? true : false}
            variant={'outlined'}
            InputLabelProps={{
              variant: 'filled',
            }}
            focused={isOpen}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {params.InputProps.endAdornment}
                  {!isOpen && (
                    <InputAdornment key='inputExpandButton' position='end' onClick={(): void => setOpen(true)}>
                      <IconButton
                        onClick={(): void => {
                          setCurrValue(null);
                          setOpen(true);
                        }}
                        edge='end'>
                        {' '}
                        <img src={arrowDown} alt={''} />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
              startAdornment: (
                <InputAdornment position='start'>
                  {isOpen ? (
                    <IconButton onClick={onClick} edge='start'>
                      <img
                        style={{
                          textAlign: 'center',
                          margin: '0.925rem 0rem 0rem 0rem',
                          borderRadius: '50%',
                          border: '1px solid $primary',
                          objectFit: 'cover',
                          width: '22.5px',
                          left: '19px',
                          top: '13px',
                          height: '22.5px',
                        }}
                        src={searchImg}
                        alt='open'
                      />
                    </IconButton>
                  ) : value && value.src ? (
                    <IconButton>
                      <img
                        style={{
                          textAlign: 'center',
                          margin: '0.925rem 0rem 0rem 0rem',
                          borderRadius: '50%',
                          border: '1px solid $primary',
                          objectFit: 'cover',
                          width: '22.5px',
                          left: '19px',
                          top: '13px',
                          height: '22.5px',
                        }}
                        loading='lazy'
                        src={value.src}
                        alt=''
                      />
                    </IconButton>
                  ) : null}
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      <ErrorInput error={errors} type={errorType}></ErrorInput>
    </div>
  );
}
