import type {
  AnyEventTypeResponse,
  TCalendarData,
  TCalendarEvent,
  TDailyEventsRequestData,
  TDayStatus,
  TMonthRequestData,
} from '../../../types';
import { DAYS_FORMAT, YEAR_MONTH_FORMAT } from '../../../../../../utils/const';
import { PETS_EVENTS, PETS_EVENTS_MONTH } from '../../../requestKeys';
import { PetEventsResponseTypes, PetEventsTypes } from '../../../utils/enums/eventTypes';
import { getTimeOffset, today, transformDateToFormat } from '../../../../../../utils/helpers/dateHelper';
import { useDateFunction, useFetchEventListDataOnMount } from '../../../hooks';
import { useEffect, useMemo, useState } from 'react';

import type { Dayjs } from 'dayjs';
import { RequestState } from '../../../../../../utils/enums';
import { UrlsType } from '../../../../../../utils/services';
import allergy from '../../../../../../assets/images/pet/allergy.svg';
import doctor from '../../../../../../assets/images/pet/doc.svg';
import parasite from '../../../../../../assets/images/pet/bug.svg';
import service from '../../../../../../assets/images/pet/service.svg';
import { useFetchMonthDataOnMount } from '../../../hooks/useFetchMonthDataOnMount';
import { useTranslation } from 'react-i18next';
import vaccine from '../../../../../../assets/images/pet/vaccine.svg';

const monthRequestKey: UrlsType = PETS_EVENTS_MONTH;
const dailyEventsRequestKey: UrlsType = PETS_EVENTS;
const currentYearMonth = transformDateToFormat(today, YEAR_MONTH_FORMAT);

function transformMonthData(allDates: TDayStatus[]): number[] | [] {
  return allDates.reduce((previousValue: number[], currentValue) => {
    if (Object.values(currentValue)[0])
      previousValue.push(Number(transformDateToFormat(Object.keys(currentValue)[0], DAYS_FORMAT)));

    return previousValue;
  }, []);
}

function transformEvents(
  events: AnyEventTypeResponse | [],
  getDateFromUTC: (utcDate: string) => [date: string, time: string],
): TCalendarEvent[] | 'empty' {
  if (!events || !events.length) return 'empty';
  const allEvents: TCalendarEvent[] = events.map(event => {
    const {
      event_date,
      id,
      pet_id,
      allergy_type,
      drug_name,
      event_type,
      service_name,
      treatment_reason,
      vaccination_name,
      procedure_name,
    } = event;
    const [date, time] = getDateFromUTC(event_date);

    const eventTypeObj = {
      [PetEventsResponseTypes.ALLERGY]: { title: allergy_type, icon: allergy, key: PetEventsTypes.ALLERGY },
      [PetEventsResponseTypes.SERVICE]: { title: service_name, icon: service, key: PetEventsTypes.SERVICE },
      [PetEventsResponseTypes.VACCINATION]: {
        title: vaccination_name,
        icon: vaccine,
        key: PetEventsTypes.VACCINATION,
      },
      [PetEventsResponseTypes.TREATMENT]: {
        title: treatment_reason,
        icon: parasite,
        key: PetEventsTypes.TREATMENT,
      },
      [PetEventsResponseTypes.VETERINARIAN]: {
        title: procedure_name,
        icon: doctor,
        key: PetEventsTypes.VETERINARIAN,
      },
    };

    return {
      eventType: event_type,
      petId: pet_id,
      id,
      time,
      date,
      drugName: drug_name,
      service_name: service_name,
      title: eventTypeObj[event_type].title,
      icon: eventTypeObj[event_type].icon,
      key: eventTypeObj[event_type].key,
    };
  });
  return allEvents;
}
  const timeshift = getTimeOffset();

export function useData({ petId, needToCheckData, needToCheckMonthData, setNeedToCheckData, setNeedToCheckMonthData }: TCalendarData) {
  const { t } = useTranslation();
  const [yearMonth, setYearMonth] = useState<string>(currentYearMonth);
  const [dateValue, setDateValue] = useState<Dayjs | null>(today);
  const { getDateFromUTCHook } = useDateFunction({});
  const { transformRangeDateToUTCHook } = useDateFunction({ dateFrom: dateValue });

  const [startDate, finishDate] = transformRangeDateToUTCHook();
  const dailyEventsData: TDailyEventsRequestData = useMemo(() => {
    return {
      pet_id: petId,
      // date_from: dayjs(dateValue).format('YYYY-MM-DD'),
      // date_to: dayjs(dateValue).format('YYYY-MM-DD'),
      date_from: startDate,
      date_to: finishDate,
      limit: 5,
      offset: 0,
    };
  }, [finishDate, petId, startDate, needToCheckData]);

  const monthData: TMonthRequestData = useMemo(() => {
    return {
      pet_id: petId,
      date: yearMonth,
      timeshift,
    };
  }, [petId, timeshift, yearMonth, needToCheckMonthData]);

  const [requestStatusEventsData, fetchedEventsData] = useFetchEventListDataOnMount(
    dailyEventsRequestKey,
    dailyEventsData,
    setNeedToCheckData
  );

  const [requestStatusMonthData, fetchedMonthData] = useFetchMonthDataOnMount(monthRequestKey, monthData, setNeedToCheckMonthData);
  const events = fetchedEventsData.results.result;
  const transformedMonthData = useMemo(() => transformMonthData(fetchedMonthData), [fetchedMonthData]);
  const isCalendarLoad =
    requestStatusMonthData === RequestState.Pending || requestStatusMonthData === RequestState.Idle;

  const transformedEvents = transformEvents(events, getDateFromUTCHook);
  const isLastEventThisDay = transformedEvents.length === 1;

  const onEventsBtnText = t('PetProfilePage.calendar.addButton');
  const onMenuBtnText = t('PetProfilePage.calendar.addButton2');
  const noEventsText = t('PetProfilePage.calendar.noEvents');
  const loadingEventsText = t('PetProfilePage.calendar.loadingEvents');

  return {
    onEventsBtnText,
    onMenuBtnText,
    noEventsText,
    dateValue,
    setDateValue,
    setYearMonth,
    loadingEventsText,
    isLastEventThisDay,
    transformedEvents,
    transformedMonthData,
    isCalendarLoad,
  };
}
