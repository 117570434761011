import type { TVerifyPhoneForm } from '../../../types/types';

const initialValues = {
  phone: '',
  code: '',
  recaptcha_token: '',
  terms: false,
};

export function useData(): { initialValues: TVerifyPhoneForm } {
  return { initialValues };
}
