import { CreatePetEvent, CreatePetPage, EditPetPage, PetEventCard, PetOneTypeEvents, PetProfilePage } from './views';
import { Fragment, ReactElement } from 'react';
import { PET_ALLERGY, PET_SERVICES, PET_TREATMENT, PET_VACCINATION, PET_VETERINARIAN } from './requestKeys';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PetProfileRoutePath } from './utils/enums/routes-path';
import type { TPetProfileRoutesProps } from './types';
import { useData } from './hooks/useData';

const PetProfileRoutes = ({ url }: TPetProfileRoutesProps): ReactElement => {
  const { allergiesData, additionalServicesData, vaccinationData, parasiteTreatmentData, vetData } = useData();
  return (
    <Fragment>
      <Switch>
        <Route path={`${url}/:id/${PetProfileRoutePath.MAIN}`}>
          <PetProfilePage />
        </Route>
        <Route path={`${url}/${PetProfileRoutePath.CREATE}`}>
          <CreatePetPage />
        </Route>
        <Route path={`${url}/${PetProfileRoutePath.EDIT}/:id`}>
          <EditPetPage />
        </Route>

        <Route path={`${url}/:petId/${PetProfileRoutePath.ALLERGIES}`}>
          <PetOneTypeEvents eventDetails={allergiesData} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.ADD_ALLERGY}`}>
          <CreatePetEvent eventDetails={allergiesData} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.VIEW_ALLERGY}/:eventId`}>
          <PetEventCard eventDetails={allergiesData} url={PET_ALLERGY} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.EDIT_ALLERGY}/:eventId`}>
          <EditPetPage />
        </Route>

        <Route path={`${url}/:petId/${PetProfileRoutePath.VET}`}>
          <PetOneTypeEvents eventDetails={vetData} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.ADD_VET}`}>
          <CreatePetEvent eventDetails={vetData} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.VIEW_VET}/:eventId`}>
          <PetEventCard eventDetails={vetData} url={PET_VETERINARIAN} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.EDIT_VET}/:eventId`}>
          <EditPetPage />
        </Route>

        <Route path={`${url}/:petId/${PetProfileRoutePath.SERVICES}`}>
          <PetOneTypeEvents eventDetails={additionalServicesData} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.ADD_SERVICES}`}>
          <CreatePetEvent eventDetails={additionalServicesData} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.VIEW_SERVICES}/:eventId`}>
          <PetEventCard eventDetails={additionalServicesData} url={PET_SERVICES} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.EDIT_SERVICES}/:eventId`}>
          <EditPetPage />
        </Route>

        <Route path={`${url}/:petId/${PetProfileRoutePath.VACCINATION}`}>
          <PetOneTypeEvents eventDetails={vaccinationData} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.ADD_VACCINATION}`}>
          <CreatePetEvent eventDetails={vaccinationData} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.VIEW_VACCINATION}/:eventId`}>
          <PetEventCard eventDetails={vaccinationData} url={PET_VACCINATION} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.EDIT_VACCINATION}/:eventId`}>
          <EditPetPage />
        </Route>

        <Route path={`${url}/:petId/${PetProfileRoutePath.TREATMENT}`}>
          <PetOneTypeEvents eventDetails={parasiteTreatmentData} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.ADD_TREATMENT}`}>
          <CreatePetEvent eventDetails={parasiteTreatmentData} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.VIEW_TREATMENT}/:eventId`}>
          <PetEventCard eventDetails={parasiteTreatmentData} url={PET_TREATMENT} />
        </Route>
        <Route path={`${url}/:petId/${PetProfileRoutePath.EDIT_TREATMENT}/:eventId`}>
          <EditPetPage />
        </Route>

        <Redirect from={'*'} to={'/'} />
      </Switch>
    </Fragment>
  );
};

export default PetProfileRoutes;
