import { type KeyboardEventHandler, useCallback } from 'react';

import type { TCustomHandlersArg } from '../../types';
import { useFormikContext } from 'formik';

export function useHandlers({
  setFocus,
  setOpen,
  onClose,
  onChange,
  setCurrValue,
  setPrevValue,
  prevValue,
  currValue,
  isFormPart,
  field,
  onChangeForm
}: TCustomHandlersArg) {

  const { setFieldValue } = useFormikContext()

  const onCloseDefault = useCallback(
    (event, reason): void => {
      setOpen(false);
      if (onClose) onClose(event, reason);
    },
    [setOpen, onClose],
  );

  const onOpen = useCallback((): void => {
    setFocus(true);
    setPrevValue(currValue);
    setOpen(true);
    setCurrValue(null);
  }, [currValue, setCurrValue, setFocus, setOpen, setPrevValue]);

  const onChangeDefault = useCallback(
    (event, currValue, reason?): void => {
      if (currValue !== null) {
        setCurrValue(currValue);
      }
      onChange(event, currValue, 'selectOption');
      
      if(onChangeForm) onChangeForm();

      if (isFormPart && field?.name) {
        setFieldValue(field?.name, currValue.id);
      }
      onCloseDefault(event, reason);
      setFocus(true);
    },
    [field?.name, isFormPart, onChange, onChangeForm, onCloseDefault, setCurrValue, setFieldValue, setFocus],
  );
  const onClickAway = useCallback((): void => {
    setFocus(false);
  }, [setFocus]);

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (event.key === 'Enter') {
        event.defaultPrevented = true;
        onChangeDefault(event, prevValue);
      }
    },
    [onChangeDefault, prevValue],
  );

  return { onCloseDefault, onOpen, onChangeDefault, handleKeyDown, onClickAway };
}
