import { Box } from '@mui/material';
import Compressor from 'compressorjs';
import { Field, FormikProps, FormikValues } from 'formik';
import { ComponentProps, type ChangeEvent, type ReactElement, useCallback, useRef, useState } from 'react';
import avatar_editor from '../../../../../assets/images/photo_update.png';
import { blobToFile, checkImage } from '../../../../../utils/helpers';
import { useStyles } from './styles';
type TProps = {
  defaultSrc: string;
  alt: string;
  disabled?: boolean;
  form?: FormikProps<FormikValues>;
  field?: ComponentProps<typeof Field>;
};
type TAvatar = {
  name: string;
  result: Blob | null;
  result_name: string;
};

export function FormAvatar({ defaultSrc, alt, disabled, ...props }: TProps): ReactElement {
  const { form, field } = props;
  const styles = useStyles();
  const hiddenFileInput = useRef(null);
  const [newAvatar, setNewAvatar] = useState<TAvatar>({ name: '', result: null, result_name: '' });

  const handleClick = useCallback((): void => {
    if (hiddenFileInput?.current) {
      (hiddenFileInput.current as HTMLInputElement).click();
    }
  }, []);

  const handleChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
      const file = event.target.files?.[0] as File;

      if (!file) {
        return;
      }

      await checkImage(file);

      try {
        const _ = new Compressor(file, {
          quality: 0.6,
          convertTypes: ['image/png', 'image/jpeg', 'image/jpg'],
          convertSize: 500000,

          success(blob: Blob): void {
            form?.setFieldValue(field.name, blobToFile(blob, file.name));
            setNewAvatar({ name: file.name, result: blob, result_name: file.name });
          },
          error(err): void {
            console.log(err.message);
          },
        });
      } catch (error) {
        console.error('Error uploading image file:', error);
      }
    },
    [field?.name, form],
  );
  return (
    <Box width='170px' height='170px' position='relative'>
      <img
        className={styles.formAvatar}
        src={newAvatar.result ? URL.createObjectURL(newAvatar.result) : field?.value ? field.value : defaultSrc}
        alt={alt}
      />
      {!disabled && (
        <>
          <img className={styles.formAvatarButton} onClick={handleClick} src={avatar_editor} alt='Pet avatar' />
          <input
            className={styles.formAvatarHiddenFileInput}
            type='file'
            name='avatar'
            accept='.png'
            ref={hiddenFileInput}
            onChange={handleChange}
          />
        </>
      )}
    </Box>
  );
}
