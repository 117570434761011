import { PetTab } from '../../../enums';
import type { TPetTabsData, tabsInfo } from '../types';
import { useTranslation } from 'react-i18next';
import warning from '../../../../../../assets/images/pet/warning.png';



export function useData({hasAllergy}: TPetTabsData) {
  const { t } = useTranslation();
  const tabLabels = {
    [PetTab.INFO]: t(`PetProfilePage.tabs.${PetTab.INFO}`),
    [PetTab.GALLERY]: t(`PetProfilePage.tabs.${PetTab.GALLERY}`),
    [PetTab.MEDICAL]: t(`PetProfilePage.tabs.${PetTab.MEDICAL}`),
    [PetTab.CALENDAR]: t(`PetProfilePage.tabs.${PetTab.CALENDAR}`),
  };

  const tabsArr: tabsInfo[] = [
    {
      id: PetTab.INFO,
      icon: undefined,
      iconPosition: undefined,
    },
    {
      id: PetTab.GALLERY,
      icon: undefined,
      iconPosition: undefined,
    },
    {
      id: PetTab.MEDICAL,
      icon: hasAllergy ? warning : undefined,
      iconPosition: 'start',
    },
    {
      id: PetTab.CALENDAR,
      icon: undefined,
      iconPosition: undefined,
    },
  ];
  return { tabsArr, tabLabels };
}
