import { Button, Stack, Typography, useTheme } from '@mui/material';
import { Field, Form, useFormikContext } from 'formik';
import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { RequestState } from '../../../../../../../utils/enums';
import { Phone, SecureInput } from '../../../../../shared';
import type { TLoginForm } from '../../../../types/types';
import { useHandlers } from './useHandlers';

export const LoginForm = ({ loginStatus, ...props }: { loginStatus: RequestState }): ReactElement => {
  const { t } = useTranslation();
  const { errors, touched, setFieldValue } = useFormikContext<TLoginForm>();
  const theme = useTheme();
  const { handleClearPhone, handleClearPassword } = useHandlers();
  return (
    <Form noValidate {...props}>
      <Stack marginBottom={0.8} gap={3.2}>
        <Phone
          setField={setFieldValue}
          required
          clear={handleClearPhone}
          errorsPhone={touched.phone && errors.phone}
          errorsCode={touched.code && errors.code}
        />
        <Field
          name='password'
          component={SecureInput}
          required
          errors={touched.password && errors.password}
          clear={handleClearPassword}
          label={t('loginPage.placeholders.password')}
        />
        {loginStatus === RequestState.Error && <p className='alert'>{t('loginPage.errors.incorrectData')}</p>}
        <Button type='submit' variant='primaryContained'>
          <Typography color='#fff' fontSize={16} fontWeight={700} fontFamily='Nunito'>
            {t('loginPage.logIn')}
          </Typography>
        </Button>
      </Stack>

      <Link to='/auth/forgot'>
        <Typography color={theme.palette.text.primary} fontSize={16} fontFamily='Nunito' textAlign='center'>
          {t('loginPage.forgot')}
        </Typography>
      </Link>
    </Form>
  );
};
