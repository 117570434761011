export const UK_LOCALE_DATE_FORMAT = 'dd-MM-yyyy';
export const EN_LOCALE_DATE_FORMAT = 'yyyy-MM-dd';

export const UK_LOCALE_DATE_FORMAT_DAYJS = 'DD-MM-YYYY';
export const UK_LOCALE_DATE_POINTS_FORMAT_DAYJS = 'DD.MM.YYYY';
export const EN_LOCALE_DATE_FORMAT_DAYJS = 'YYYY-MM-DD';
export const EN_LOCALE_DATE_POINTS_FORMAT_DAYJS = 'YYYY.MM.DD';
export const YEAR_MONTH_FORMAT = 'YYYY-MM'
export const DAYS_FORMAT = 'DD'

export const UK_LOCALE_TIME_FORMAT = 'HH:mm';
export const EN_LOCALE_TIME_FORMAT = 'HH:mm A';
export const DATA_BASE_FORMAT = 'YYYY-MM-DDT00:00:00Z';
export const UTC_DATA_BASE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
export const FINISH_DATE_DATA_BASE_FORMAT = 'YYYY-MM-DDT23:59:59Z';

export const UK_LOCALE_DATE_WITH_TIME_FORMAT = 'DD.MM.YYYY  HH:mm';
export const EN_LOCALE_DATE_WITH_TIME_FORMAT = 'YYYY.MM.DD  hh:mm A';

export const MIN_OUTDOOR_TIME = 4 * 60;
export const MAX_OUTDOOR_TIME = 23 * 60;

export const AM_TIME = 'am';
export const PM_TIME = 'pm';

export const TWELVE_HOUR_FORMAT = '12-hour';
export const HOUR_NUMERIC_FORMAT = 'numeric';
