import { Formik } from 'formik';
import type { ReactElement } from 'react';
import React from 'react';
import { RequestState } from '../../../../../utils/enums';
import { PageLoader } from '../../../shared';
import type { TEditProfileForm } from '../../utils/types/types';
import { useProfileUpdateValidationSchema } from '../../utils/validationSchemas/ProfileUpdateValidationSchema';
import { EditPageForm } from './EditPageForm/EditPageForm';
import { useData } from './useData';
import { useHandlers } from './useHandlers';

export const EditPage = (): ReactElement => {
  const profileUpdateValidationSchema = useProfileUpdateValidationSchema();

  const { initialValues } = useData();
  const { updateStatus, handleSubmit } = useHandlers();

  return (
    <>
      <Formik<TEditProfileForm>
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={profileUpdateValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}>
        <EditPageForm requestStatus={updateStatus}></EditPageForm>
      </Formik>
      {updateStatus === RequestState.Pending && <PageLoader />}
    </>
  );
};
