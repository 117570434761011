import default_avatar from '../../../../../assets/images/default_avatar.png';
import type { TEditProfileForm } from '../../utils/types/types';

const initialValues = {
  avatar: default_avatar,
  birthday: '',
  city: '',
  country: 224,
  email: '',
  first_name: '',
  gender: '',
  last_name: '',
  phone_number: localStorage.getItem('phone_number') || '',
};

export function useData(): { initialValues: TEditProfileForm } {
  return { initialValues };
}
