import type { ReactElement } from 'react';
import React, { useState } from 'react';
import notification from '../../../../../assets/images/dashboard/notification.png';
import avatar from '../../../../../assets/images/default_avatar.png';
import horizontalLogo from '../../../../../assets/images/logo_horizontal.png';
import { Sidebar } from '../Sidebar/Sidebar';
import './Header.scss';

// tslint:disable-next-line:no-any
export function Header(props: { user: any }): ReactElement {
  const { user } = props;
  const [isOpen, setisOpen] = useState(false);
  const toggling = (): void => setisOpen(!isOpen);

  return (
    <div className='header'>
      {isOpen && <Sidebar status={isOpen} user={user} setisOpen={setisOpen} />}
      <img src={horizontalLogo} alt='horizontal logo' />
      <div className='rightBlock'>
        <img className='notification-icon' src={notification} alt='notification' />
        <img src={user?.avatar ? user.avatar : avatar} onClick={toggling} className={'dropbtn'} alt='avatar' />
      </div>
    </div>
  );
}
