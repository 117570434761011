import IconButton from '@mui/material/IconButton';
import React, { ReactElement } from 'react';
import searchImg from '../../../../../../assets/images/search.png';
import { CustomInput } from '../CustomInput/CustomInput';

export function SearchInput(props: any): ReactElement {
  return (
    <CustomInput
      {...props}
      icons-start={[
        <IconButton onClick={props.onClick} edge='start'>
          <img src={searchImg} alt='open' />
        </IconButton>,
      ]}
    />
  );
}
