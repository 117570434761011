import { Box, Button, Link, Typography, useTheme } from '@mui/material';
import { Field, Form, useFormikContext } from 'formik';
import type { ReactElement } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestState } from '../../../../../../../utils/enums';
import { CustomCheckbox, Phone } from '../../../../../shared';
import type { TVerifyPhoneForm } from '../../../../types/types';
import { useRecaptchaVerify } from '../../../../utils/hooks/useRecaptchaVerify';
import { useHandlers } from './useHandlers';

export function VerifyPhoneForm({ requestState }: { requestState: RequestState }): ReactElement {
  const { t } = useTranslation();
  const { errors, touched, setFieldValue, values } = useFormikContext<TVerifyPhoneForm>();
  const theme = useTheme();
  const { handleClearPhone } = useHandlers();

  useRecaptchaVerify(values.terms, setFieldValue);

  return (
    <Form>
      <Typography color={theme.palette.text.primary} fontFamily='Nunito' fontSize={16} fontWeight={400}>
        {t('verifyPhonePage.description')}
      </Typography>
      <Phone
        setField={setFieldValue}
        required
        clear={handleClearPhone}
        values={values}
        errorsPhone={touched.phone && errors.phone}
        errorsCode={touched.code && errors.code}
      />
      <Box marginTop={1}>
        <Field
          as={CustomCheckbox}
          name='terms'
          labelPlacement='start'
          label={
            <Typography>
              {t('signupPage.terms.text')}{' '}
              <Link color={theme.palette.text.primary} href='/auth/policy'>
                {t('signupPage.terms.link')}
              </Link>
            </Typography>
          }
        />

        {values.terms && <Box width='100%' id='recaptcha-container' marginTop={1} />}
      </Box>
      {requestState === RequestState.Error && (
        <Box marginTop={1}>
          <Typography>{t('verifyPhonePage.errors.incorrectData')}</Typography>
        </Box>
      )}
      <Box marginTop={1.5}>
        <Button
          disabled={!!localStorage.getItem('google_auth') || !values.terms}
          type='submit'
          variant='primaryContained'>
          {t('verifyPhonePage.next')}
        </Button>
      </Box>
    </Form>
  );
}
