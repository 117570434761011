import React, {ReactElement, useState} from 'react';
import {useErrorHandler} from 'react-error-boundary';
import {useHistory} from 'react-router-dom';
import trash_icon from '../../../../../../assets/images/chat/trash.png';
import standard from '../../../../../../assets/images/default_avatar.png';
import { getTime } from '../../../../../../utils/hooks/getTime';
import {getLastMessage} from '../../../utils/hooks/getMessages/getMessages';
import './ChatItem.scss';

// tslint:disable-next-line:no-any cyclomatic-complexity
export const ChatItem = (props: any): ReactElement => {
    const history = useHistory();
    const handleError = useErrorHandler();
    const {user} = props;
    const [unreadCount, setUnreadCount] = useState(0);
    const zulip_id = Number(localStorage.getItem('zulip_id'));

    const [message, setMessage] = useState({
        avatar_url: '',
        content: '',
        reactions: [],
        sender_id: 0,
        display_recipient: [{
            full_name: '',
            id: 0
        }],
        flags: [],
        sender_full_name: '',
        timestamp: 0,
    });

    // tslint:disable-next-line:no-any
    const checkUnreadMessages = (list: any): void => {
        if (list) {
            // @ts-ignore
            // tslint:disable-next-line:typedef no-any
            list.map((message: any) => {
                if (!message.flags[0] || message.flags[0] !== 'read') {
                    // tslint:disable-next-line:typedef
                    return setUnreadCount((unreadCount) => unreadCount + 1);
                } else {
                    return unreadCount;
                }
            });
        }
    };
    // tslint:disable-next-line:typedef
    useState(async () => {
        await getLastMessage(user.zulip_id).then((result): void => {
            setMessage(result[result.length - 1]);
            checkUnreadMessages(result);
            // tslint:disable-next-line:typedef
        }, error => handleError(error));
    });
    const handleClick = (): void => {
        if (user) {
            history.push({
                pathname: '/chat/personal',
                state: {user}
            });
        }
    };
    const makeScrollable = (id: string): void => {
        // @ts-ignore
        document.getElementById(id).style.display = 'flex';

    };
    const removeScrollable = (id: string): void => {
        setTimeout((): void => {
            if (document.getElementById(id)) {
                // @ts-ignore
                document.getElementById(id).style.display = 'none';
            }

        }, 800);
    };
    // alert(message.content);
    return (
        <>
            {(message && user) && (
                // tslint:disable-next-line:typedef
                <div className={'chat-item-container'} data-id={user.id} onTouchStart={() => makeScrollable(user.id)}
                     onTouchEnd={(): void => removeScrollable(user.id)}>
                    <div onClick={handleClick}>
                        <div className={'chat-item-avatar'}>
                            <img
                                src={user.avatar ? user.avatar : standard}
                                alt=''/>
                        </div>
                        <div className={'chat-item-body'}>
                            <div className={'chat-item-body--top'}>
                                <div
                                    // @ts-ignore
                                    className={'chat-item-body--top-title'}>{message.display_recipient[0]?.id === zulip_id ?
                                    // @ts-ignore
                                    message.display_recipient[1]?.full_name :
                                    // @ts-ignore
                                    message.display_recipient[0]?.full_name}</div>
                                {/*@ts-ignore*/}
                                <div className={'chat-item-body--top-time'}>{getTime(message.timestamp)}</div>
                            </div>
                            <div className={'chat-item-body--bottom'}>
                                <div
                                    // @ts-ignore
                                    className={`chat-item-body--bottom-content ${(!message.flags[0] || message.flags[0] !== 'read') && 'unread'}`}>
                                    {/*@ts-ignore*/}
                                    {message.content.replace('<p>', '').replace('</p>', '')}
                                </div>
                                {/*@ts-ignore*/}
                                {(!message.flags[0] || message.flags[0] !== 'read') && (
                                    <div className={'chat-item-body--bottom-status'}><span>+{unreadCount}</span></div>)}
                            </div>
                        </div>
                    </div>
                    <div id={user.id} className={'chat-item-controller'} onClick={(): void => alert('delete')}>
                        <img src={trash_icon} alt='delete'/>
                    </div>
                </div>
            )}
        </>
    );
};
