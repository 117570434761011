import { Stack } from '@mui/material';
import { Field, Form, useFormikContext } from 'formik';
import {
  CustomDateInput,
  CustomInput,
  CustomSelect,
  CustomSelectNew,
  FormAvatar,
  FormOutdoorSchedule,
  RadioButtonGroup,
} from '../../../../shared';
import { useData } from '../../../hooks/useData';
import type { TPetEditForm, TPetEditFormProps } from '../types';
import { useTranslation } from 'react-i18next';
import CustomKindSelectWithPagination from '../../../components/PetForm/CustomKindSelectWithPagination/CustomKindSelectWithPagination';
import { FormHeader } from '../../../../shared/components/FormHeader';
import { usePetForm } from '../hooks';
import { DeletePetButton } from './DeletePetButton.tsx/DeletePetButton';
import { useEffect, useState } from 'react';
import { areObjectsEqual } from '../../../../../../utils/helpers';
import { SelectTypes } from '../../../../../../utils/enums';

export function EditPetForm({ title, initialAvatar }: TPetEditFormProps) {
  const { errors, touched, values, initialValues, setFieldValue } = useFormikContext<TPetEditForm>();
  const [popUpShow, setPopUpShow] = useState(false);

  useEffect(() => {
    setPopUpShow(!areObjectsEqual(values, initialValues));
  }, [initialValues, values]);

  const { animalIdOptions, handleClearField, handleDateChange, onIdTypeChange } = usePetForm();
  const petId = values.id;
  const {
    selectLivingPlaceOptions,
    selectKindOptions,
    selectTemperamentOptions,
    genderOptions,
    foodOptions,
    sterilizationOptions,
  } = useData();
  const { t } = useTranslation();

  return (
    <Form>
      <FormHeader prevUrl={`/pet/${petId}/main`} title={title} popUpShow={popUpShow} />
      <Stack padding='0rem 1rem' height='90vh' overflow='auto'>
        <Stack alignItems='center' marginBottom='1rem' marginTop='1rem'>
          <Field id='avatar' name='avatar' defaultSrc={initialAvatar} component={FormAvatar} />
        </Stack>
        <Stack marginBottom='1rem'>
          <Field
            id='kind'
            name='kind'
            component={CustomSelectNew}
            selectType={SelectTypes.PopUpSelect}
            required
            options={selectKindOptions}
            errors={touched.kind && errors.kind}
            label={t('petForm.placeholders.kind')}
            errorType={'transparent'}
            value={selectKindOptions.find((option) => option.id === values.kind )}
            onChangeForm={() => setFieldValue('breed', '')}
          />
        </Stack>
        <Stack marginBottom='1rem'>
          <Field
            id='name'
            name='name'
            component={CustomInput}
            clear={handleClearField}
            required
            errors={touched.name && errors.name}
            label={t('petForm.placeholders.name')}
            errorType={'transparent'}
          />
        </Stack>
        <Stack marginBottom='1rem'>
          <Field
            id='birth'
            name='birth'
            component={CustomDateInput}
            required
            onChange={handleDateChange}
            errors={touched.birth && errors.birth}
            label={t('petForm.placeholders.birthday')}
            errorType={'transparent'}
            value={values.birth}
          />
        </Stack>
        <Stack marginBottom='1rem'>
          <Field
            id='breed'
            name='breed'
            component={CustomKindSelectWithPagination}
            label={t('petForm.placeholders.breed')}
            kindId={values.kind}
            breedId={values.breed}
          />
        </Stack>
        <Stack marginBottom='1rem'>
          <Field
            id='gender'
            name='gender'
            component={RadioButtonGroup}
            options={genderOptions}
            label={t('petForm.placeholders.gender')}
          />
        </Stack>
        <Stack marginBottom='1rem'>
          <Field
            id='color'
            name='color'
            component={CustomInput}
            clear={handleClearField}
            label={t('petForm.placeholders.color')}
            errors={touched.color && errors.color}
          />
        </Stack>
        <Stack marginBottom='1rem'>
          <Field
            id='special_signs'
            name='special_signs'
            component={CustomInput}
            clear={handleClearField}
            errors={touched.special_signs && errors.special_signs}
            label={t('petForm.placeholders.special_signs')}
          />
        </Stack>
        <Stack marginBottom='1rem'>
          <Field
            id='food'
            name='food'
            component={RadioButtonGroup}
            label={t('petForm.placeholders.food')}
            options={foodOptions}
          />
        </Stack>
        <Stack marginBottom='1rem'>
          <Field
            id='sterilization'
            name='sterilization'
            component={RadioButtonGroup}
            label={t('petForm.placeholders.sterilization')}
            options={sterilizationOptions}
          />
        </Stack>
        <Stack marginBottom='1rem'>
          <Field
            id='living_place'
            name='living_place'
            component={CustomSelect}
            choices={selectLivingPlaceOptions}
            label={t('petForm.placeholders.living_place')}
          />
        </Stack>
        <Stack marginBottom='1rem'>
          <Field
            id='outdoor_schedule'
            name='outdoor_schedule'
            component={FormOutdoorSchedule}
            label={t('petForm.placeholders.outdoor_schedule')}
            values={values.outdoor_schedule}
          />
        </Stack>
        <Stack marginBottom='1rem'>
          <Field
            id='id_type'
            name='id_type'
            label={t('petForm.placeholders.animal_id')}
            options={animalIdOptions}
            component={RadioButtonGroup}
            onChange={onIdTypeChange}
            isInColumn
          />
        </Stack>
        <Stack marginBottom='1rem'>
          <Field
            id='temperament'
            name='temperament'
            component={CustomSelect}
            choices={selectTemperamentOptions}
            label={t('petForm.placeholders.temperament')}
          />
        </Stack>
        <Stack marginBottom='5rem'>
          <DeletePetButton petId={petId} />
        </Stack>
      </Stack>
    </Form>
  );
}
