import type { Location } from 'history';
import { PetTab } from '../../../enums';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

type TExtendedLocation = Location<{ from: string }>;

function getDefaultPetTab(location: TExtendedLocation): string {
  switch (true) {
    case location.pathname.includes(PetTab.MEDICAL):
      return PetTab.MEDICAL;
    case location.pathname.includes(PetTab.CALENDAR):
      return PetTab.CALENDAR;
    default:
      return PetTab.INFO;
  }
}
const getInitialPosition = (lastPage: string): PetTab =>
  lastPage === PetTab.CALENDAR || lastPage === PetTab.MEDICAL ? lastPage : PetTab.INFO;

export function useCalculateTabState(): PetTab {
  const location = useLocation<{ from: string }>();

  const tabName = useMemo(() => getDefaultPetTab(location), [location]);
  const tabState: PetTab = useMemo(() => getInitialPosition(tabName), [tabName]);

  return tabState;
}
