import { useCallback, useEffect, useState } from 'react';

import { get } from '../../../../utils/services/request';
import { tokenState } from '../../../../utils/atoms';
import { useErrorHandler } from 'react-error-boundary';
import { useRecoilState } from 'recoil';

type TBreedItem = {
  id: number;
  breed_name: string;
  breed_name_en: string;
  breed_name_ua: string;
};

const initialOffset = 0;

export function useFetchBreed(
  kindId: number,
  limit?: number,
  breedId?: number,
): {breeds: TBreedItem[], loadMoreBreeds: () => void, fetchBreeds: (kindId: number, offset: number, limit: number, breedId?: number) => void, loading: boolean} {
  const [breeds, setBreeds] = useState<TBreedItem[]>([]);
  const [loading, setLoading] = useState(false);
  const handleError = useErrorHandler();
  const [token] = useRecoilState(tokenState);

  const fetchBreeds = useCallback(
    (kindId: number, offset: number, limit: number) => {
      setLoading(true);

      if (token && kindId >= 0) {
        get('PETS_BREEDS', { kind_id: kindId, limit, offset, ...(breedId && { breed_id: breedId }) }, '', token)
          .then(res => {
            const newBreeds = res.data.results;
            setBreeds(prevBreeds => [...prevBreeds, ...newBreeds]);
            setLoading(false);
          })
          .catch(err => {
            handleError(err);
            setLoading(false);
          });
      }
    },
    [breedId, handleError, token],
  );

  const loadMoreBreeds = () => {
    if (!loading && kindId && limit) {
      const newOffset = breeds.length;
      fetchBreeds(kindId, newOffset, limit);
    }
  };

  useEffect(() => {
    kindId && limit && fetchBreeds(kindId, initialOffset, limit);
  }, [fetchBreeds, kindId, limit]);

  useEffect(() => {
    setBreeds([]);
  }, [kindId]);

  return {breeds, loadMoreBreeds, fetchBreeds, loading};
}
