import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

// tslint:disable-next-line:typedef
export const useLoginValidationSchema = () => {
  const { t } = useTranslation();
  // tslint:disable-next-line:typedef
  return useMemo(() => {
    return Yup.object().shape({
      phone: Yup.string()
        .required(t('validations.required'))
        .min(8, t('validations.min'))
        .matches(/^([0-9])/, t('validations.phone_number')),
      password: Yup.string()
        .required(t('validations.required'))
        .min(7, t('validations.min'))
        .matches(
          /^(?!.* )(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?!.*[а-яА-ЯіІїЇъЪЫыєЄёЁ])(?=.{7,})/,
          t('validations.password'),
        ),
      code: Yup.string().required(t('validations.code')),
    });
  }, [t]);
};
