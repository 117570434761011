export const en = {
  translation: {
    validations: {
      required: 'Required field',
      email: 'Invalid email address',
      shouldBeChecked: 'Please confirm terms',
      min: 'Too short',
      max: 'Too long',
      code: 'Country must be selected',
      onlyLetters: 'Should contain only letters',
      name: 'Should contain only letters and symbol "-", " " or "_"',
      phone_number: 'Should contain only numbers',
      onlyDigitsAndLetters: 'Should contain only digits and letters',
      password: 'Must has 1 Uppercase, 1 Number and 1 Special',
      confirmPassword: 'Confirm should be the same as password',
      color: `Should contain only letters and symbol "-" or "'" without repetition`,
      special_signs: `Should contain only letters and symbol "-", "," or "'" without repetition`,
      chip: 'The length of the code cannot exceed 30 characters',
      emptyField: 'The filed could not be empty',
      id_chip_stigma: 'Should contain only letters, digits and symbol "-", " "'
    },
    Phone: {
      countryTitle: 'Country',
      searchPlaceholder: 'Search...',
      phonePlaceholder: 'Phone number'
    },
    startPage: {
      login: 'Log In',
      signup: 'Sign Up',
      or: 'OR',
    },
    loginPage: {
      title: 'Log in',
      keepLoggedin: 'Keep me logged in',
      logIn: 'Log in',
      forgot: 'Forgot your password ?',
      placeholders: {
        login: 'Phone number',
        password: 'Password',
      },
      errors: {
        incorrectData: 'There is no user with this number or password!',
        trouble: 'An error occurred. Try again later.'
      },
      back: 'Back',
    },
    forgotPage: {
      title: 'Forgot password',
      description: 'Enter your phone number, we will send you verification code',
      placeholders: {
        phone: 'Phone',
      },
      errors: {
        incorrectData: 'There is no user with this number!',
        trouble: 'The password is changed in the FB or Google account'
      },
      sendCode: 'Send code',
      back: 'Back',
    },
    resetPassPage: {
      title: 'Reset password',
      description: 'Enter new password and verification CODE',
      subtitle: 'Please type the verification code sent to ',
      verify: 'Verify',
      resend: 'RESEND CODE',
      resendMessage: 'Didn`t receive the code?',
      error: 'Code is not valid',
      placeholders: {
        phone: 'Phone',
        password: 'New password',
        re_password: 'Confirm password'
      },
      reset: 'Reset Password',
      back: 'Back',
    },
    signupPage: {
      title: 'Sign up',
      placeholders: {
        phoneNumber: 'Phone number',
        name: 'Name',
        password: 'Password',
        re_password: 'Confirm password',
        email: 'Email',
      },
      noCountriesFound: 'No countries found!',
      terms: {
        text: 'I have read the',
        link: 'Privacy policy',
      },
      register: 'Register',
      back: 'Back',
      errors: {
        userExists: 'User with current phone number already exists',
        missingRecaptcha: 'It is necessary to confirm the recaptcha',
        trouble: 'An error occurred. Try again later.'
      },
    },
    verifyPhonePage: {
      title: 'Please, enter your phone number.',
      description: 'An SMS with an activation code will be sent to the specified number',
      next: 'Send code',
      back: 'Back',
      placeholder: 'Phone number',
      errors: {
        incorrectData: 'Phone number is incorrect!',
        trouble: 'An error occurred. Try again later.',
        socialNumberExist: 'This number is already in use!'
      },
    },
    verifyPhoneCode: {
      back: 'Back',
      title: 'Verification code',
      subtitle: 'Please type the verification code sent to ',
      verify: 'Validate',
      resendMessage: 'Didn`t receive the code?',
      resend: 'RESEND CODE',
      error: 'Code is not valid',
    },
    sww: {
      title: 'Something went wrong',
      description: 'Please, try to sign in/log in again',
      button: 'Try again'
    },
    shared: {
      footerMenu: {
        calendar: 'Calendar',
        map: 'Map',
        chat: 'Chat',
        contacts: 'Contacts',
      },
      addCard: {
        title: 'Add new',
      },
      spinnerText: 'loading ...'
    },
    dashboard: {
      pets: 'Pets',
      owner: 'Owner',
      deletePetNotificationText: 'Are you sure that you want to delete this pet?',
      limitForCreatingPetsNotificationTitle: 'Limit of adding pets',
      limitForCreatingPetsNotification: 'You have reached the limit of adding pets'
    },
    sidebar: {
      pet_name: 'Pet name',
      edit: 'Edit Profile',
      language: 'Language',
      confidentiality: 'Confidentiality',
      confItem: 'All',
      theme: 'Theme',
      notifications: 'Notifications',
      map: 'Map marker',
      information: 'Information',
      help: 'Help',
      logout: 'Logout',
      getPremium: 'Get Premium'
    },
    ProfilePage: {
      title: 'Profile',
      edit: 'Edit',
      ukraine: 'Ukraine',
      placeholders: {
        first_name: 'Name',
        last_name: 'Surname',
        birthday: 'Birthday',
        email: 'user@domain.com',
        phone_number: 'Phone number',
        male: 'Male',
        female: 'Female',
        country: 'Country',
        city: 'City',
      },
    },
    EditPage: {
      title: 'Profile edit',
      Done: 'Save',
      errors: {
        error: 'Some of data is invalid',
        trouble: 'An error occurred. Try again later.'
      },
      placeholders: {
        first_name: 'Name',
        last_name: 'Surname',
        birthday: 'Birthday',
        phone_number: 'Phone number',
        email: 'user@domain.com',
        country: 'Country',
        city: 'City',
        search: 'Search...',
        citySearch: 'Nothing was found'
      },
      ukraine: 'Ukraine',
      male: 'Male',
      female: 'Female'
    },
    EditPetPage: {
      title: 'Edit profile',
      Done: 'Done',
      errors: {
        error: 'Some of data is invalid',
      },
      placeholders: {
        kind: 'Kind',
        name: 'Name',
        birthday: 'Date of birth',
        breed: 'Breed',
        gender: 'Sex',
        color: 'Color',
        special_signs: 'Special signs',
        //food_type: 'Type of food',
        //sterilization: 'Sterilization',
        place_of_extension: 'Place of extension',
        //outdoor_schedule: 'Outdoor schedule',
        //animal_id: 'Animal id',
        temperament: 'Temperament'
      },
      ukraine: 'Ukraine',
      male: 'Male',
      female: 'Female'
    },
    petForm: {
      title: 'To add the pet',
      submitButtonText: 'Save',
      placeholders: {
        kind: 'Kind',
        name: 'Name',
        birthday: 'Date of birth',
        breed: 'Breed',
        gender: 'Sex',
        color: 'Color',
        special_signs: 'Special signs',
        food: 'Type of food',
        sterilization: 'Sterilization',
        living_place: 'Place of extension',
        outdoor_schedule: 'Outdoor schedule',
        animal_id: 'Chip/Stigma ID',
        temperament: 'Temperament',

      },
      male: 'Male',
      female: 'Female',
      stigma: 'Stigma',
      chip: 'Chip',
      natural: 'Natural',
      feed: 'Feed',
      mixed: 'Mixed',
      add: 'Add',
      delete: 'Delete',
      yes: 'Yes',
      no: 'No',
      not_chosen: 'Not chosen',
      no_chip: 'No',
      limit_notification: 'The limit for adding the number of walks has been reached'
    },
    petInfo: {
      registration_date: 'Registration date',
      stigma: 'Stigma ID',
      chip: 'Chip ID',
    },

    LanguagePage: {
      title: 'Language'
    },
    confidentPage: {
      title: 'Confidentality',
      status: 'Online status'
    },
    helpPage: {
      title: 'Help',
      problem: 'Report a problem',
      center: 'Help center',
      reviews: 'Reviews',
    },
    informationPage: {
      title: 'Information',
      contacts: 'Contacts',
      about: 'About',
    },
    notificationsPage: {
      title: 'Notifications',
      show: 'Show notifications',
      messReq: 'Message requests',
      messages: 'Messages',
      reqJoinGroup: 'Requests to join a group'
    },
    Contacts: {
      title: 'Contact',
      addButton: 'Add',
      description1: 'Are you sure that you want to delete ',
      description2: 'this contact?',
      sww: 'Something was wrong',
      tryAgain: 'Try again',
      deleteDescription: 'Deleted successfully',
      placeholders: {
        search: 'Search...',
        yesDelete: 'Yes, delete',
        delete: 'Delete',
        change: 'Change',
        cancel: 'Cancel',
        pending: 'Pending...'
      },
    },
    AddContact: {
      title: 'Add users',
      placeholders: {
        search: 'Search...',
        delete: 'Delete',
        resend: 'Resend',
      }
    },
    ChatPage: {
      title: 'Messages',
    },
    invite: {
      description1: 'Sorry, we didn`t find users with this phone number.',
      description2: 'You can invite new users',
      inviteButton: 'Invite',
    },
    selects: {
      kind: {
        crows: "Crow",
        owls: "Owl",
        parrots: "Parrot",
        canaries: "Canary",
        amadins: "Amadin",
        snakes: "Snake",
        lizards: "Lizard",
        crocodiles: "Crocodile",
        alligators: "Alligator",
        turtles: "Turtle",
        squirrels: "Squirrel",
        guinea_pigs: "Guinea pig",
        mice: "Mouse",
        rats: "Rat",
        chinchillas: "Chinchilla",
        hamsters: "Hamster",
        anteaters: "Anteater",
        bears: "Bear",
        lynx: "Lynx",
        cougars: "Cougar",
        panthers: "Panther",
        lions: "Lion",
        tigers: "Tiger",
        fishes: "Fish",
        rabbits: "Rabbit",
        insects: "Insect",
        amphibians: "Amphibia",
        birds: "Bird",
        reptiles: "Reptile",
        ferrets: "Ferret",
        monkeys: "Monkey",
        foxes: "Fox",
        pigs: "Pig",
        horses: "Horse",
        cats: "Cat",
        dogs: "Dog"
      },
      place: {
        apartment: 'Apartment',
        house: 'House',
        other: 'Other'
      },
      temperament: {
        friendly: 'Friendly',
        unfriendly: 'Unfriendly',
        middle: 'Middle'
      }
    },
    appErrorNotification: {
      trouble: 'Oops, something went wrong, please try again!',
      tryLater: 'Something went wrong, please try later!'
    },
    PetProfilePage: {
      title: 'Pet profile',
      tabs: {
        gallery: 'Gallery',
        info: 'Information',
        medical: 'Medical record',
        calendar: 'Calendar'
      },
      calendar: {
        addButton: 'Add Event',
        addButton2: 'Back to list',
        allergies: 'Allergy',
        vaccination: 'Vaccination',
        treatment: 'Parasite treatment',
        vet: 'Appointments at the veterinarian',
        services: 'Additional services',
        noEvents: 'No events this day',
        loadingEvents: 'Events are loading...',
        today: 'Today',
      },
      notifications: {
        invalidDataTitle: 'Invalid date or time',
        invalidDataText: 'You can not create event in the past',
        successTitle: 'Success!',
        successText: 'You have successfully added ',
        deleteEvent: 'Are you sure that you want to delete this event?',
      },
      commonEventForm: {
        save: 'Save',
        add: 'add',
        emptyListText: `You don't have any `,
        time: 'Time',
      },
      allergyForm: {
        periodName: 'Date of onset of symptoms: ',
        pageTitle: 'Allergy',
        emptyListMarker: 'allergy',
        addedText: 'allergy',
        buttonAlt: 'allergy',
        addPageTitle: 'Add an allergy',
        editPageTitle: 'Edit an allergy',
        allergyTypeTitle: 'Type of allergy',
        allergyTypeOptions: {
          drug: 'Drug allergy',
          food: 'Food allergy',
          insect: 'Insect bite',
          other: 'Other',
        },
        addPageInputFields: {
          name: 'Allergen name'
        },
        addPageTextareaFields: {
          notes: 'Notes'
        },
      },
      servicesForm: {
        periodName: 'Date of the visit: ',
        pageTitle: 'Additional services',
        emptyListMarker: 'additional services',
        addedText: 'additional service',
        buttonAlt: 'Additional services',
        addPageTitle: 'Add a service',
        editPageTitle: 'Edit a service',
        addPageInputFields: {
          name: 'Service name',
          institution: 'Institution`s name',
          address: 'Address',
        },
        addPageTextareaFields: {
          notes: 'Notes'
        }
      },
      vaccinationForm: {
        periodName: 'Date of the vaccination: ',
        pageTitle: 'Vaccination',
        emptyListMarker: 'vaccination',
        addedText: 'vaccination',
        buttonAlt: 'Vaccination',
        addPageTitle: 'Add a vaccination',
        editPageTitle: 'Edit a vaccination',
        addPageInputFields: {
          name: 'Vaccination name',
          drug: 'Drug name',
          address: 'Address',
        },
        addPageTextareaFields: {
          notes: 'Notes'
        }
      },
      treatmentForm: {
        periodName: 'Date of the treatment: ',
        pageTitle: 'Parasite treatment',
        emptyListMarker: 'parasite treatment',
        addedText: 'parasite treatment',
        buttonAlt: 'Parasite treatment',
        addPageTitle: 'Add a treatment',
        editPageTitle: 'Edit a treatment',
        addPageInputFields: {
          reason: 'Reason for treatment',
          drug: 'Drug name',
          address: 'Address',
        },
        addPageTextareaFields: {
          notes: 'Notes'
        }
      },
      veterinarianForm: {
        periodName: 'Date of the appointment: ',
        pageTitle: 'Appointments at the veterinarian',
        emptyListMarker: 'appointments at the veterinarian',
        addedText: 'appointments at the veterinarian',
        buttonAlt: 'veterinarian services',
        addPageTitle: 'Add an appointment',
        editPageTitle: 'Edit an appointment',
        addPageInputFields: {
          name: 'Petclinic name',
          address: 'Clinic address',
          procedure: 'Name of procedure',
        },
        addPageTextareaFields: {
          notes: 'Notes'
        }
      },
    },
    ViewEventPage: {
      edit: 'Edit',
      date: 'Date',
    },
    Notification: {
      geolocation: 'To display the local time correctly, please turn on geolocation in the browser settings!'
    },
    PopUp: {
      cancel: 'Cancel',
      continue: 'Continue',
      loosingData: "You may lose the data on this page if you don't save it!"
    }
  },
}
