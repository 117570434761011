import { DATA_BASE_FORMAT, FINISH_DATE_DATA_BASE_FORMAT, UTC_DATA_BASE_FORMAT } from '../const';
import dayjs, { Dayjs } from 'dayjs';

import type { CalendarDate } from '../types';
import utc from 'dayjs/plugin/utc';

export const today = dayjs();
dayjs.extend(utc);

export function getCurrentDay(date: CalendarDate, minDate: Dayjs, maxDate: Dayjs): CalendarDate {
  if (date && date < minDate) return minDate;
  if (date && date > maxDate) return maxDate;
  return date;
}

export function getMinMaxDate(disablePast: boolean): [Dayjs, Dayjs] {
  const minDate = disablePast ? today : today.subtract(4, 'year');

  const after3Month = today.add(3, 'month');
  const daysInMonth = after3Month.daysInMonth();
  const maxDate = after3Month.date(daysInMonth);

  return [minDate, maxDate];
}

export function getTimeOffset(): string {
  const offset = dayjs().utcOffset() / 60;
  return offset.toString();
}

export function getDateWithTimeFromUTC(
  utcDate: string,
  dateFormat: string,
  timeFormat: string,
): [date: string, time: string] {
  const date = dayjs(utcDate).utc(true).format(dateFormat);
  const time = dayjs(utcDate).utc(true).format(timeFormat);
  return [date, time];
}
export function getTimeFromUTC(utcDate: string, timeFormat: string): string {
  return dayjs(utcDate).utc(true).format(timeFormat);
}

export function transformRangeDateToUTC(
  dateFrom: string | dayjs.Dayjs | null,
  dateTo: string | dayjs.Dayjs | null = dateFrom,
): [UTCStartDate: string, UTCFinishDate: string] {
  const startDate = dayjs(dateFrom).format(DATA_BASE_FORMAT);
  const UTCStartDate = dayjs(startDate).utc().format(UTC_DATA_BASE_FORMAT).concat('Z');

  const finishDate = dayjs(dateTo || dateFrom).format(FINISH_DATE_DATA_BASE_FORMAT);
  const UTCFinishDate = dayjs(finishDate).utc().format(UTC_DATA_BASE_FORMAT).concat('Z');
  return [UTCStartDate, UTCFinishDate];
}

export function transformDateToFormat(timeUnit: Dayjs | string, format: string) {
  return dayjs(timeUnit).format(format);
}

export function getMonthFromDate(date: Dayjs | string | null) {
  return dayjs(date).month();
}
