export const uk = {
  translation: {
    validations: {
      required: "Обов'язкове поле",
      email: 'Невірна адреса електронної пошти',
      shouldBeChecked: 'Підтвердьте умови',
      min: 'Занадто короткий',
      max: 'Надто довго',
      onlyLetters: 'Має містити лише літери',
      name: 'Має містити лише літери та 1 символ «-», « » або «_»',
      phone_number: 'Повинен містити тільки цифри',
      onlyDigitsAndLetters: 'Має містити лише цифри',
      password: "Має містити 1 велику літеру, цифру та спец.символ",
      code: "Обов'язково оберить країну",
      confirmPassword: 'Підтвердження має збігатися з паролем',
      color: `Має містити лише літери та 1 символ «-» або «'» без повторень`,
      special_signs: `Має містити лише літери та символи «-», «,» або «'» без повторень`,
      chip: 'Довжина коду не може перевищувати 30 символів',
      emptyField: 'Поле не може бути порожнім',
      id_chip_stigma: 'Має містити лише літери, цифри та символи «-», « »'
    },
    Phone: {
      countryTitle: 'Kраїна',
      searchPlaceholder: 'Пошук...',
      phonePlaceholder: 'Номер телефону'
    },
    startPage: {
      login: 'Увійти',
      signup: 'Зареєструватися',
      or: 'АБО',
    },
    loginPage: {
      title: 'Увійти',
      keepLoggedin: 'Залишити мене в системі',
      logIn: 'Увійти',
      forgot: 'Забули свій пароль ?',
      placeholders: {
        login: 'Номер телефону',
        password: 'Пароль',
      },
      errors: {
        incorrectData: 'Не коректний номер телефону або пароль!',
        trouble: 'Сталась помилка. Спробуйте ще раз пізніше.'

      },
      back: 'Назад',
    },
    forgotPage: {
      title: 'Забули пароль',
      description: 'На вказаний номер буде відправлено СМС з кодом активації',
      placeholders: {
        phone: 'Номер телефону',
      },
      errors: {
        incorrectData: 'Немає жодного користувача з цим номером!',
        trouble: 'Пароль змінюється в Facebook або Google аккаунті'
      },
      sendCode: 'Відправити код',
      back: 'Назад',
    },
    resetPassPage: {
      title: 'Скинути пароль',
      description: 'Введіть новий пароль і КОД підтвердження',
      subtitle: 'Будь ласка, введіть код підтвердження, надісланий на номер ',
      verify: 'Підтвердити',
      resend: 'ВІДПРАВИТИ КОД ПОВТОРНО',
      resendMessage: 'Не отримали код?',
      error: 'Код недійсний',
      placeholders: {
        phone: 'Номер телефону',
        password: 'Новий пароль',
        re_password: 'Підтвердьте пароль'
      },
      reset: 'Скинути пароль',
      back: 'Назад',
    },
    signupPage: {
      title: 'Зареєструватися',
      placeholders: {
        phoneNumber: 'Номер телефону',
        name: "Ім'я",
        password: 'Пароль',
        re_password: 'Підтвердьте пароль',
        email: 'Електронна пошта',
      },
      noCountriesFound: 'Країни не знайдено!',
      terms: {
        text: 'Я прочитав',
        link: 'Політику конфіденційності',
      },
      register: 'Зареєструватися',
      back: 'Назад',
      errors: {
        userExists: 'Користувач із поточним номером телефону вже існує',
        missingRecaptcha: 'Необхідно підтвердити рекаптчу',
        trouble: 'Сталась помилка. Спробуйте ще раз пізніше.'

      },
    },
    verifyPhonePage: {
      title: 'Будь ласка, введіть свій номер телефону.',
      description: 'На вказаний номер буде відправлено СМС з кодом активації',
      next: 'Відправити код',
      back: 'Назад',
      placeholder: 'Номер телефону',
      errors: {
        incorrectData: 'Не коректний номер телефону!',
        trouble: 'Сталась помилка. Спробуйте ще раз пізніше.',
        socialNumberExist: 'Цей номер вже використовується!'
      },
    },
    verifyPhoneCode: {
      back: 'Назад',
      title: 'Код підтвердження',
      subtitle: 'Будь ласка, введіть код підтвердження, надісланий на номер ',
      verify: 'Перевірити',
      resendMessage: 'Не отримали код?',
      resend: 'НАДІСЛАТИ КОД ПОВТОРНО',
      error: 'Код недійсний',
    },
    shared: {
      footerMenu: {
        calendar: 'Календар',
        map: 'Карта',
        chat: 'Чат',
        contacts: 'Контакти',
      },
      addCard: {
        title: 'Додати новий',
      },
      spinnerText: 'завантаження ...'
    },
    dashboard: {
      pets: 'Домашні тварини',
      owner: 'Власник',
      deletePetNotificationText: 'Ви дійсно хочете видалити цього улюбленця?',
      limitForCreatingPetsNotificationTitle: 'Ліміт додавання улюбленців',
      limitForCreatingPetsNotification: 'Ви досягли ліміту додавання улюбленців'
    },
    sidebar: {
      pet_name: "Кличка улюбленця",
      edit: 'Редагувати профіль',
      language: 'Мова',
      confidentiality: 'Конфіденційність',
      confItem: 'Всі',
      theme: 'Тема',
      notifications: 'Сповіщення',
      map: 'Карта',
      information: 'Інформація',
      help: 'Допомога',
      logout: 'Вийти',
      getPremium: 'Отримати преміум'
    },
    ProfilePage: {
      title: 'Профіль',
      edit: 'Редагувати',
      ukraine: 'Україна',
      placeholders: {
        first_name: 'Ім`я',
        last_name: 'Прiзвище',
        birthday: 'Дата народження',
        email: 'user@domain.com',
        phone_number: 'Номер телефону',
        male: 'Чоловік',
        female: 'Жінка',
        country: 'Країна',
        city: 'Місто',
      },
    },

    EditPage: {
      title: 'Редагування профілю',
      Done: 'Зберегти',
      errors: {
        error: 'Деякі дані недійсні',
        trouble: 'Сталась помилка. Спробуйте ще раз пізніше.'
      },
      placeholders: {
        first_name: 'Ім\'я',
        last_name: 'Прізвище',
        birthday: 'Дата народження',
        phone_number: 'Номер телефону',
        email: 'user@domain.com',
        country: 'Країна',
        city: 'Місто',
        search: 'Пошук...',
        citySearch: 'Нічого не знайдено'
      },
      ukraine: 'Україна',
      male: 'Чоловік',
      female: 'Жінка'
    },
    EditPetPage: {
      title: 'Edit profile',
      Done: 'Done',
      errors: {
        error: 'Some of data is invalid',
      },
      placeholders: {
        kind: 'Kind',
        name: 'Name',
        birthday: 'Date of birth',
        breed: 'Breed',
        gender: 'Sex',
        color: 'Color',
        special_signs: 'Special signs',
        //food_type: 'Type of food',
        //sterilization: 'Sterilization',
        place_of_extension: 'Place of extension',
        //outdoor_schedule: 'Outdoor schedule',
        //animal_id: 'Animal id',
        temperament: 'Temperament'
      },
      ukraine: 'Ukraine',
      male: 'Male',
      female: 'Female'
    },
    petForm: {
      title: 'Додати улюбленця',
      submitButtonText: 'Зберегти',
      errors: {
        incorrectData: 'Ви ввели неправильні данні',
      },
      placeholders: {
        kind: 'Вид',
        name: 'Кличка',
        birthday: 'День народження',
        breed: 'Порода',
        gender: 'Стать',
        color: 'Колір',
        special_signs: 'Особливі ознаки',
        food: 'Тип їжі',
        sterilization: 'Стерелізація',
        living_place: 'Місце проживання',
        outdoor_schedule: 'Розклад прогулянок',
        animal_id: 'Чіп/Клеймо ID',
        temperament: 'Характер',

      },
      male: 'Чоловіча',
      female: 'Жіноча',
      stigma: 'Клеймо',
      chip: 'Чіп',
      natural: 'Натуралка',
      feed: 'Корм',
      mixed: 'Змішаний тип',
      add: 'Додати',
      delete: 'Видалити',
      yes: 'Так',
      no: 'Ні',
      not_chosen: 'Не вибрано',
      no_chip: 'Немає',
      limit_notification: 'Досягнутий ліміт кількості вигулів тваринки'
    },
    petInfo: {
      registration_date: 'Дата реєстрації',
      stigma: 'Клеймо ID',
      chip: 'Чіп ID',
    },

    LanguagePage: {
      title: 'Мова'
    },
    confidentPage: {
      title: 'Конфіденційність',
      status: 'Онлайн статус'
    },
    helpPage: {
      title: 'Допомога',
      problem: 'Повідомити про проблему',
      center: 'Центр допомоги',
      reviews: 'Відгуки',
    },
    informationPage: {
      title: 'Інформація',
      contacts: 'Контакти',
      about: 'Про',
    },
    notificationsPage: {
      title: 'Сповіщення',
      show: 'Показати сповіщення',
      messReq: 'Запити на повідомлення',
      messages: 'Повідомлення',
      reqJoinGroup: 'Запити на вступ до групи'
    },
    Contacts: {
      title: 'Добавить пользователя',
      addButton: 'Добавити',
      description1: 'Ви впевнені, що хочете видалити ',
      description2: 'цей контакт?',
      sww: 'Щось пішло не так',
      tryAgain: 'Спробуйте знову',
      deleteDescription: 'Видалити вдалося',
      placeholders: {
        search: 'Пошук...',
        yesDelete: 'Так, видалити',
        delete: 'Видалити',
        change: 'Змінити',
        cancel: 'Відмінити',
        pending: 'В очікуванні...',
      },
    },
    AddContact: {
      title: 'Добавити користувача',
      placeholders: {
        search: 'Пошук...',
        delete: 'Видалити',
        resend: 'Повторити',
      }
    },
    invite: {
      description1: 'Вибачте, ми не знайшли користувачів із цим номером телефону.',
      description2: 'Ви можете запрошувати нових користувачів.',
      inviteButton: 'Запросити',
    },
    selects: {
      kind: {
        crows: "Ворона",
        owls: "Сова",
        parrots: "Папуга",
        canaries: "Канарка",
        amadins: "Амадін",
        snakes: "Змія",
        lizards: "Ящірка",
        crocodiles: "Крокодил",
        alligators: "Алігатор",
        turtles: "Черепаха",
        squirrels: "Білка",
        guinea_pigs: "Морська свинка",
        mice: "Миша",
        rats: "Криса",
        chinchillas: "Шиншила",
        hamsters: "Хом'як",
        anteaters: "Мурахоїд",
        bears: "Ведмедь",
        lynx: "Рись",
        cougars: "Пума",
        panthers: "Пантера",
        lions: "Лев",
        tigers: "Тигр",
        fishes: "Риба",
        rabbits: "Кролик",
        insects: "Комаха",
        amphibians: "Амфібія",
        birds: "Птах",
        reptiles: "Рептилія",
        ferrets: "Тхір",
        monkeys: "Мавпа",
        foxes: "Лисиця",
        pigs: "Свиня",
        horses: "Кінь",
        cats: "Кот",
        dogs: "Собака"
      },
      place: {
        apartment: 'Квартира',
        house: 'Будинок',
        other: 'Інше'
      },
      temperament: {
        friendly: 'Дружелюбний',
        unfriendly: 'Недружелюбний',
        middle: 'Середній'
      }
    },
    appErrorNotification: {
      trouble: 'Упс, щось пішло не так, спробуйте, будь-ласка, ще раз!',
      tryLater: 'Щось пішло не так, спробуйте, будь-ласка, пізніше.'
    },
    PetProfilePage: {
      title: 'Профіль тварини',
      tabs: {
        gallery: 'Галерея',
        info: 'Інформація',
        medical: 'Медичний запис',
        calendar: 'Календар'
      },
      calendar: {
        addButton: 'Додати подiю',
        addButton2: 'Повернутись до списку',
        allergies: 'Алергiя',
        vaccination: 'Вакцинацiя',
        treatment: 'Лікування від паразитів',
        vet: 'Запис до ветеринара',
        services: 'Додаткові послуги',
        noEvents: 'Цього дня немає запланованих подій',
        loadingEvents: 'Подiї завантажуються...',
        today: 'Сьогодні',
      },
      notifications: {
        invalidDataTitle: 'Неправильна дата або час',
        invalidDataText: 'Ви не можете створити подію в минулому',
        successTitle: 'Успіх!',
        successText: 'Ви успішно створили ',
        deleteEvent: 'Ви дійсно хочете видалити цю подію?',
      },
      commonEventForm: {
        save: 'Зберегти',
        add: 'додати ',
        emptyListText: 'Нема жодно',
        time: 'Час',
      },
      allergyForm: {
        periodName: 'Дата появи симптомів: ',
        pageTitle: 'Алергія',
        emptyListMarker: 'ї алергії',
        addedText: 'алергію',
        buttonAlt: 'алергія',
        addPageTitle: 'Додати алергію',
        editPageTitle: 'Змінити алергія',
        allergyTypeTitle: 'Вид алергії',
        allergyTypeOptions: {
          drug: 'Алергія на ліки',
          food: 'Харчова алергія',
          insect: 'Укус комахи',
          other: 'Інше',
        },
        addPageInputFields: {
          drug: 'Назва алергену'
        },
        addPageTextareaFields: {
          notes: 'Нотатки'
        }
      },
      servicesForm: {
        periodName: 'Дата візиту: ',
        pageTitle: 'Додаткові послуги',
        emptyListMarker: 'ї додаткової послуги',
        addedText: 'додаткову послугу',
        buttonAlt: 'Додаткові послуги',
        addPageTitle: 'Додати послугу',
        editPageTitle: 'Редагувати послугу',
        addPageInputFields: {
          name: 'Назва послуги',
          institution: 'Назва установи',
          address: 'Адреса',
        },
        addPageTextareaFields: {
          notes: 'Нотатки'
        }
      },
      vaccinationForm: {
        periodName: 'Дата щеплення: ',
        pageTitle: 'Щеплення',
        emptyListMarker: 'го щеплення',
        addedText: 'щеплення',
        buttonAlt: 'щеплення',
        addPageTitle: 'Додайте щеплення',
        editPageTitle: 'Відредагуйте щеплення',
        addPageInputFields: {
          name: 'Назва щеплення',
          drug: 'Назва препарату',
          address: 'Адреса',
        },
        addPageTextareaFields: {
          notes: 'Нотатки'
        }
      },
      treatmentForm: {
        periodName: 'Дата лікування: ',
        pageTitle: 'Лікування від паразитів',
        emptyListMarker: 'го запису про лікування від паразитів',
        addedText: 'лікування від паразитів',
        buttonAlt: 'Лікування від паразитів',
        addPageTitle: 'Додайте лікування',
        editPageTitle: 'Редагувати лікування',
        addPageInputFields: {
          reason: 'Причина лікування',
          drug: 'Назва препарату',
          address: 'Адреса',
        },
        addPageTextareaFields: {
          notes: 'Нотатки'
        }
      },
      veterinarianForm: {
        periodName: 'Дата прийому: ',
        pageTitle: 'Запис до ветеринара',
        emptyListMarker: 'го запису на прийом до ветеринара',
        addedText: 'запис до ветеринара',
        buttonAlt: 'ветеринарні послуги',
        addPageTitle: 'Додайте зустріч',
        editPageTitle: 'Редагувати зустріч',
        addPageInputFields: {
          name: 'Назва ветклініки',
          address: 'Адреса клініки',
          procedure: 'Назва процедури',
        },
        addPageTextareaFields: {
          notes: 'Нотатки'
        }
      },
    },
    ViewEventPage: {
      edit: 'Редагувати',
      date: 'Дата',
    },
    Notification: {
      geolocation: 'Для вірного відображення місцевого часу включить будь-ласка геолокацію у налаштуваннях браузера!'
    },
    PopUp: {
      cancel: 'Відмінити',
      continue: 'Продовжувати',
      loosingData: "Ви можете втратити дані на цій сторінці, якщо не збережете їх!"
    }
  },
}
