import './PetToSimilarEventButton.scss';

import type { ContainerProps } from '../../types';
import { PetProfileRoutePath } from '../../utils/enums/routes-path';
import { PetTab } from '../../enums';
import arrow_right from '../../../../../assets/images/pet/arrow-right.png';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';

export const PetToSimilarEventButtonMemo = memo(function PetToSimilarEventButton({
  icon,
  title,
  link,
  warning,
  date,
  petId,
  tabState,
}: ContainerProps) {
  const history = useHistory();
  const stateObj = tabState === PetTab.MEDICAL ? { from: PetTab.MEDICAL } : { from: PetTab.CALENDAR, date: date };
  const goToLink = (): void => {
    history.replace(`${PetProfileRoutePath.PET}/${petId}/${link}`, stateObj);
  };

  return (
    <div className={`pet-medical-container${warning ? ' warning' : ''}`} onClick={goToLink}>
      <span className=''>
        <span className='pet-medical-container-icon'>
          {<img src={icon[0]} alt='icon' />}
        </span>
        <span className='pet-medical-container-title'>{title}</span>
      </span>
      <button className='pet-medical-container-link'>
        <img src={arrow_right} alt='arrow-right' />
      </button>
    </div>
  );
});
