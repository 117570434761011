import { Field } from 'formik';
import { PetFields } from '../../../../enums';
import { CustomInput } from '../../../../../shared';
import { ErrorsTypes } from '../../../../../../../utils/enums';
import { useTranslation } from 'react-i18next';
import { LABEL_I18_KEY } from './constants/constants';
import { usePetForm } from '../../hooks';

export function IdChipStigma({ ...props }) {
  const { selectedChipStigmaId, form } = props;
  const { t } = useTranslation();
  const { handleClearField } = usePetForm();
  const isNoChip = selectedChipStigmaId === PetFields.NoChip;
  const label = t(LABEL_I18_KEY[selectedChipStigmaId]);

  if (isNoChip) {
    return null;
  }

  return (
    <Field
      id={PetFields.IdChipStigma}
      component={CustomInput}
      name={PetFields.IdChipStigma}
      required
      clear={handleClearField}
      errors={form.touched.id_chip_stigma && form.errors.id_chip_stigma}
      errorType={ErrorsTypes.Transparent}
      label={label}
    />
  );
}
