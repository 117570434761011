import * as Yup from 'yup';

import type { PetEventDetails, TValidationObj } from '../types';
import { useTranslation } from 'react-i18next';

export const useEventValidSchema = (details: PetEventDetails) => {
  const { t } = useTranslation();
  const requiredField: string = t('validations.required');
  const maxLengthAlert: string = t('validations.max');
  const validation: TValidationObj = {};
  const inputMaxLength = 200;
  const textareaMaxLength = 2000;

  validation.dateField = Yup.string().notRequired();

  if (details.addPageSelectFields?.length) {
    details.addPageSelectFields.forEach(selectField => {
      const required: boolean | undefined = selectField.required;
      const fieldName: string = selectField.fieldName;
      if (!required) {
        validation[fieldName] = Yup.string().notRequired();
        return;
      }
      validation[fieldName] = Yup.string().required(requiredField);
    }); 
  }

  if (details.addPageInputFields?.length) {
    details.addPageInputFields.forEach(inputField => {
      const required: boolean | undefined = inputField.required;
      const fieldName: string = inputField.fieldName;
      if (!required) {
        validation[fieldName] = Yup.string().max(inputMaxLength, maxLengthAlert).notRequired();
        return;
      }
      validation[fieldName] = Yup.string().max(inputMaxLength, maxLengthAlert).required(requiredField);
    });
  }

  if (details.addPageTimeSetField) {
    validation.timeSetField = Yup.number().required(requiredField);
  }

  if (details.addPageTextareaFields?.length) {
    details.addPageTextareaFields.forEach(textareaField => {
      const fieldName = textareaField.fieldName;
      validation[fieldName] = Yup.string().max(textareaMaxLength, maxLengthAlert).notRequired();
    });
  }
  const schema: Yup.AnySchema = Yup.object(validation);
  return schema;
};
