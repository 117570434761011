import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';

import { Badge } from '@mui/material';
import Box from '@material-ui/core/Box';
import type { Dayjs } from 'dayjs';
import { memo } from 'react';
import { useData } from './hooks/useData';

export const EventBadgeMemo = memo(function EventBadge({
  eventdays = [],
  day,
  outsideCurrentMonth,
  ...other
}: PickersDayProps<Dayjs> & { eventdays?: number[] }): JSX.Element {
  const isSelected = !outsideCurrentMonth && eventdays.indexOf(day.date()) >= 0;
  const key = day.toString();
  const { anchorOrigin } = useData({ EventBadgeMemo, eventdays });

  return (
    <Badge
      key={key}
      overlap='circular'
      badgeContent={
        isSelected ? (
          <Box height='0.4rem' width='0.4rem' border='#47cfde solid 0.2rem' borderRadius='50%' marginLeft='28px'></Box>
        ) : undefined
      }
      anchorOrigin={anchorOrigin}>
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </Badge>
  );
});
