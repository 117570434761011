import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

// tslint:disable-next-line:typedef
export const usePhoneValidationSchema = () => {
    const {t} = useTranslation();
    // tslint:disable-next-line:typedef
    return useMemo(() => {
        return Yup.object().shape({

            phone: Yup.string()
                .required(t('validations.required'))
                .min(8, t('validations.min'))
                .matches(/^([0-9])/, t('validations.phone_number')),
            code: Yup.string()
                .required(t('validations.code')),
            recaptcha_token: Yup.string()
                .required(t('validations.required')),
            terms: Yup.boolean().oneOf([true], 'Field must be checked')
        });
    }, [t]);
};
