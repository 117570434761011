import { Formik } from 'formik';
import type { ReactElement } from 'react';
import { RequestState } from '../../../../../utils/enums';
import { PageLoader } from '../../../../modules/shared/components';
import { AuthContent } from '../../components/AuthContent/AuthContent';
import type { TLoginForm } from '../../types/types';
import { useLoginValidationSchema } from '../../utils/validationSchemas/LoginValidationSchema';
import { LoginForm } from './components/LoginForm/LoginForm';
import { useData } from './useData';
import { useHandlers } from './useHandlers';

export function LoginPage(): ReactElement {
  const LoginValidationSchema = useLoginValidationSchema();
  const { handleSubmit, loginStatus } = useHandlers();
  const { initialValues } = useData();

  return (
    <>
      <AuthContent translationRootPath={'loginPage'} prevUrl={'/start'} content={`login`}>
        <Formik<TLoginForm>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={LoginValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}>
          <LoginForm loginStatus={loginStatus} />
        </Formik>
      </AuthContent>
      {loginStatus === RequestState.Pending && <PageLoader />}
    </>
  );
}
