import React, {useEffect, useState} from 'react';
import {useErrorHandler} from 'react-error-boundary';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import ukr from '../../../../../assets/images/flags/ua.svg';
import en from '../../../../../assets/images/flags/usa.svg';
import {ProfileContent} from '../../components/ProfileContent/ProfileContent';
import {useUpdate} from '../../utils/hooks/useUpdate';
import './LanguagePage.scss';

// tslint:disable-next-line:typedef
export const LanguagePage = () => {
    const phone_number = localStorage.getItem('phone_number');
    const handleError = useErrorHandler();
    const userLang = localStorage.getItem('language');
    const {t, i18n} = useTranslation();
    const history = useHistory();
    const [incomingLang, setIncomingLang] = useState(userLang);
    const [, onUpdate] = useUpdate(phone_number);
    const goBack = (): void => {
        history.push('/dashboard/main');
    };
    // tslint:disable-next-line:typedef
    const changeLanguageHandler = (newLang: string) => {
        setIncomingLang(newLang);
        // tslint:disable-next-line:typedef
        i18n.changeLanguage(newLang).then(() => {}, error => handleError(error));
        const formData = new FormData();
        formData.append('language', newLang);
        // @ts-ignore
        onUpdate(formData);
    };
    useEffect((): void => {
        const languages = ['en', 'uk'];
        // tslint:disable-next-line:typedef
        languages.map((lang: string) => {
            if (incomingLang === lang) {
                localStorage.setItem('language', incomingLang);
                // @ts-ignore
                return document.getElementById(incomingLang).className = 'language-item checked';
            } else {
                // @ts-ignore
                return document.getElementById(lang).className = 'language-item';
            }
        });
    }, [incomingLang]);
    return (
        <ProfileContent translationRootPath={'languagePage'}>
            <div className='profile-header'>
                <button className='btn-back' onClick={goBack}>
                </button>
                <div className='profile-title'>{t('LanguagePage.title')}</div>
            </div>
            <div className='language-container'>
                <ul>
                    <li className={`language-item ${userLang === 'en' ? 'checked' : ''}`} id='en'
                        onClick={(): void => changeLanguageHandler('en')}><img src={en} alt='en'/>English
                    </li>
                    <li className={`language-item ${userLang === 'uk' ? 'checked' : ''}`} id='uk'
                        onClick={(): void => changeLanguageHandler('uk')}><img src={ukr} alt='uk'/>Українська
                    </li>
                </ul>
            </div>
        </ProfileContent>
    );
};
