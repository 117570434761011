import { DayCalendarSkeleton, LocalizationProvider, type DateCalendarProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar/DateCalendar';
import type { Dayjs } from 'dayjs';
import { Field } from 'formik';
import { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventBadgeMemo } from './Badge';

import 'dayjs/locale/en';
import 'dayjs/locale/uk';
import { getCurrentDay, getMinMaxDate } from '../../../../../utils/helpers/dateHelper';
import type { CalendarDate, CalendarWidgetProps } from '../../../../../utils/types';
import { useData } from './hooks/useData';
import { useHandlers } from './hooks/useHandlers';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { CalendarWidgetTheme } from '../../../../../MUITheme';
import { ThemeProvider, styled } from '@mui/material';

const StyledContainer = styled(Container)({
  position: 'relative',
  minHeight: '340px',
});
const TodayButton = styled(Button)({
  border: 'none',
  background: 'none',
  color: '#2ba8b2',
  textTransform: 'none',
  textDecoration: 'underline solid 1px #2ba8b2',
  fontSize: '14px',
  fontWeight: '600',
  cursor: 'pointer',
  position: 'absolute',
  bottom: '0%',
  left: '50%',
  padding: '0',
  transform: 'translate(-50%, -50%)',
  fontFamily: 'Nunito-Regular, sans-serif',
  '&:hover': {
    background: 'none',
    color: '#2ba8b2',
  },
});

export const CalendarWidgetMemo = memo(function CalendarWidget({
  isFormPart = false,
  value,
  onChange,
  onYearChange,
  handleDayChanging,
  setYearMonth,
  // needToCheckData = false,
  fieldName,
  setDateValue,
  loading = false,
  disablePast = false,
  disableFuture = false,
  hasSpecialMarks = false,
  eventdays,
}: CalendarWidgetProps): JSX.Element {
  const { today, slots, slotProps } = useData({ EventBadgeMemo, eventdays });
  const [date, setDate] = useState<CalendarDate>(value || today);
  const [returnedToToday, setReturnedToToday] = useState<boolean>(false);
  const { t } = useTranslation();

  const [minDate, maxDate] = getMinMaxDate(disablePast);
  const initialCalendarValue = getCurrentDay(date, minDate, maxDate);

  const locale = localStorage.getItem('language');

  const { onDayChange, onMonthChange, onTodayClick } = useHandlers({
    date,
    setDate,
    setDateValue,
    handleDayChanging,
    isFormPart,
    fieldName,
    setYearMonth,
    setReturnedToToday,
  });

  useEffect((): void => {
    if (returnedToToday) {
      setDate(today);
      setDateValue && setDateValue(today);
    }
    setReturnedToToday(false);
    return;
  }, [returnedToToday, setDateValue, today]);

  const commonCalendarSettings: DateCalendarProps<Dayjs> = useMemo(
    () => ({
      maxDate: maxDate,
      minDate: minDate,
      // views: ['day', 'year', 'month', 'day'],
      views: ['day'],
      value: initialCalendarValue,
      onMonthChange: onMonthChange,
      onYearChange: onYearChange,
      dayOfWeekFormatter: (day): string => `${day[0].toUpperCase()}${day.slice(1)}`,
      loading,
      renderLoading: () => <DayCalendarSkeleton />,
      showDaysOutsideCurrentMonth: true,
    }),
    [initialCalendarValue, loading, maxDate, minDate, onMonthChange, onYearChange],
  );

  return (
    <StyledContainer>
      <ThemeProvider theme={CalendarWidgetTheme}>
        <LocalizationProvider adapterLocale={locale} dateAdapter={AdapterDayjs}>
          {isFormPart ? (
            <Field
              name={fieldName}
              component={() => (
                <DateCalendar
                  {...commonCalendarSettings}
                  disablePast={disablePast}
                  disableFuture={disableFuture}
                  onChange={onChange || onDayChange}
                />
              )}></Field>
          ) : (
            <DateCalendar
              {...commonCalendarSettings}
              onChange={onChange || onDayChange}
              slots={slots}
              slotProps={slotProps}
            />
          )}
          {loading ? <></> : <TodayButton onClick={onTodayClick}>{t('PetProfilePage.calendar.today')}</TodayButton>}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledContainer>
  );
});
