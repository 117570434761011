import { useState } from 'react';
import './PetGalleryPhoto.scss';

type Props = {
    photo?: string
};

// tslint:disable-next-line:typedef
export function PetGalleryPhoto(props: Props) {
    const { photo } = props;
    const [isScaled, setIsScaled] = useState(false);
    const toggleScalePhoto = (): void => {
        !isScaled ? setIsScaled(true) : setIsScaled(false);
    };

    return (
        <div className='PetGalleryPhoto' onClick={toggleScalePhoto}>
            {photo && (
                <img src={photo} alt='gallery item' />
            )}
            {isScaled && (
                <div className='PetGalleryPhoto-scaled'>
                    <div className='scaled-photo-wrapper'>
                        {photo && (
                            <img src={photo} alt='gallery item' />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
