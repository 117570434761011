import type { HandlersData, TDeleteEvent } from '../types';
import { RequestState } from '../../../../utils/enums';
import { remove } from '../../../../utils/services';
import { MouseEventHandler, useCallback, useState } from 'react';

import { PetProfileRoutePath } from '../utils/enums/routes-path';
import delete_pet_icon from '../../../../assets/images/dashboard/delete_pet_icon.svg';
import { getRequestKey } from '../helpFunctions';
import { notificationSelector } from '../../../../utils/selectors';
import { popupState } from '../../shared/components/Popup/utils/atoms/popup';
import { useHistory } from 'react-router';
import { useIsMounted } from '../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokenState } from '../../../../utils/atoms';

export function usePetEventHandlers({
  eventId,
  keyInfo,
  setNeedToCheckData,
  date,
  previousLocation,
  petId,
  eventType,
  currentDay,
  setNeedToCheckMonthData,
  isLastEvent,
}: HandlersData) {
  const [, popupStateUpdate] = useRecoilState(popupState);
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const themeBlue = theme.palette.info.dark;
  const themeRed = theme.palette.error.main;
  const isMounted = useIsMounted();
  const [deleteStatus, setDeleteStatus] = useState<RequestState>(RequestState.Idle);
  const [deleteData, setDeleteData] = useState<string>('');
  const [, addNotification] = useRecoilState(notificationSelector);
  const [token] = useRecoilState(tokenState)

  const deleteEvent = useCallback(
    async ({ eventId, keyInfo, setCurrentEventsList, date }: TDeleteEvent) => {
      try {
        const requestKey = getRequestKey(keyInfo, 'deleteOne');
        const response = await remove(requestKey, eventId, token);

        if (!isMounted() || !response) {
          setDeleteStatus(RequestState.Idle);
          return;
        }
        if (response.error) {
          setDeleteStatus(RequestState.Error);
          addNotification({
            title: response.error.response?.status,
            text: response.error.response?.data,
            type: 'error',
          });
          throw Error(response.error);
        }
        setDeleteStatus(RequestState.Success);

        addNotification({
          title: t('PetProfilePage.notifications.successTitle'),
          text: '',
          type: 'success',
        });
        setDeleteData(response.data.statusText as string);
        
        if (setNeedToCheckData && currentDay) {
          setNeedToCheckData(true)
        }
        if(isLastEvent && setNeedToCheckMonthData) {
          setNeedToCheckMonthData(true)
        }
        return [deleteStatus, deleteData];
      } catch (err) {
        console.log('[error]:', err);
      }
    },
    [addNotification, currentDay, deleteData, deleteStatus, isLastEvent, isMounted, setNeedToCheckData, setNeedToCheckMonthData, t, token],
  );

  const handleDeleteEvent: MouseEventHandler<HTMLButtonElement> = useCallback((): void => {
    popupStateUpdate({
      popup: {
        text: `${t('PetProfilePage.notifications.deleteEvent')}`,
        icon: delete_pet_icon,
        actions: [
          {
            text: t('Contacts.placeholders.cancel'),
            color: themeBlue,
            onClick: () => {},
          },
          {
            text: t('Contacts.placeholders.yesDelete'),
            color: themeRed,
            onClick: () => {
              deleteEvent({ eventId, keyInfo, date });
            },
          },
        ],
      },
    });
  }, [date, deleteEvent, eventId, keyInfo, popupStateUpdate, t, themeBlue, themeRed]);

  const handleEditEvent = useCallback(() => {
    return;
  }, []);

  const onEventClickHandler = useCallback(() => {
    const typeArr = eventType.split('_');
    const path = `view_${typeArr[1].toLocaleLowerCase()}`;

    history.push(`${PetProfileRoutePath.PET}/${petId}/${path}/${eventId}`, { from: previousLocation, id: petId });
  }, [eventId, eventType, history, petId, previousLocation]);

  return { handleDeleteEvent, handleEditEvent, onEventClickHandler };
}
