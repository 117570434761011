import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { Formik } from 'formik';
import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import resendImg from '../../../../../../assets/images/resend.png';
import { RequestState } from '../../../../../../utils/enums';
import { PageLoader } from '../../../../shared';
import { AuthContent } from '../../../components/AuthContent/AuthContent';
import type { TNewPasswordForm } from '../../../types/types';
import { hidePhone } from '../../../utils/hooks/useHidePhone';
import { useForgotPassValidationSchema } from '../../../utils/validationSchemas/ForgotValidationSchema';
import { PhoneCode } from '../../VerifyPhoneCode/_components/PhoneCode/PhoneCode';
import { NewPasswordForm } from './components/NewPasswordForm';
import { useData } from './useData';
import { useHandlers } from './useHandlers';

export function NewPasswordPage(): ReactElement {
  const ForgotValidationSchema = useForgotPassValidationSchema();
  const { t } = useTranslation();
  const { phone_number, codeError, handleResend, handleSubmit, requestState, dispatch, codeDigit } = useHandlers();
  const { initialValues } = useData();
  const theme = useTheme();

  return (
    <>
      <AuthContent translationRootPath={'resetPassPage'} prevUrl={'/auth/forgot'} content={'reset-password'}>
        <Stack>
          <Stack marginBottom={1}>
            <Typography color={theme.palette.text.primary} fontFamily='Nunito' fontSize={16} fontWeight={400}>
              {t('resetPassPage.subtitle')}
              {hidePhone(phone_number.current || '')}
            </Typography>
            <PhoneCode codeError={codeError} codeChange={dispatch} />
            <Typography
              color={theme.palette.text.primary}
              fontFamily='Nunito'
              fontSize={16}
              fontWeight={400}
              textAlign='center'
              marginTop={1}>
              {t('resetPassPage.resendMessage')}
            </Typography>
            <Button onClick={handleResend} variant='secondaryOutlined'>
              <img src={resendImg} />
              <Typography color={theme.palette.text.primary} fontFamily='Nunito' fontSize={16} fontWeight={600}>
                {t('resetPassPage.resend')}
              </Typography>
            </Button>
          </Stack>
          <Box marginTop={3.2}>
            <Formik<TNewPasswordForm>
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={ForgotValidationSchema}>
              <NewPasswordForm
                state={requestState}
                codeState={Object.values(codeDigit).join('').length === 6}></NewPasswordForm>
            </Formik>
          </Box>
        </Stack>
      </AuthContent>
      {requestState === RequestState.Pending && <PageLoader />}
    </>
  );
}
