import { useRecoilState } from 'recoil';
import { popupState } from '../../Popup/utils/atoms/popup';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import loosing_data_icon from '../../../../../../assets/images/popUp_loosing_data_icon.svg';

export function useShowPopUp() {
  const [, popupStateUpdate] = useRecoilState(popupState);
  const { t } = useTranslation();
  const theme = useTheme();

  function showPopUp({ goBackCallback }: { goBackCallback: () => void }) {
    popupStateUpdate({
      popup: {
        text: `${t('PopUp.loosingData')}`,
        icon: loosing_data_icon,
        actions: [
          {
            text: t('PopUp.cancel'),
            color: theme.palette.error.light,
            onClick: () => {},
          },
          {
            text: t('PopUp.continue'),
            color: theme.palette.warning.light,
            onClick: () => goBackCallback(),
          },
        ],
      },
    });
  }

  return { showPopUp };
}
