import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  radioButtonGroupLabel: {
    fontFamily: 'Nunito-Regular, sans-serif',
    color: '#3f414e',
    fontSize: 16,
    lineHeight: '22px',
    marginBottom: 3,
  },
  radioButtonGroupRadioLabel: {
    minWidth: '115px',
    marginRight: 55,
    fontFamily: 'Nunito-Regular, sans-serif',
    color: '#3f414e',
    fontSize: 16,
    lineHeight: '22px',
    marginBottom: -5,
  },
  radioButtonGroupContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '14px 20px',
  },
  radioButtonGroupRadio: {
    color: '#3F414E',

    '&.Mui-checked': {
      color: '#47CFDE',
    },
  },
  petPageFormRadioButtonGreen: {
    color: '#88df3e',
    '&.Mui-checked': {
      color: '#88df3e',
    },
  },
  petPageFormRadioButtonRed: {
    color: '#ff1500',

    '&.Mui-checked': {
      color: '#ff1500',
    },
  },
  radioButtonGroupContainerInner: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  radioButtonGroupContainerInnerInColumn: {
    display: 'flex',
    flexDirection: 'column',
    // flexWrap: 'wrap',
  },
  radioButtonGroupPostComponent: {
    marginTop: 10,
    marginBottom: 10,
    width: '320px',
  },
  radioButton: {
    marginBottom: -7,
    '& .MuiButtonBase-root': {
      padding: '9px',
    },
  },
  '@media (max-height: 700px)': {
    radioButton: {
      minWidth: '150px',
    },
    radioButtonGroupRadioLabel: {
      marginRight: 35,
    },
  },
}));
