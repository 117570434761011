import React, {Fragment, ReactElement, useState} from 'react';
import {useErrorHandler} from 'react-error-boundary';
import {Redirect} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import User from '../../../../../../assets/images/chat/2.png';
import logo from '../../../../../../assets/images/chat/empty.png';
import standard from '../../../../../../assets/images/default_avatar.png';
import { getTime } from '../../../../../../utils/hooks/getTime';
import './DialogPage.scss';

export const DialogPage = (): ReactElement => {
    const [redirect, setRedirect] = useState('');
    const location = useLocation();
    const handleError = useErrorHandler();
    // @ts-ignore
    const {user} = location.state;
    const [userMessage, setUserMessage] = useState('');
    // const [messagesDate, setMessagesDate] = useState(0);
    const goBack = (): void => {
        setRedirect((): string => '/chat/main');
    };
    // tslint:disable-next-line:no-any
    const unreadMessagesId: any[] = [];
    const zulipInit = require('zulip-js');
    const zulip_id = Number(localStorage.getItem('zulip_id') !== '' ? localStorage.getItem('zulip_id') : 0);
    const zulip_email = localStorage.getItem('zulip_email');
    const zulip_api_key = localStorage.getItem('zulip_api_key');
    const [messages, setMessages] = useState([
        {
            avatar_url: '',
            content: '',
            reactions: [],
            sender_id: 0,
            display_recipient: [{
                full_name: '',
                id: 0
            }],
            flags: [],
            sender_full_name: '',
            sender_email: '',
            timestamp: 0,
        }
    ]);
    const config = {
        username: zulip_email,
        apiKey: zulip_api_key,
        insecure: true,
        realm: 'https://api.pets.kruts.net:9089'
    };
    // tslint:disable-next-line:typedef
    const getMessage = async (id: string | number) => {
        const client = await zulipInit(config);
        const readParams = {
            anchor: 'newest',
            num_before: 100,
            num_after: 0,
            narrow: [
                {operator: 'pm-with', operand: [zulip_id, id]},
            ],
        };
        const addflag = {
            messages: unreadMessagesId,
            flag: 'read',
        };
        const messageList = await client.messages.retrieve(readParams);
        if (messageList.result === 'success') {
            const {messages} = messageList;
            // tslint:disable-next-line:no-any typedef
            messages.map((message: any) => (!message.flags[0] && message.flags[0] !== 'read' && message.sender_id !== zulip_id && unreadMessagesId.push(Number(message.id))));
            if (unreadMessagesId.length !== 0) {
                await client.messages.flags.add(addflag);
            }
            setMessages(messages);
        }
        // @ts-ignore
        document.getElementById('message-container')?.scroll(0, document.getElementById('message-container').scrollHeight);

    };
    /*
     const editMessage = async (id: number): Promise<void> => {

         const client = await zulipInit(config);

     //     // Update a message with the given "message_id"
     //     const params = {
     //         message_id: id,
     //         content: 'New Content',
     //     };

         console.log(await client.messages.update(params));
     };*/
    // tslint:disable-next-line:typedef
    useState(() => {
        try {
            getMessage(user.zulip_id).then((): void => {
                // tslint:disable-next-line:typedef
            }, error => handleError(error));
        } catch (e) {
            console.log(e);
        }
    });
    // const compareMessagesTimestamp = (t: number): boolean => {
    //     if (getTime(t) !== getTime(messagesDate)) {
    //         return true;
    //     } else {
    //         setMessagesDate(t);
    //         return false;
    //     }
    // };
    // tslint:disable-next-line:typedef
    const createMessage = (e: {
        preventDefault: () => void;
        target: { value: React.SetStateAction<string>; };
    }) => {
        e.preventDefault();
        setUserMessage(e.target.value);
        document.getElementById('message-container')?.scroll(0, document.documentElement.scrollHeight);

    };
    // tslint:disable-next-line:typedef
    const sendMessage = async () => {
        if (userMessage !== '') {
            const client = await zulipInit(config);
            const params = {
                to: [user.zulip_id],
                type: 'private',
                content: userMessage,
            };
            await client.messages.send(params);
            setUserMessage('');
            getMessage(user.zulip_id).then((): void => {
                // tslint:disable-next-line:typedef
            }, error => handleError(error));
        }
    };
    if (redirect) {
        return <Redirect to={redirect} push/>;
    }
    // @ts-ignore
    document.getElementById('message-container')?.scroll(0, document.getElementById('message-container').scrollHeight);
    return (
        <div className="personal-dialog-container"
             onLoad={(): void => document.getElementById('message-container')?.scrollIntoView({block: 'end'})}>
            <div className="personal-dialog-header">
                <button className="btn-back" onClick={goBack}>
                </button>
                <div className="personal-dialog-information">
                    <div className="personal-dialog-name">{`${user.first_name} ${user.last_name}`}</div>
                    <span className="personal-dialog-status">online</span>
                </div>
                <img src={user.avatar !== null ? user.avatar : standard} alt="avatar"/>
            </div>
            <div id={'message-container'} className="message-container">
                {/* tslint:disable-next-line:no-any typedef */}
                {messages.length !== 0 ? (messages.map((message: any, index: number) => (
                    <Fragment key={index}>
                        {/*{compareMessagesTimestamp(message.timestamp) && (*/}
                        {/*    <div className='personal-dialog-date'>*/}
                        {/*        <span className='personal-dialog-date-day'>4</span> <span*/}
                        {/*        className='personal-dialog-date-month'>{getTime(message.timestamp)}</span>*/}
                        {/*    </div>*/}
                        {/*)}*/}
                        <div
                            className={`message-item ${message.sender_id !== user.zulip_id ? 'right' : 'left'}${message.flags[0] === 'read' ? ' readed' : ''}`}>
                            <div className="message-item-container">
                                <div className="message-item-content">
                                    <div className="message-item-text">
                                        {message.content.replace('<p>', '').replace('</p>', '')}
                                    </div>
                                    <div className="message-item-time">
                                        <span>{getTime(message.timestamp)}</span>
                                        {message.edit_history && (<span>{' edited'}</span>)}
                                    </div>
                                </div>
                            </div>
                            <div className="message-item-avatar">
                                <img
                                    src={message.sender_id !== user.zulip_id ? User : (user.avatar !== null ? user.avatar : standard)}
                                    alt=""/>
                            </div>
                        </div>
                    </Fragment>
                ))) : (<div className="empty">
                    <img src={logo} alt=""/>
                    <p>You don`t have message yet. Write and say hello </p>
                </div>)}
            </div>
            <div className="personal-dialog-controls">
                <div className="attach"/>
                <input
                    id="message-field"
                    name="message-field"
                    className="message-field-input"
                    type="text"
                    autoComplete="off"
                    value={userMessage}
                    onChange={createMessage}
                    data-id="message-field"/>
                <div className="send" onClick={sendMessage}/>
            </div>
        </div>

    );
};
