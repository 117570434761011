import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { type ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useRecoilState } from 'recoil';
import PopupButton from './components/PopupButton/PopupButton';
import { popupState } from './utils/atoms/popup';

export function Popup(): ReactElement {
  const [state, setState] = useRecoilState<Popup.TPopupState>(popupState);
  const history = useHistory();
  const handleClose = (): void => {
    setState({ popup: null });
  };
  const handleClick = (action: () => void): void => {
    action();
    handleClose();
  };
  useEffect((): void => {
    if (state.popup) {
      history.listen((location): void => {
        if (state.popup) {
          handleClose();
        }
      });
    }
  }, [handleClose, history, state]);

  return (
    <Dialog
      className='popup'
      open={!!state.popup}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{
        style: {
          borderRadius: '28px ',
          alignItems: 'center',
          margin: 0,
          maxWidth: '385px',
          width: '100%',
          textAlign: 'center',
        },
      }}>
      <DialogTitle id='alert-dialog-title'>
        {state.popup?.icon ? <img src={state.popup?.icon} alt='delete' /> : null}
      </DialogTitle>
      <DialogContent>
        {state.popup?.title}
        <DialogContentText id='alert-dialog-description'>{state.popup?.text}</DialogContentText>
      </DialogContent>
      <DialogActions sx={{ width: '100%' }}>
        {state.popup?.actions?.map((action, id): ReactElement => {
          return <PopupButton key={id} action={action} handleClick={handleClick} />;
        })}
      </DialogActions>
    </Dialog>
  );
}
