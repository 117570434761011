import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

export function useGoBack(previousUrl?: string, clearContent?: () => void): () => void {
  const history = useHistory();
  const goBack = useCallback(() => {
    if (clearContent) {
      clearContent();
    }
    if (previousUrl) {
      history.push(previousUrl);
    } else {
      history.goBack();
    }
  }, [clearContent, history, previousUrl]);

  return goBack;
}
