import { useCallback, useEffect, useState } from 'react';
import { get } from '../../../../utils/services/request';
import { useRecoilState } from 'recoil';
import { notificationSelector } from '../../../../utils/selectors/notification';
import { useTranslation } from 'react-i18next';
import { useErrorHandler } from 'react-error-boundary';
import { useIsMounted } from '../../../../utils/hooks';
import { RequestState, NotificationType } from '../../../../utils/enums';
import { tokenState } from '../../../../utils/atoms';

export function useFetchPetData(petId: string) {
  const [token] = useRecoilState(tokenState)
  const [, addNotification] = useRecoilState(notificationSelector);
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const isMounted = useIsMounted();
  const [petData, setPetData] = useState(null);
  const [requestState, setRequestState] = useState(RequestState.Idle);

  const fetchPetData = useCallback(
    async (petId: string) => {
      if (!token) return;

      setRequestState(RequestState.Pending);
      const { data, error } = await get('GET_PET_INFO', { pet_id: petId }, '', token);

      if (!isMounted()) return;

      switch (true) {
        case !!data:
          setPetData(data);
          setRequestState(RequestState.Success);
          break;
        case !!error:
          error.response.status === 400
            ? addNotification({
                title: error.response.status,
                text: t('appErrorNotification.trouble'),
                type: NotificationType.Error,
              })
            : handleError(error);
          setRequestState(RequestState.Error);
          break;
        default:
          setRequestState(RequestState.Idle);
          break;
      }
    },
    [token, isMounted, addNotification, t, handleError],
  );

  useEffect(() => {
    if (petId) {
      fetchPetData(petId);
    }
  }, [fetchPetData, petId]);

  return { petData, requestState, fetchPetData };
}
