import { AM_TIME, PM_TIME } from '../../../../utils/const/date';

export function convertTimeFormat(inputTime: string) {
  const formattedTimes: string[] = [];

  const inputTimeArray = inputTime.split(' | ');

  inputTimeArray.forEach(time => {
    const [hourStr, minuteStr] = time.split(':');
    const hour = parseInt(hourStr, 10);
    const minute = parseInt(minuteStr, 10);

    const period = hour >= 12 ? PM_TIME : AM_TIME;

    const formattedHour = hour % 12 || 12;

    const formattedTime = `${formattedHour}:${minute.toString().padStart(2, '0')}${period}`;
    formattedTimes.push(formattedTime);
  });

  return formattedTimes.join(' | ');
}
