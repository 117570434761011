import {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';
import api from './api';

const API_URL = 'https://api.pets.kruts.net/api/v1';
const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const access = localStorage.getItem('access');
    config.headers['Authorization'] = `JWT ${access}`;
    return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
    return response;
};

// @ts-ignore
// tslint:disable-next-line:cyclomatic-complexity
const onResponseError = async (error: AxiosError): Promise<AxiosError> => {

    if (error.response) {
        if (error.response.status === 401 && error.response.data.code === 'refresh_token_not_valid') {
            localStorage.clear();
            // eslint-disable-next-line no-restricted-globals
            if (!!(window.history && history.replaceState)) {
                window.history.go();
            } else {
                document.location.reload();
            }
        } else if (
            error.response.status === 401 &&
            error.response.data.code === 'token_not_valid'
        ) {
            // @ts-ignore
            const storedToken = localStorage.getItem('refresh');
            localStorage.removeItem('refresh');
            const rs = await api.post(`${API_URL}/auth/jwt/refresh`, {
                refresh: storedToken,
            });
            const {access, refresh} = rs.data;
            localStorage.setItem('access', access);
            localStorage.setItem('refresh', refresh);

            // eslint-disable-next-line no-restricted-globals
            // if (!!(window.history && history.replaceState)) {
            //     window.history.go();
            // } else {
            //     document.location.reload();
            // }
        }
    }
    return Promise.reject(error);
};

export const setupInterceptorsTo = (
    axiosInstance: AxiosInstance
): AxiosInstance => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
};
