import React from 'react';
import type {ReactElement} from 'react';
import {useHistory} from 'react-router-dom';
import './PolicyPage.scss';

export const PolicyPage = (): ReactElement => {
    const history = useHistory();
    const goBack = (): void => {
        localStorage.getItem('google_auth') ? history.push('/auth/phoneverify') : history.push('/auth/signup');

    };
    return (
        <>
            <div className='policy-content'>
                <div className={'header'}>
                    <button className='btn-back' onClick={goBack}>
                    </button>
                    <div className='title'>Private policy</div>
                </div>
            </div>
        </>
    );
};
