import { Loader, WithFooterMenu } from '../../../shared';
import { useData, useHandleClick } from './hooks';

import Container from '@mui/material/Container';
import { HeaderWithSearch } from '../../components/HeaderWithSearch';
import type { PetEventDetails } from '../../types';
import { PetSimilarEventContainer } from '../../components/PetSimilarEventContainer/PetSimilarEventContainer';
import { RequestState } from '../../../../../utils/enums';
import { useFetchOneTypeEventsDataOnMount } from '../../hooks/useFetchOneTypeEventsDataOnMount';
import { useParams } from 'react-router-dom';

type Props = {
  eventDetails: PetEventDetails;
};
// Page "Medical Records"
export function PetOneTypeEvents({ eventDetails }: Props) {
  const params = useParams<{ petId: string }>();
  const petId = params.petId;
  const { title, requestKey, altText, requestData } = useData({ type: eventDetails.type, petId });
  const { handleAddEvent } = useHandleClick({
    pathForAdd: eventDetails.pathForAdd,
    pathForBack: eventDetails.pathForBack,
    petId,
  });

  const [requestStatus, fetchedEventsData] = useFetchOneTypeEventsDataOnMount({ requestKey, requestData });
  return (
    <WithFooterMenu>
      {requestStatus === RequestState.Success ? (
        <>
          <HeaderWithSearch title={title} path={`/pet/${petId}/main/medical`}>
            <button className='add-event' onClick={handleAddEvent}>
              <img src={eventDetails.buttonIcon} alt={altText} />
            </button>
          </HeaderWithSearch>
          <Container>
            <PetSimilarEventContainer eventsList={fetchedEventsData} eventDetails={eventDetails} type={requestKey} />
          </Container>
        </>
      ) : (
        <Loader />
      )}
    </WithFooterMenu>
  );
}
