import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AbsoluteRoutes, RequestState } from '../../../../../../utils/enums';

export function useCheckLoggedInOnMount(
  tokenVerificationState: RequestState,
  tokenVerified: boolean,
  phoneVerified: boolean,
): void {
  const history = useHistory();
  useEffect((): void => {
    switch (true) {
      case tokenVerificationState === RequestState.Success && tokenVerified && phoneVerified:
        history.push(AbsoluteRoutes.Dashboard);
        break;
      case tokenVerificationState === RequestState.Idle ||
        tokenVerificationState === RequestState.Pending ||
        (tokenVerificationState === RequestState.Success && (!phoneVerified || !tokenVerified)):
        break;
      case tokenVerificationState === RequestState.Error:
        localStorage.clear();
        break;
      default:
        throw Error(`Unsupported case: ${tokenVerificationState} - ${tokenVerified}`);
    }
  }, [tokenVerificationState, tokenVerified, phoneVerified, history]);
}
