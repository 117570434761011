import { Field, useFormikContext } from 'formik';
import type { ReactElement } from 'react';
import { CustomInput } from '../../../../shared';
import { PetEventDetailsProps } from '../../../types';

export function PetFormikInputArray({ eventDetails, ...props }: PetEventDetailsProps): ReactElement {
  const { setFieldValue, errors, touched } = useFormikContext<any>();
  return (
    <div>
      {eventDetails.addPageInputFields?.map((inputField: any, index: number) => {
        if (!eventDetails.addPageInputFields) {
          return <></>;
        }
        const { fieldName: name, title, required } = eventDetails.addPageInputFields[index];
        const clear = () => {
          setFieldValue(name, '');
        };
        return (
          <Field
            {...props}
            component={CustomInput}
            name={name}
            required={required || false}
            key={index}
            label={title}
            clear={clear}
            errors={touched[name] && errors[name]}
            errorType={'transparent'}
          />
        );
      })}
    </div>
  );
}
