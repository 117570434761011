import React, { type ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import default_avatar from '../../../../../assets/images/dashboard_avatar.svg';
import default_pet_avatar from '../../../../../assets/images/pet_default.svg';
import { PageLoader } from '../PageLoader/PageLoader';
import './DashboardCard.scss';

// tslint:disable-next-line:no-any cyclomatic-complexity
export function DashboardCard(props: { obj: any }): ReactElement {
  const history = useHistory();
  const [loader, setLoader] = useState(true);
  const [path, setPath] = useState('');
  const { obj } = props;

  useEffect((): void => {
    obj ? setLoader(false) : setLoader(true);
  }, [obj]);
  useEffect((): void => {
    if (obj) {
      setPath(obj.hasOwnProperty('name') ? '/pet/main' : '/profile/main');
    }
  }, [obj]);

  const handleClick = (): void => {
    if (obj) {
      history.push({
        pathname: path,
        state: { obj },
      });
    }
  };
  return (
    <>
      {obj && (
        <div className={`dashboardCard ${obj?.avatar ? '' : 'default_container'}`} onClick={handleClick}>
          {obj?.avatar ? (
            <img src={obj?.avatar} alt='avatar' />
          ) : obj.hasOwnProperty('name') ? (
            <img className={'default pet'} src={default_pet_avatar} alt='avatar' />
          ) : (
            <img className={'default user'} src={default_avatar} alt='avatar' />
          )}
          {obj.hasOwnProperty('name') ? (
            <div className={'dashboardCard_title'}>
              <span>{obj.name}</span>
            </div>
          ) : (
            <div className={'dashboardCard_title'}>
              <span>{obj?.first_name ? obj?.first_name : obj?.username}</span>
              <span>{obj?.last_name}</span>
            </div>
          )}
        </div>
      )}
      {loader && <PageLoader />}
    </>
  );
}
