import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material';
import React, { type ReactElement, useState } from 'react';
import closePass from '../../../../../../assets/images/close_pass.svg';
import openPass from '../../../../../../assets/images/open_pass.svg';
import { CustomInput } from '../CustomInput/CustomInput';

const StyledEyeIcon = styled('img')({
  marginRight: '-6px', 
});

export function SecureInput(props: any): ReactElement {
  const { field } = props;
  const [passwordType, setPasswordType] = useState('password');
  const handlePasswordType = (): void => {
    if (field.value) {
      if (passwordType === 'password') {
        setPasswordType('text');
        return;
      }
      setPasswordType('password');
    }
  };

  return (
    <CustomInput
      {...props}
      type={passwordType}
      icons-end={[
        <IconButton onClick={handlePasswordType} edge='end'>
          {field.value ? (
            passwordType === 'text' ? (
              <StyledEyeIcon src={openPass} alt='open'/>
            ) : (
              <StyledEyeIcon src={closePass} alt='close'/>
            )
          ) : (
            <img src={closePass} alt='close' />
          )}
        </IconButton>,
      ]}
    />
  );
}
