import type { PetEventInfoProps, PetFormSelectField, PetFormTextField } from '../../types';

import { useDateFunction } from '../../hooks';
import { MultilineField, OneLineField } from '../../../shared/components/TextField';

export function PetEventInfo({ eventData, eventDetails }: PetEventInfoProps): JSX.Element {
  const { title: dateTitle, required: dateRequired } = eventDetails.viewDateField;

  const { getFormattedDateWithTime } = useDateFunction({ initialDate: eventData.event_date });
  const dateValue = getFormattedDateWithTime();
  return (
    <>
      {eventDetails.addPageSelectFields
        ? eventDetails.addPageSelectFields.map(
            ({ title, required, options }: PetFormSelectField, index: number) => {
              const value = options.filter((option) => option.id === eventData.allergy_type);
              return <OneLineField label={title} value={value[0]?.name ?? ''} required={required || false} key={index} />;
            },
          )
        : null}
      {eventDetails.addPageInputFields
        ? eventDetails.addPageInputFields.map(
            ({ title, required, requestFieldName }: PetFormTextField, index: number) => {
              const value = eventData[requestFieldName];
              return <OneLineField label={title} value={value ?? ''} required={!!required || false} key={index} />;
            },
          )
        : null}
      <OneLineField label={dateTitle} value={dateValue ?? ''} required={dateRequired} />
      {eventDetails.addPageTextareaFields
        ? eventDetails.addPageTextareaFields.map(
            ({ title, required, requestFieldName }: PetFormTextField, index: number) => {
              const value = eventData[requestFieldName];
              return <MultilineField label={title} value={value ?? ''} required={!!required || false} key={index} />;
            },
          )
        : null}
    </>
  );
}
