import {PetGalleryDateBlock} from '../PetGalleryDateBlock';
import './PetGallery.scss';

// tslint:disable-next-line:typedef
export function PetGallery() {
    return (
        <section className='pet-gallery'>
            <div className='pet-gallery-content'>
                <PetGalleryDateBlock date={5} month='липня' withAddButton photosForThatDate={['', '', '', '']}/>
                <PetGalleryDateBlock date={6} month='липня' photosForThatDate={['', '', '', '', '']}/>
                <PetGalleryDateBlock date={7} month='липня' photosForThatDate={['', '', '', '', '']}/>
                <PetGalleryDateBlock date={8} month='липня' photosForThatDate={['', '', '', '', '']}/>
            </div>
        </section>
    );
}
