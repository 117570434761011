import { Box, Checkbox, FormControlLabel, useTheme } from '@mui/material';
import React, { type ReactElement } from 'react';
import checked from '../../../../../assets/images/checked.png';
import { useStyles } from './styles';

export function CustomCheckbox({ ...props }): ReactElement {
  const { label } = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <FormControlLabel
      label={label}
      {...props}
      className={classes.root}
      control={
        <Checkbox
          icon={<Box border='1px solid white' borderRadius='0.25em' height='20px' width='20px' />}
          checkedIcon={
            <Box
              alignItems='center'
              borderRadius='0.25em'
              border='1px solid white'
              display='flex'
              height='20px'
              justifyContent='center'
              width='20px'
              bgcolor={theme.palette.text.primary}>
              <img height='50%' src={checked} />
            </Box>
          }
        />
      }
    />
  );
}
