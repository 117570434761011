import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';

// tslint:disable-next-line:typedef
export const useSignupValidationSchema = () => {
    const {t} = useTranslation();
    // tslint:disable-next-line:typedef
    return useMemo(() => {
        return Yup.object().shape({
            phone: Yup.string()
                .required(t('validations.required'))
                .min(8, t('validations.min'))
                .matches(/^([0-9])/, t('validations.phone_number')),
            name: Yup.string()
                .required(t('validations.required'))
                .min(2, t('validations.min'))
                .matches(/^[A-Za-zа-яА-ЯіІїЇёЁъЪЫыєЄґҐ]+([-\s'`_][A-Za-zа-яА-ЯіІїЇёЁъЪЫыєЄґҐ]+)*$/, t('validations.name')),
            password: Yup.string()
                .required(t('validations.required'))
                .min(7, t('validations.min'))
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?!.*[а-яА-ЯіІїЇъЪЫыєЄёЁґҐ])(?=.{7,})/, t('validations.password')),
            re_password: Yup.string()
                .required(t('validations.required'))
                .oneOf([Yup.ref('password')], t('validations.confirmPassword')),
            email: Yup.string().email(t('validations.email')).matches(/^(?!.*[а-яА-ЯіІїЇёЁъЪЫыєЄґҐ])/, t('validations.email')),
            code: Yup.string()
                .required(t('validations.code')),
            recaptcha_token: Yup.string()
                .required(t('validations.required')),
            terms: Yup.boolean().oneOf([true], 'Field must be checked')
        });
    }, [t]);
};
