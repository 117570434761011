import { useTranslation } from 'react-i18next';
import type { TPetInfo } from '../../../types';
import type { UseDataReturnValue } from './types';
import { useEffect, useMemo } from 'react';
import formatDateByLocale from '../../../../../../utils/helpers/formatDateByLocale';
import { useFetchBreed, useData as usePetData } from '../../../hooks';
import { ALL_BREED_LIMIT, NO_CONTENT_CLASS } from '../constants';
import { LocalStorageService } from '../../../../../../utils/services';
import { Language, LocalStorageKeys } from '../../../../../../utils/enums';
import square_default_pet_avatar from '../../../../../../assets/images/pet/square_default_pet_avatar.svg';
import { USER_LOCALE } from '../../../../../../utils/const';

export function useData({ petData }: { petData: TPetInfo }): UseDataReturnValue {
  const { avatar, name, kind, birth, gender, breed } = petData;
  const { t } = useTranslation();
  const {breeds, fetchBreeds, loading: isLoading} = useFetchBreed(kind);
  const { KIND_SELECT_OPTIONS } = usePetData();

  const language = LocalStorageService.getItem(LocalStorageKeys.Language);

  const formattedBirth = useMemo(() => {
    return birth && formatDateByLocale(birth, USER_LOCALE).replaceAll('-', '.');
  }, [birth]);

  const kindNameById = useMemo(() => {
    const kindItem = KIND_SELECT_OPTIONS.find(item => item.id === kind);
    return kindItem && t(kindItem.i18KeyName);
  }, [KIND_SELECT_OPTIONS, kind, t]);

  useEffect(() => {
    fetchBreeds(kind, 0, ALL_BREED_LIMIT);
  }, [fetchBreeds, kind]);

  const breedById = useMemo(() => {
    return breeds.find(item => item.id === breed);
  }, [breeds, breed]);

  const genderHasContentClass = useMemo(() => {
    return gender ? '' : NO_CONTENT_CLASS;
  }, [gender]);

  const breedHasContentClass = useMemo(() => {
    return breedById ? '' : NO_CONTENT_CLASS;
  }, [breedById]);

  const genderFieldValue = useMemo(() => {
    return gender ? t(`petForm.${gender}`) : '\u2014';
  }, [gender, t]);

  const breedFieldValue = useMemo(() => {
    return breedById
      ? language === Language.Uk || language === Language.Ru
        ? breedById.breed_name_ua
        : breedById.breed_name_en
      : '\u2014';
  }, [breedById, language]);

  const avatarSrc = useMemo(() => {
    return avatar || square_default_pet_avatar;
  }, [avatar]);

  return {
    avatarSrc,
    name,
    kindNameById,
    formattedBirth,
    genderHasContentClass,
    breedHasContentClass,
    genderFieldValue,
    breedFieldValue,
    isLoading,
  };
}
