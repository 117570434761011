import { IconButton, Paper, Stack, styled, Typography } from '@mui/material';
import { Field, Form, useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import cities from '../../../../../../assets/i18n/cities.json';
import saveIcon from '../../../../../../assets/images/save_icon.svg';
import { RequestState } from '../../../../../../utils/enums';
import { useGoBack } from '../../../../../../utils/hooks';
import useGetUser from '../../../../../../utils/hooks/useGetUser';
import { BackButton, CustomDateInput, CustomInput, FormAvatar, PageLoader, RadioButtonGroup } from '../../../../shared';
import { CustomSearchSelect } from '../../../../shared/components/CustomSearchSelect/CustomSearchSelect';
import type { TCityData, TEditProfileForm } from '../../../utils/types/types';
import { useData } from './useData';
import { useHandlers } from './useHandlers';

const USER_LOCALE = navigator.language;

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'left',
  fontFamily: 'Nunito, sans-serif',
  height: '50px',
  fontSize: '16px',
  width: '100%',
  lineHeight: '21.82px',
  color: '#3F414E',
  boxShadow: 'none',
}));
export const EditPageForm = ({ requestStatus }: { requestStatus: RequestState }): ReactElement => {
  const goBack = useGoBack();
  const { errors, touched, setFieldValue, values, setValues } = useFormikContext<TEditProfileForm>();
  const { handleClear, saveDate, searchMethodSearchSelect, checkSelectedSearchSelect, clickOptionSearchSelect } =
    useHandlers({ values });
  const { t } = useTranslation();
  const { userStatus, userData } = useGetUser();
  const { radioButtonGroupOptions, getValueSearchSelect } = useData({
    values,
  });

  useEffect((): void => {
    userData().then((result): void => {
      for (const [key] of Object.entries(values)) {
        if (result.data[key]) {
          setFieldValue(key, result.data[key]);
        }
      }
    });
  }, [setFieldValue, setValues, userData]);

  const renderOptionSearchSelect = useCallback((city: TCityData): ReactElement => {
    // @ts-ignore
    return <Item>{city[`name_${USER_LOCALE}`]}</Item>;
  }, []);

  return (
    <Form>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height='8vh'
        padding='0.63rem 1rem 0.63rem 1rem'
        boxShadow='2px 2px 16px 0px rgba(0, 0, 0, 0.1)'>
        <BackButton goBack={goBack} />
        <Typography
          fontFamily='Nunito-Bold, sans-serif'
          fontWeight='700'
          maxWidth='46vw'
          lineHeight='22px'
          marginTop=' 0.5rem'>
          {t('EditPage.title')}
        </Typography>
        <IconButton className='confirm-button' type='submit'>
          <img src={saveIcon} alt='' />
        </IconButton>
      </Stack>
      <Stack alignItems='center' gap='24px' padding='0rem 1rem 1rem' overflow='auto' height='90vh'>
        <Field name='avatar' component={FormAvatar} alt='User' />
        <Field
          component={CustomInput}
          name='first_name'
          id='first_name'
          type='text'
          clear={handleClear}
          errors={touched.first_name && errors.first_name}
          data-id='first_name'
          label={t('EditPage.placeholders.first_name')}
          errorType={'transparent'}
        />
        <Field
          component={CustomInput}
          name='last_name'
          id='last_name'
          type='text'
          clear={handleClear}
          errors={touched.last_name && errors.last_name}
          data-id='last_name'
          label={t('EditPage.placeholders.last_name')}
          errorType={'transparent'}
        />
        <Field
          name='birthday'
          component={CustomDateInput}
          label={t('EditPage.placeholders.birthday')}
          errors={touched.birthday && errors.birthday}
          errorType={'transparent'}
          onChange={saveDate}
          value={values.birthday}
          disableFuture
        />
        <Field name='gender' options={radioButtonGroupOptions} component={RadioButtonGroup} />
        <Field
          component={CustomInput}
          name='phone_number'
          type='text'
          className='phone_number'
          disabled={true}
          data-id='phone_number'
          label={t('EditPage.placeholders.phone_number')}
          InputProps={{ sx: { color: 'red' } }}
        />
        <Field
          id='email'
          component={CustomInput}
          name='email'
          clear={handleClear}
          errors={touched.email && errors.email}
          data-id='email'
          label={t('EditPage.placeholders.email')}
          errorType={'transparent'}
        />
        <CustomInput disabled value={t('EditPage.ukraine')} label={t('EditPage.placeholders.country')} />
        <Field
          component={CustomSearchSelect}
          label={t('EditPage.placeholders.city')}
          name='city'
          title={t('EditPage.placeholders.city')}
          options={cities.city.data}
          renderOptions={renderOptionSearchSelect}
          value={getValueSearchSelect}
          onClick={clickOptionSearchSelect}
          checkSelected={checkSelectedSearchSelect}
          searchMethod={searchMethodSearchSelect}
        />
        {requestStatus === RequestState.Error && <p className='alert'>{t('EditPage.errors.error')}</p>}
      </Stack>
      {(userStatus === RequestState.Pending || requestStatus === RequestState.Pending) && <PageLoader />}
    </Form>
  );
};
