import { Formik } from 'formik';
import React from 'react';
import type { ReactElement } from 'react';
import { RequestState } from '../../../../../utils/enums';
import { PageLoader } from '../../../shared';
import { AuthContent } from '../../components/AuthContent/AuthContent';
import type { TSignupForm } from '../../types/types';
import { useSignupValidationSchema } from '../../utils/validationSchemas/SignupValidationSchema';
import { SignupForm } from './components/SignupForm';
import { useData } from './useData';
import { useHandlers } from './useHandlers';

export const SignupPage = (): ReactElement => {
  const signupValidationSchema = useSignupValidationSchema();
  const { initialValues } = useData();
  const { handleSubmit, registerStatus } = useHandlers();

  return (
    <>
      <AuthContent translationRootPath={'signupPage'} prevUrl={'/start'} content={'signup'}>
        <Formik<TSignupForm>
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={signupValidationSchema}>
          <SignupForm requestState={registerStatus}></SignupForm>
        </Formik>
      </AuthContent>
      {registerStatus === RequestState.Pending ? <PageLoader /> : false}
    </>
  );
};
