import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { AppModules } from '../../../../../utils/const';
import { LocalStorageKeys, RequestState } from '../../../../../utils/enums';
import { LocalStorageService } from '../../../../../utils/services';
import type { TVerifyPhoneForm } from '../../types/types';
import { AuthRoutePath } from '../../utils/const/route-path';
import { usePhoneVerify } from '../../utils/hooks/usePhoneVerify';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../../utils/atoms';

export function useHandlers(): { requestState: RequestState; handleSubmit: (data: TVerifyPhoneForm) => void } {
  const [access] = useRecoilState(tokenState)
  const history = useHistory();
  const [requestState, onPhoneVerify] = usePhoneVerify();

  const handleSubmit = useCallback(
    async ({ phone, code, ...data }: TVerifyPhoneForm): Promise<void> => {
      const response = await onPhoneVerify({
        phone_number: `+${code}${phone}`,
        ...data,
        access,
        language: code === `380` ? 'uk' : 'en',
      });

      if (!!response.error) {
        return;
      }

      LocalStorageService.setItem(LocalStorageKeys.Code, code);
      LocalStorageService.setItem(LocalStorageKeys.Phone, phone);
      LocalStorageService.setItem(LocalStorageKeys.PhoneNumber, `+${code}${phone}`);
      history.push(`/${AppModules.auth}/${AuthRoutePath.CODE_VERIFY}`);
    },
    [access, history, onPhoneVerify],
  );

  return { requestState, handleSubmit };
}
