import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory } from 'react-router-dom';
import crown from '../../../../../assets/images/crown.png';
import avatar from '../../../../../assets/images/default_avatar.png';
import './Sidebar.scss';

// @ts-ignore
// tslint:disable-next-line:typedef cyclomatic-complexity
export function Sidebar({ status, setisOpen, user }): ReactElement {
  const { t } = useTranslation();
  const history = useHistory();
  const [language, setLanguage] = useState('');
  const obj = user;
  useEffect((): void => {
    const selectedLang = localStorage.getItem('language');
    if (selectedLang === 'en') {
      setLanguage('English');
    } else if (selectedLang === 'uk') {
      setLanguage('Українська');
    }
  }, [user]);
  const [redirect, setRedirect] = useState('');
  const logout = (): void => {
    localStorage.clear();
    window.location.replace('/');
  };
  const cropLongName = (longName: string): string => {
    if (longName.length > 15) {
      return `${longName.slice(0, 15)}...`;
    } else {
      return longName;
    }
  };
  if (redirect) {
    return <Redirect to={redirect} push />;
  }

  return (
    <>
      {status && (
        <div className='sidebar-container'>
          <div className='sidebar'>
            <div className='sidebar-header'>
              <img
                className='avatar'
                src={user?.avatar ? user?.avatar : avatar}
                onClick={(): void => {
                  history.push({
                    pathname: '/profile/main',
                    state: { obj },
                  });
                }}
                alt='avatar'
              />
              <p className='sidebar-name'>
                {user?.first_name || user?.last_name
                  ? `${cropLongName(user?.first_name)} ${cropLongName(user?.last_name)}`
                  : user?.username}
              </p>
              <p className='link' onClick={(): void => setRedirect((): string => '/profile/edit')}>
                {t('sidebar.edit')}
              </p>
            </div>
            <div className='sidebar-menu'>
              <div className='expanded' onClick={(): void => setRedirect((): string => '/profile/language')}>
                <img src={require('../../../../../assets/images/sidebar/lang.png').default} alt='' />
                <span className='button-anchor'>
                  <span>{t('sidebar.language')}</span> <span>{language}</span>
                </span>
              </div>
              <div className='expanded inactive'>
                <img src={require('../../../../../assets/images/sidebar/confident.png').default} alt='' />
                <span className='button-anchor'>
                  <span>{t('sidebar.confidentiality')}</span>
                  <span> {t('sidebar.confItem')}</span>
                </span>
              </div>
              <div className='inactive'>
                <img src={require('../../../../../assets/images/sidebar/theme.png').default} alt='' />
                <span className='button-anchor'>{t('sidebar.theme')}</span>
              </div>
              <div className='inactive'>
                <img src={require('../../../../../assets/images/sidebar/notifications.png').default} alt='' />
                <span className='button-anchor'>{t('sidebar.notifications')}</span>
              </div>
              <div className='inactive'>
                <img src={require('../../../../../assets/images/sidebar/map.png').default} alt='' />
                <span className='button-anchor'>{t('sidebar.map')}</span>
              </div>
              <div className='inactive'>
                <img src={require('../../../../../assets/images/sidebar/info.png').default} alt='' />
                <span className='button-anchor'>{t('sidebar.information')}</span>
              </div>
              <div className='inactive'>
                <img src={require('../../../../../assets/images/sidebar/help.png').default} alt='' />
                <span className='button-anchor'>{t('sidebar.help')}</span>
              </div>
              <div onClick={logout}>
                <img src={require('../../../../../assets/images/sidebar/logout.png').default} alt='' />
                <span className='button-anchor'>{t('sidebar.logout')}</span>
              </div>
            </div>
            <div className='sidebar-premium'>
              <img src={crown} alt='premium' />
              <p>{t('sidebar.getPremium')}</p>
            </div>
          </div>
          <div className='hollow' onClick={(): boolean => setisOpen(false)} />
        </div>
      )}
    </>
  );
}
