import React, { ReactElement, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Redirect } from 'react-router-dom';
import { useRecoilState } from 'recoil';
// import User1 from '../../../../../assets/images/chat/1.png';
import bin from '../../../../../assets/images/contacts/bin.png';
import change from '../../../../../assets/images/contacts/change.png';
import delete_contact from '../../../../../assets/images/contacts/delete-contact.png';
import new_icon from '../../../../../assets/images/contacts/new-button.png';
import unknown_icon from '../../../../../assets/images/contacts/unknown.png';
import standard from '../../../../../assets/images/default_avatar.png';
import { notificationSelector } from '../../../../../utils/selectors';
import api from '../../../../../utils/services/api';
import { SearchInput, WithFooterMenu } from '../../../shared';
import { popupState } from '../../../shared/components/Popup/utils/atoms/popup';
import useGetInvitationsFromMe from '../../utils/hooks/useGetInvitationsFromMe';
import useGetInvitationsToMe from '../../utils/hooks/useGetInvitationsToMe';
import useSearchFriends from '../../utils/hooks/useSearchFriends';
// import useSendResponseOnInv from '../../utils/hooks/useSendResponseOnInv';
import './ContactsPage.scss';
import { tokenState } from '../../../../../utils/atoms';

export function ContactsPage(): ReactElement {
  const [, addNotification] = useRecoilState(notificationSelector);
  const [, popupStateUpdate] = useRecoilState(popupState);
  const history = useHistory();
  const { t } = useTranslation();
  const handleError = useErrorHandler();
  const [, receivedInv] = useGetInvitationsToMe();
  const [, sendedInv] = useGetInvitationsFromMe();
  const [invToMe, setInvToMe] = useState([]);
  const [invFromMe, setInvFromMe] = useState([]);
  const [redirect, setRedirect] = useState('');
  const [, searchFriendsList] = useSearchFriends();
  const [friends, setFriends] = useState<any[]>();
  const [search, setSearch] = useState('');
  // const [requestStatus, sendResponseOnInv] = useSendResponseOnInv();
  const [token] = useRecoilState(tokenState)

  const goBack = (): void => {
    setRedirect((): string => '/dashboard/main');
  };
  /* const [error, setError] = useState({
        show: false,
    });
    */
  const [menu] = useState({
    show: false,
  });

  const addUser = (): void => {
    history.push('add');
    // setRedirect((): string => '/contacts/add');
  };
  useEffect((): void => {
    const searchFriends = async (): Promise<void> => {
      // @ts-ignore
      return await searchFriendsList(search);
    };
    // tslint:disable-next-line:typedef
    searchFriends().then(
      value => {
        // @ts-ignore
        setFriends(value.data);
        // tslint:disable-next-line:typedef
      },
      error => handleError(error),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  useEffect((): void => {
    const getInv = async (): Promise<void> => {
      // @ts-ignore
      return await receivedInv();
    };
    // tslint:disable-next-line:typedef
    getInv().then(
      value => {
        // @ts-ignore
        setInvToMe(value.data);
        // tslint:disable-next-line:typedef
      },
      error => handleError(error),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect((): void => {
    const getSendInv = async (): Promise<void> => {
      // @ts-ignore
      return await sendedInv();
    };
    // tslint:disable-next-line:typedef
    getSendInv().then(
      value => {
        // @ts-ignore
        setInvFromMe(value.data);
        // tslint:disable-next-line:typedef
      },
      error => handleError(error),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // tslint:disable-next-line:no-any typedef
  // const checkOnline = (time: any) => {
  //     const currentTime = new Date();
  //     const userActivity = new Date(time);
  //     const diff = (currentTime.getTime() - 10800000) - userActivity.getTime();
  //     return diff.toString() < '300000';
  // };
  const handleDelete = (id: string, phone: string): void => {
    popupStateUpdate({
      popup: {
        text: `${t('Are you sure that you want to delete')} \n ${phone} ${t('this contact?')}`,
        icon: delete_contact,
        actions: [
          {
            text: t('Contacts.placeholders.cancel'),
            color: '#47CFDE',
            onClick: (): void => {},
          },
          {
            text: t('Contacts.placeholders.yesDelete'),
            color: '#FF1500',
            onClick: (): void => {
              handleDeleteTrue(id, phone);
            },
          },
        ],
      },
    });
  };
  const handleDeleteTrue = (id: string, phone: string): void => {

    if (id) {
      // tslint:disable-next-line:typedef
      api.delete(`https://api.pets.kruts.net:443/api/v1/friends/${id}`, { headers: { Authorization: token } }).then(
        response => {
          if (response.status === 204) {
            addNotification({
              text: t('Contacts.deleteDescription'),
              type: 'success',
            });
            setSearch('');
          }
          // tslint:disable-next-line:typedef
        },
        error => {
          if (error.response?.status === 400) {
            addNotification({
              title: error.response.status,
              text: error.response.data,
              type: 'error',
            });
            return;
          }
          handleError(error);
        },
      );

      setSearch(' ');
    }
  };
  // tslint:disable-next-line:no-any
  const handleClick = (user: any): void => {
    history.push({
      pathname: '/chat/personal',
      state: { user },
    });
  };
  if (redirect) {
    return <Redirect to={redirect} push />;
  }
  return (
    <>
      <WithFooterMenu>
        <div className='main-contacts-container'>
          <div className='contacts-header'>
            <button className='btn-back' onClick={goBack}>
              <span>{t(`back`)}</span>
            </button>
            <h1>{t('Contacts.title')}</h1>
            <div className='add-button' onClick={addUser}>
              <p>{t('Contacts.addButton')}</p>
            </div>
          </div>
          <div className='contacts-container'>
            {/* <div className="custom-input">
                            <input id={'search'} type="text" name="search"
                                   placeholder={t('Contacts.placeholders.search')}
                                   value={search}
                                   autoComplete="off"
                                   onChange={(e): void => setSearch(e.target.value)}/>
                            {search &&
                                <span className="clear" onClick={(): void => {
                                    setTimeout((): void => document.getElementById('search')?.focus(), 200);
                                    setSearch('');
                                }}> </span>}
                            <span className="search_icon"> </span>
                        </div>
                        */}
            <SearchInput
              label={t('Contacts.placeholders.search')}
              value={search}
              onChange={(e: any): void => setSearch(e.target.value)}></SearchInput>
            <div className='contacts-list-container'>
              {search === '' && (
                <div>
                  {invToMe.length > 0 && (
                    <div>
                      <div className='contact-separator-new separator'>
                        <div className='separator-icon'>
                          <img src={new_icon} alt='new' />
                        </div>
                      </div>
                      <ul className='contacts-list-new contacts-list'>
                        {/*tslint:disable-next-line:typedef no-any*/}
                        {invToMe.map((friend, index) => (
                          <li className='contacts-item-new contacts-item online' key={index}>
                            <div className='item-info'>
                              {/*@ts-ignore*/}
                              <img src={friend?.avatar_url ? friend.avatar_url : standard} alt='ava' />
                              <div>
                                {/*@ts-ignore*/}
                                <p className='item-name'>{friend.username}</p>
                                {/*@ts-ignore*/}
                                <p className='item-phone'>{friend.phone_number}</p>
                              </div>
                            </div>
                            <div className='item-buttons'>
                              <button className='accept' />
                              <button className='reject' />
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {invFromMe.length > 0 && (
                    <div>
                      <div className='contact-separator-unknown separator'>
                        <div className='separator-icon'>
                          <img src={unknown_icon} alt='' />
                        </div>
                      </div>
                      <ul className='contacts-list-new contacts-list'>
                        {/*tslint:disable-next-line:typedef no-any*/}
                        {invFromMe.map((friend, index) => (
                          <li className='contacts-item-new contacts-item' key={index}>
                            <div className='item-info'>
                              <img
                                // @ts-ignore
                                src={friend?.avatar_url ? friend?.avatar_url : standard}
                                alt='ava'
                              />
                              <div>
                                {/*@ts-ignore*/}
                                <p className='item-name'>{friend.username}</p>
                                <p className='item-phone'>{t('Contacts.placeholders.pending')}</p>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {/*@ts-ignore*/}
              {friends && friends.length > 0 && (
                <>
                  <div className='contact-separator-liter separator'>
                    {/*<div className='separator-rounded'>*/}
                    {/*    /!*A*!/*/}
                    {/*</div>*/}
                  </div>
                  <ul className='contacts-list'>
                    {/*@ts-ignore*/}
                    {friends &&
                      friends.map(
                        // tslint:disable-next-line:typedef no-any
                        (friend: any, index: number) => (
                          <li className={`contacts-item ${friend.online ? 'online' : ''}`} key={index}>
                            <img src={friend.avatar !== null ? friend.avatar : standard} alt='ava' />
                            <div>
                              <p className='item-name'>
                                {friend.first_name} {friend.last_name}
                              </p>
                              <p className='item-phone'>{friend.phone_number}</p>
                            </div>
                            <span
                              className='trash'
                              onClick={(): void => handleDelete(friend.id, friend.phone_number)}
                            />
                            <span className='send' onClick={(): void => handleClick(friend)} />
                          </li>
                        ),
                      )}
                  </ul>
                </>
              )}
            </div>
          </div>
          {menu.show && (
            <div className='menu delete-change'>
              <div className='menu-body'>
                <button className='delete'>
                  <img src={bin} alt='delete' />
                  <span>{t('Contacts.placeholders.delete')}</span>
                </button>
                <button className='change'>
                  <img src={change} alt='change' />
                  <span>{t('Contacts.placeholders.change')}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      </WithFooterMenu>
    </>
  );
}
