import { format } from 'date-fns';
import { FormikValues, useFormikContext } from 'formik';
import { useCallback } from 'react';
import type { TCityData, TEditProfileForm } from '../../../utils/types/types';
const USER_LOCALE = navigator.language;

export function useHandlers({ values }: { values: FormikValues }): {
  handleClear: (id: string) => void;
  clickOptionSearchSelect: (city: TCityData) => void;
  checkSelectedSearchSelect: (city: TCityData) => boolean;
  searchMethodSearchSelect: (searchValue: string, city: TCityData) => boolean;
  saveDate: (value: Date) => void;
} {
  const { setFieldValue } = useFormikContext<TEditProfileForm>();

  const handleClear = useCallback(
    (id): void => {
      setFieldValue(id, '');
    },
    [setFieldValue],
  );

  const clickOptionSearchSelect = useCallback(
    (city: TCityData): void => {
      setFieldValue('city', city.id);
    },
    [setFieldValue],
  );
  const checkSelectedSearchSelect = useCallback((city: TCityData): boolean => city.id === values.city, [values.city]);
  const searchMethodSearchSelect = useCallback(
    (searchValue: string, city: TCityData): boolean =>
      // @ts-ignore
      city[`name_${USER_LOCALE}`].toLowerCase().includes(searchValue.toLowerCase()),
    [],
  );
  const saveDate = useCallback(
    (value: Date): void => {
      setFieldValue('birthday', format(value, 'yyyy-MM-dd'));
    },
    [setFieldValue],
  );

  return { handleClear, searchMethodSearchSelect, checkSelectedSearchSelect, clickOptionSearchSelect, saveDate };
}
