import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { enGB, uk } from 'date-fns/locale';
import dayjs from 'dayjs';
import { useFormikContext } from 'formik';
import { type ReactElement, useEffect, useState } from 'react';
import { EN_LOCALE_DATE_FORMAT, UK_LOCALE_DATE_FORMAT, USER_LOCALE } from '../../../../../utils/const';
import { ErrorInput } from '../../components/Inputs/ErrorInput/ErrorInput';
import './CustomDateInput.scss';

type TProps = {
  onChange: () => void;
  required?: boolean;
  errors?: any;
  errorType: string;
  value: Date | null;
  field: any;
};

export function CustomDateInput({ errors, errorType, onChange, value, field, ...props }: TProps): ReactElement {
  const [cancelLabel, setCancelLabel] = useState('Cancel');
  const [locale, setLocale] = useState(enGB);
  const [dateFormat, setDateFormat] = useState(EN_LOCALE_DATE_FORMAT);
  const [focused, setFocus] = useState(false);
  const { validateField } = useFormikContext();

  // change translation depending on app language
  useEffect((): void => {
    const language = localStorage.getItem('language');
    if (language === 'en') {
      setLocale(enGB);
    } else {
      setLocale(uk);
      setCancelLabel('Скасувати');
    }
  }, []);

  useEffect((): void => {
    validateField(field.name);
  }, [value, validateField]);

  // change date format depending on browsers locale
  useEffect((): void => {
    if (USER_LOCALE === 'uk' || USER_LOCALE === 'ru') {
      setDateFormat(UK_LOCALE_DATE_FORMAT);
    } else {
      setDateFormat(EN_LOCALE_DATE_FORMAT);
    }
  }, []);
  const today = dayjs();
  const minDate = dayjs().subtract(30, 'year');
  return (
    <div className='formDateInputContainer'>
      <div className='formDateInputContainerInner'>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <DatePicker
            {...field}
            {...props}
            format={dateFormat}
            openTo='year'
            maxDate={today}
            minDate={minDate}
            views={['year', 'month', 'date']}
            cancelLabel={cancelLabel}
            onChange={onChange}
            value={value}
            focused={focused}
            className={Boolean(errors) ? 'error' : ''}
            InputLabelProps={{
              variant: 'filled',
            }}
            inputVariant='outlined'
            onClose={(): void => {
              setFocus(false);
            }}
            onOpen={(): void => setFocus(true)}
          />
        </MuiPickersUtilsProvider>
      </div>
      <ErrorInput error={errors} type={errorType}></ErrorInput>
    </div>
  );
}
