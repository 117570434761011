import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../../../MUITheme';

export const useStyles = makeStyles({
  backButton: {
    alignItems: 'center',
    background: theme.palette.primary.main,
    border: '0',
    borderRadius: '1.75rem',
    boxShadow: '0 4px 8px rgba(20, 164, 180, 0.8)',
    height: '2.5rem',
    justifyContent: 'center',
    outline: 'none',
    width: '2.5rem',
    transition: ' 0.1s',

    '&:active, &:hover': {
      background: theme.palette.secondary.main,
      boxShadow: '0 2px 8px rgba(20, 164, 200, 0.8)',
      transform: 'translateY(2px)',
    },
  },
});
