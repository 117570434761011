import { Box, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import type { ReactElement } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import default_avatar from '../../../../../assets/images/default_avatar.png';
import edit_icon from '../../../../../assets/images/edit_icon.svg';
import { RequestState } from '../../../../../utils/enums';
import { useGoBack } from '../../../../../utils/hooks';
import useGetUser from '../../../../../utils/hooks/useGetUser';
import { BackButton, CustomInput, FormAvatar, PageLoader, RadioButtonGroup } from '../../../shared';
import type { TUserData } from '../../utils/types/types';
import { useData } from './useData';
import { useHandlers } from './useHandlers';

const initialValues = {
  avatar: '',
  birthday: '',
  city: '',
  country: '224',
  email: '',
  first_name: '',
  gender: '',
  last_name: '',
  phone_number: '',
};
// tslint:disable-next-line:no-any cyclomatic-complexity
export const ProfileMainPage = (): ReactElement => {
  const { t } = useTranslation();
  const goBack = useGoBack();
  const { userStatus, userData } = useGetUser();
  const handleError = useErrorHandler();
  const [user, setUser] = useState<TUserData>(initialValues);
  const { formattedBirthday, radioButtonGroupOptions, cityAndCountry, radioButtonGroupValue } = useData(user);
  const { editButtonHandler } = useHandlers();

  useEffect((): void => {
    // @ts-ignore
    userData().then((value: Core.RequestResponse<TUserData>): void => {
      setUser(value.data);
    });
  }, [handleError, userData]);

  return (
    <Box>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height='8vh'
        padding='0.63rem 1rem 0.63rem 1rem'
        boxShadow='2px 2px 16px 0px rgba(0, 0, 0, 0.1)'>
        <BackButton goBack={goBack} />
        <Typography
          fontFamily='Nunito-Bold, sans-serif'
          fontWeight='700'
          maxWidth='46vw'
          lineHeight='22px'
          marginTop=' 0.5rem'>
          {t('ProfilePage.title')}
        </Typography>
        <IconButton className='confirm-button' onClick={editButtonHandler}>
          <img src={edit_icon} alt='' />
        </IconButton>
      </Stack>
      <Stack alignItems='center' gap='24px' padding='1rem 1rem 1rem' overflow='auto' height='90vh'>
        <FormAvatar defaultSrc={user?.avatar || default_avatar} alt='User' disabled />
        <CustomInput
          id='first_name'
          name='first_name'
          type='text'
          readOnly
          value={user?.first_name}
          data-id='first_name'
          label={t('ProfilePage.placeholders.first_name')}
        />
        <CustomInput
          id='last_name'
          name='last_name'
          readOnly
          type='text'
          value={user?.last_name}
          data-id='last_name'
          label={t('ProfilePage.placeholders.last_name')}
        />
        <CustomInput
          id='birthday'
          name='birthday'
          readOnly
          type='text'
          value={formattedBirthday}
          data-id='last_name'
          label={t('ProfilePage.placeholders.birthday')}
        />
        <RadioButtonGroup options={radioButtonGroupOptions} name='gender' disabled field={radioButtonGroupValue} />

        <CustomInput
          id='phone_number'
          name='phone_number'
          type='text'
          className='phone_number'
          value={user?.phone_number}
          readOnly
          data-id='phone_number'
          label={t('ProfilePage.placeholders.phone_number')}
        />
        <CustomInput
          id='email'
          name='email'
          type='text'
          readOnly
          value={user?.email}
          data-id='email'
          label={t('ProfilePage.placeholders.email')}
        />
        <CustomInput
          id='country'
          name='country'
          type='text'
          data-id='country'
          readOnly
          value={cityAndCountry.country}
          label={t('ProfilePage.placeholders.country')}
        />
        <CustomInput
          id='city'
          name='city'
          type='text'
          data-id='city'
          readOnly
          // @ts-ignore
          value={cityAndCountry.city}
          label={t('ProfilePage.placeholders.city')}
        />
      </Stack>
      {userStatus === RequestState.Pending && <PageLoader />}
    </Box>
  );
};
