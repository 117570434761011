import Slider from 'react-slick';
import './DashboardSlider.scss';
import type { TDashboardSliderProps, TDashboardItem } from './types';
import basket from '../../../../assets/images/dashboard/basket_for_dashboard_slider.svg';
import { useMemo } from 'react';

const sliderSettings = {
  dots: true,
  swipe: true,
  dotsClass: 'slick-dots',
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
  speed: 200,
};

function convertToArrayOfArrays<T>(arr: T[]) {
  const result = [];
  for (let i = 0; i < arr.length; i += 2) {
    result.push([arr[i], arr[i + 1]]);
  }
  return result;
}

export const DashboardSlider = ({ items, sliderWidth, onDelete }: TDashboardSliderProps) => {
  const imageSize = sliderWidth / 2 - 40;

  const slidesArray = useMemo(() => convertToArrayOfArrays(items), [items]);

  return (
    <div className='sliderContainer'>
      <Slider {...sliderSettings}>
        {slidesArray?.map((slides: TDashboardItem[], index: number) => (
          <div key={index} className='sliderContainerSlide'>
            <div className='sliderContainerSlideFlex'>
              {slides.map((slide, slideIndex) => (
                <div key={slideIndex} onClick={slide.onClick} style={{ opacity: slide.opacity }}>
                  <div
                    style={{
                      backgroundImage: `url(${slide.imageSrc})`,
                      width: `${imageSize}px`,
                      height: `${imageSize}px`,
                      backgroundPosition: slide.backgroundPosition,
                    }}
                    className={slide.imageSrc ? 'sliderContainerSlideImage' : 'sliderContainerSlideImageNoBorder'}>
                    {slide.text && (
                      <div className='sliderAvatarNameContainer'>
                        <span className='sliderAvatarName'>{slide.text}</span>
                      </div>
                    )}
                    {onDelete && !(index === 0 && slideIndex === 0) && slide.imageSrc !== undefined && (
                      <button
                        className='sliderAvatarBasketContainer'
                        onClick={event => {
                          event.stopPropagation();
                          onDelete?.(slide.petId!);
                        }}>
                        <img src={basket} alt='Basket' />
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
