import { PetEventsTypes } from '../../../utils/enums/eventTypes';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

export function useData(title: string, type: PetEventsTypes) {
  const { t } = useTranslation();
  const { petId, eventId } = useParams<{ petId: string; eventId: string }>();
  const editBtnText = t('ViewEventPage.edit');
  function setFirstCapitalLetter(title: string, type: PetEventsTypes): string {
    if (!title) return t(`PetProfilePage.${type}Form.pageTitle`);
    return title[0].toUpperCase() + title.slice(1);
  }

  const capitalizedTitle = setFirstCapitalLetter(title, type);
  return { capitalizedTitle, editBtnText, petId, eventId };
}
