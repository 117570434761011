import { PetEventTitleFields } from '../../utils/enums/eventTypes';
import { PET_ALLERGY, PET_SERVICES, PET_TREATMENT, PET_VACCINATION, PET_VETERINARIAN } from '../../requestKeys';
import { TypeProps } from '../../types';

export const TYPE_PROPS: TypeProps = {
  [PET_ALLERGY]: { title: PetEventTitleFields.ALLERGY },
  [PET_SERVICES]: { title: PetEventTitleFields.SERVICE },
  [PET_TREATMENT]: { title: PetEventTitleFields.TREATMENT },
  [PET_VACCINATION]: { title: PetEventTitleFields.VACCINATION },
  [PET_VETERINARIAN]: { title: PetEventTitleFields.VETERINARIAN },
};
