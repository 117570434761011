import React from 'react';
import {Redirect, Route} from 'react-router-dom';

// @ts-ignore
// tslint:disable-next-line:typedef
const RouteGuard = ({Component, ...rest}) => {

    // tslint:disable-next-line:typedef
    function hasJWT() {
        let flag;
        localStorage.getItem('access') && localStorage.getItem('refresh') && localStorage.getItem('is_phone_number_verified') === 'True' ? flag = true : flag = false;
        return flag;
    }

    return (
        <>
            <Route {...rest}
                // tslint:disable-next-line:typedef
                   render={props => (
                       hasJWT() ?
                           ((props.location.pathname.match('/auth') || props.location.pathname.match('/start')) ?
                               <Redirect to={{pathname: '/dashboard/main'}}
                               />
                               :
                               <Component {...props} />)
                           :
                           (!(props.location.pathname.match('/auth') || props.location.pathname.match('/start')) ?
                                   <Redirect to={{pathname: '/auth/login'}}/>
                                   :
                                   <Component {...props} />
                           ))}/>
        </>
    );
};

export default RouteGuard;
