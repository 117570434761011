import { Field, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import arrow from '../../../../../assets/images/pet/arrow-change.png';
import { FormikTimeSetProps } from '../../types';

const PetFormikTimeSet: React.FC<FormikTimeSetProps> = ({ required, placeholder, saved, time, name }) => {
  const [currentTime, setCurrentTime] = useState(time || 600);
  const [toggleClass, setToggleClass] = useState('active');

  const getPadTime = (t: number) => t.toString().padStart(2, '0');
  const hours: string = getPadTime(Math.floor(currentTime / 60));
  const minutes: string = getPadTime(currentTime - Number(hours) * 60);

  useEffect((): void => {
    if (Math.floor(currentTime / 60) >= 24 || Math.floor(currentTime / 60) < 0) {
      setCurrentTime(0);
    }
  }, [currentTime]);

  const handleTimeIncrease = (form: FormikHelpers<string>) => {
    const newTime = currentTime + 5;

    setCurrentTime(() => newTime);
    form.setFieldValue('timeSetField', newTime);
  };

  const handleTimeDecrease = (form: FormikHelpers<string>) => {
    const newTime = currentTime - 5;
    if (newTime < 0) {
      setCurrentTime(() => 1435);
      form.setFieldValue('timeSetField', newTime);
      return;
    }
    setCurrentTime(() => newTime);
    form.setFieldValue('timeSetField', newTime);
  };

  return (
    <Field name={name}>
      {({ form }: any) => {
        return (
          <div
            onMouseLeave={(): void => {
              setToggleClass('inactive');
            }}
            onMouseDown={(): void => {
              setToggleClass('active');
            }}
            className={`PetTimeSet ${toggleClass} `}>
            <span className={`PetTimeSet-index ${required ? 'required' : ''}`}>{placeholder}</span>
            <span className='PetTimeSet-choice'>
              <button type='button' className='time-decrease'>
                <img src={arrow} alt='decrease' onClick={() => handleTimeDecrease(form)} />
              </button>

              <span className='PetTimeSet-time'>{`${hours}:${minutes}`}</span>

              <button type='button' className='time-uncrease' onClick={() => handleTimeIncrease(form)}>
                <img src={arrow} alt='uncrease' />
              </button>
            </span>
          </div>
        );
      }}
    </Field>
  );
};

export default PetFormikTimeSet;
