import { RequestState } from '../../../../../../utils/enums';
import { TRequestResponse, getById } from '../../../../../../utils/services';
import { useCallback, useEffect, useState } from 'react';

import type { AnyOneEventResponse } from '../../../types';
import { URLS } from '../../../../../../utils/const';
import { notificationSelector } from '../../../../../../utils/selectors';
import { useErrorHandler } from 'react-error-boundary';
import { useIsMounted } from '../../../../../../utils/hooks';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../../../utils/atoms';

export function useGetEventData(
  eventId: string,
  url: keyof typeof URLS,
): [RequestState, AnyOneEventResponse | {}] {
  const handleError = useErrorHandler();
  const [, addNotification] = useRecoilState(notificationSelector);
  const [eventData, setEventData] = useState<AnyOneEventResponse | {}>({});
  const [requestStatus, setCreateStatus] = useState<RequestState>(RequestState.Idle);
  const [token] = useRecoilState(tokenState)

  const isMounted = useIsMounted();
  const getEventData = useCallback(async (): Promise<TRequestResponse<AnyOneEventResponse> | void> => {
    setCreateStatus(RequestState.Pending);
    const response = await getById(url, Number(eventId), token);
    if (!isMounted()) return;
    
    if (response.error) {
      handleError(response.error);
      addNotification({
        title: response.error.response?.status,
        text: response.error.response?.data,
        type: 'error',
      });
      setCreateStatus(RequestState.Error);
      return
    }
    setCreateStatus(RequestState.Success);
    setEventData(response.data as AnyOneEventResponse);
  }, [addNotification, eventId, handleError, isMounted, token, url]);

  useEffect(() => {
    getEventData();
  }, [getEventData]);

  return [requestStatus, eventData];
}
