import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import type { TLoginForm } from '../../../../types/types';

export function useHandlers(): { handleClearPhone: () => void; handleClearPassword: () => void } {
  const { setFieldValue } = useFormikContext<TLoginForm>();

  const handleClearPhone = useCallback((): void => {
    setFieldValue('phone', '');
  }, [setFieldValue]);

  const handleClearPassword = useCallback((): void => {
    setFieldValue('password', '');
  }, [setFieldValue]);

  return { handleClearPhone, handleClearPassword };
}
