import { useHistory } from 'react-router';
import type { TOneTypeEventHandlersParams } from '../../../types';

export function useHandleClick({ pathForAdd, pathForBack, petId }: TOneTypeEventHandlersParams) {
  const history = useHistory();
  const handleAddEvent = (): void => {
    history.push(pathForAdd, { from: `${pathForBack}`, id: petId });
  };

  return { handleAddEvent };
}
