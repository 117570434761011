import { Box, Stack, Typography, useTheme } from '@mui/material';
import React, { type ReactElement } from 'react';
import selected from '../../../../../assets/images/check.png';
import { BackButton } from '../BackButton/BackButton';
import { CustomInput } from '../Inputs/CustomInput/CustomInput';
import { SearchInput } from '../Inputs/SearchInput/SearchInput';
import { useHandlers } from './useHandlers';

type TProps = {
  label: string;
  title: string;
  // tslint:disable-next-line:no-any
  options: any[];
  // tslint:disable-next-line:no-any
  value: any;
  // tslint:disable-next-line:no-any
  onClick: (e: any) => void;
  sort?: () => void;
  // tslint:disable-next-line:no-any
  checkSelected: (item: any) => boolean;
  // tslint:disable-next-line:no-any
  searchMethod: (searchValue: any, item: any) => boolean;
  // tslint:disable-next-line:no-any
  renderOptions: (item: any) => ReactElement;
};

export function CustomSearchSelect({
  label,
  title,
  options,
  renderOptions,
  onClick,
  checkSelected,
  searchMethod,
  value,
}: TProps & React.SelectHTMLAttributes<HTMLInputElement>): ReactElement {
  const theme = useTheme();
  const { isOpen, openSelect, closeSelect, onClickOption, visibleOptions, changeSearchValue } = useHandlers({
    options,
    onClick,
    searchMethod,
  });

  return (
    <>
      <CustomInput label={label} readOnly onClick={openSelect} value={value} />
      <Box
        bgcolor={theme.palette.background.paper}
        display={isOpen ? 'block' : 'none'}
        height='100vh'
        position='absolute'
        top={0}
        zIndex={999}
        width='100vw'>
        <Stack
          direction='row'
          justifyContent='space-between'
          height='10vh'
          padding='0.63rem 1rem 0.63rem 1rem'
          boxShadow='2px 2px 16px 0px rgba(0, 0, 0, 0.1)'>
          <BackButton goBack={closeSelect} />
          <Typography
            fontFamily='Nunito-Bold, sans-serif'
            fontWeight='700'
            maxWidth='46vw'
            lineHeight='22px'
            marginTop=' 0.5rem'>
            {title}
          </Typography>
          <Box></Box>
        </Stack>
        <Stack alignItems='center' padding='0.5rem 1rem 1rem' overflow='auto' height='90vh'>
          <SearchInput onChange={changeSearchValue} />
          <Stack width='100%'>
            {visibleOptions.map(
              // tslint:disable-next-line:no-any
              (option: any, index: number): ReactElement => (
                <Box
                  display='flex'
                  justifyContent='space-between'
                  key={`optionCustomSearchSelect${index}`}
                  onClick={onClickOption}
                  data-id={index}>
                  {renderOptions(option)}
                  {checkSelected(option) && <img height='12px' src={selected} alt='' />}
                </Box>
              ),
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
