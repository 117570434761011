export enum ProfileRoutePath {
    MAIN = 'main',
    EDIT = 'edit',
    LANGUAGE = 'language',
    CONFIDENTIALITY = 'confidentiality',
    THEME = 'theme',
    NOTIFICATIONS = 'notifications',
    MAPS = 'maps',
    INFORMATION = 'information',
    HELP = 'help'
}
