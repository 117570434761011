export enum PetProfileRoutePath {
  PET = '/pet',
  MAIN = 'main',
  EDIT = 'edit',
  CREATE = 'create',

  ALLERGIES = 'allergies',
  ADD_ALLERGY = 'add_allergy',
  EDIT_ALLERGY = 'edit_allergy',
  VIEW_ALLERGY = 'view_allergy',

  VET = 'vet',
  ADD_VET = 'add_vet',
  EDIT_VET = 'edit_vet',
  VIEW_VET = 'view_veterinarian',

  SERVICES = 'services',
  ADD_SERVICES = 'add_services',
  EDIT_SERVICES = 'edit_services',
  VIEW_SERVICES = 'view_services',

  VACCINATION = 'vaccination',
  ADD_VACCINATION = 'add_vaccination',
  EDIT_VACCINATION = 'edit_vaccination',
  VIEW_VACCINATION = 'view_vaccination',

  TREATMENT = 'treatment',
  ADD_TREATMENT = 'add_treatment',
  EDIT_TREATMENT = 'edit_treatment',
  VIEW_TREATMENT = 'view_treatment',
}
