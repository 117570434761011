import { Box, Button, Link, Stack, Typography, useTheme } from '@mui/material';
import { Field, Form, useFormikContext } from 'formik';
import type { ReactElement } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RequestState } from '../../../../../../utils/enums';
import { CustomCheckbox, CustomInput, Phone, SecureInput } from '../../../../shared';
import type { TSignupForm } from '../../../types/types';
import { useRecaptchaVerify } from '../../../utils/hooks/useRecaptchaVerify';
import { useHandlers } from './useHandlers';

export function SignupForm({ requestState, ...props }: { requestState: RequestState }): ReactElement {
  const { t } = useTranslation();
  const { errors, touched, setFieldValue, values } = useFormikContext<TSignupForm>();
  const theme = useTheme();
  const { handleClear } = useHandlers();
  useRecaptchaVerify(values.terms, setFieldValue);

  return (
    <Form {...props} noValidate>
      <Stack gap={3.2} overflow='auto'>
        <Phone
          id='phone'
          setField={setFieldValue}
          required
          clear={handleClear}
          errorsPhone={touched.phone && errors.phone}
          errorsCode={touched.code && errors.code}
        />
        <Field
          id='name'
          name='name'
          component={CustomInput}
          required
          errors={touched.name && errors.name}
          clear={handleClear}
          label={t('signupPage.placeholders.name')}
        />
        <Field
          id='password'
          name='password'
          component={SecureInput}
          required
          errors={touched.password && errors.password}
          clear={(): void => {
            setFieldValue('password', '');
          }}
          label={t('signupPage.placeholders.password')}
        />
        <Field
          id='re_password'
          name='re_password'
          component={SecureInput}
          required
          errors={touched['re_password'] && errors['re_password']}
          clear={(): void => {
            setFieldValue('re_password', '');
          }}
          label={t('signupPage.placeholders.re_password')}
        />
        <Field
          id='email'
          name='email'
          component={CustomInput}
          errors={touched.email && errors.email}
          clear={(): void => {
            setFieldValue('email', '');
          }}
          label={t('signupPage.placeholders.email')}
        />
        <Box>
          <Field
            as={CustomCheckbox}
            name='terms'
            labelPlacement='start'
            label={
              <Typography>
                {t('signupPage.terms.text')}{' '}
                <Link color={theme.palette.text.primary} href='/auth/policy'>
                  {t('signupPage.terms.link')}
                </Link>
              </Typography>
            }
          />
          {values.terms && <Box marginTop={1} width='100%' id='recaptcha-container' />}
        </Box>
        {requestState === RequestState.Error && (
          <Typography className='alert'>{t('signupPage.errors.userExists')} </Typography>
        )}
        <Button type='submit' variant='primaryContained' disabled={!values.terms}>
          {t('signupPage.register')}
        </Button>
      </Stack>
    </Form>
  );
}
