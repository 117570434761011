import React, { type ReactElement, useEffect, useState } from 'react';
import errorImage401 from '../../../../assets/images/error/401.png';
import errorImage403 from '../../../../assets/images/error/403.png';
import errorImage404 from '../../../../assets/images/error/404.png';
import errorImage500 from '../../../../assets/images/error/500.png';
import errorImageDefault from '../../../../assets/images/error/error.png';
import { ErrorPageComponent } from '../../errors/components';

export function FallbackErrorBoundary({ error }: { error: { response?: { status: number } } }): ReactElement {
  // @ts-ignore
  const codeStatus = error?.response ? error.response?.status : 0;

  const [errorImage, setErrorImage] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  useEffect((): void => {
    switch (codeStatus) {
      case 401:
        setErrorMessage('LoremipsumLoremipsum LoremipsumLoremipsum');
        // @ts-ignore
        return setErrorImage(errorImage401);
      case 403:
        setErrorMessage('LoremipsumLoremipsum LoremipsumLoremipsum');
        // @ts-ignore
        return setErrorImage(errorImage403);
      case 404:
        setErrorMessage('LoremipsumLoremipsum LoremipsumLoremipsum');
        // @ts-ignore
        return setErrorImage(errorImage404);
      case 500:
        setErrorMessage('LoremipsumLoremipsum LoremipsumLoremipsum');
        // @ts-ignore
        return setErrorImage(errorImage500);
      default:
        setErrorMessage('LoremipsumLoremipsum LoremipsumLoremipsum');
        // @ts-ignore
        return setErrorImage(errorImageDefault);
    }
  }, [codeStatus, error]);

  return (
    <ErrorPageComponent
      errorType={codeStatus}
      // @ts-ignore
      errorImage={errorImage}
      errorText={errorMessage}
    />
  );
}
