import 'bootstrap-icons/font/bootstrap-icons.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import './assets/fonts/Nunito-Black.ttf';
import './assets/fonts/Nunito-Bold.ttf';
import './assets/fonts/Nunito-ExtraBold.ttf';
import './assets/fonts/Nunito-ExtraLight.ttf';
import './assets/fonts/Nunito-Light.ttf';
import './assets/fonts/Nunito-Regular.ttf';
import './assets/fonts/Nunito-SemiBold.ttf';
import { Loader } from './pwa/modules/shared';
import RegularRouter from './regularRouter';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// tslint:disable-next-line:typedef
const LazyRouter = lazy(() => import('./lazyRouter'));

const appHeight = (): void => {
  const doc = document.documentElement;
  doc.style.setProperty('--app-height', `${window.innerHeight}px`);
};
window.addEventListener('resize', appHeight);
appHeight();
// tslint:disable-next-line:typedef
const CheckRenderRoutes = () => {
  const ua = navigator.userAgent;
  if (ua.indexOf('Android') >= 0 && parseFloat(ua.slice(ua.indexOf('Android') + 8)) < 10) {
    return <RegularRouter />;
  } else {
    return (
      <Suspense fallback={<Loader />}>
        <LazyRouter />
      </Suspense>
    );
  }
};

ReactDOM.render(<CheckRenderRoutes />, document.getElementById('root'));
// initializeFirebase();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
