import { IconButton, Stack, Typography } from '@mui/material';
import { BackButton } from '../BackButton/BackButton';
import { useGoBack } from '../../../../../utils/hooks';
import SaveIcon from '../../../../../assets/images/save_icon.svg';
import type { THeaderProps } from './types/types';
import { useShowPopUp } from './hooks/useShowPopUp';
import { useCallback } from 'react';

export function FormHeader(props: THeaderProps) {
  const { prevUrl, title, popUpShow } = props;
  const goBack = useGoBack(prevUrl);
  const { showPopUp } = useShowPopUp();

  const handleBackButtonClick = useCallback(() => {
    if (popUpShow) {
      showPopUp({ goBackCallback: goBack });
    } else {
      goBack();
    }
  }, [goBack, popUpShow, showPopUp]);

  return (
    <Stack
      display='flex'
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      height='8vh'
      padding='0.63rem 1rem 0.63rem 1rem'
      boxShadow='2px 2px 16px 0px rgba(0, 0, 0, 0.1)'>
      <BackButton onClick={handleBackButtonClick} />
      <Typography fontFamily='Nunito-Bold, sans-serif' fontWeight='700' marginTop=' 0.5rem'>
        {title}
      </Typography>
      <IconButton type='submit'>
        <img src={SaveIcon} alt='save-button' />
      </IconButton>
    </Stack>
  );
}
