import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Field, Form, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import default_pet_avatar from '../../../../../assets/images/pet/default_pet_avatar.svg';
import {
  CustomDateInput,
  CustomInput,
  CustomSelect,
  CustomSelectNew,
  FormAvatar,
  FormOutdoorSchedule,
  PageLoader,
  RadioButtonGroup,
} from '../../../shared';
import { useData, usePetForm } from '../../hooks';
import CustomKindSelectWithPagination from './CustomKindSelectWithPagination/CustomKindSelectWithPagination';
import './PetForm.scss';
import type { TPetForm, TPropsType } from './types/types';
import { FormHeader } from '../../../shared/components/FormHeader';
import { areObjectsEqual } from '../../../../../utils/helpers';
import { SelectTypes } from '../../../../../utils/enums';

export const PetForm = ({ titleI18Key }: TPropsType) => {
  const { values, handleClearField } = usePetForm();
  const [popUpShow, setPopUpShow] = useState(false);

  const { t } = useTranslation();

  const { errors, touched, initialValues, setFieldValue, isSubmitting, validateField } = useFormikContext<TPetForm>();

  const handleDateChange = (date: MaterialUiPickersDate) => {
    if (date) {
      setFieldValue('birth', date);
    }
  };

  useEffect(() => {
    validateField('kind');
  }, [validateField, values.kind]);

  const {
    selectLivingPlaceOptions,
    selectKindOptions,
    selectTemperamentOptions,
    genderOptions,
    foodOptions,
    sterilizationOptions,
    animalIdOptions,
  } = useData();

  useEffect(() => {
    setFieldValue('id_chip_stigma', '');
  }, [setFieldValue, values.id_type]);

  useEffect(() => {
    setFieldValue('breed', undefined);
  }, [setFieldValue, values.kind]);

  useEffect(() => {
    setPopUpShow(!areObjectsEqual(values, initialValues));
  }, [initialValues, values]);

  return (
    <Form noValidate>
      {isSubmitting && <PageLoader />}
      <FormHeader prevUrl='/dashboard/main' title={t(titleI18Key)} popUpShow={popUpShow} />
      <div className='petPageFormForm'>
        <div className='petPageFormFormItem'>
          <div className='petPageFormAvatarContainer'>
            <Field name='avatar' component={FormAvatar} defaultSrc={default_pet_avatar} alt='pet' />
          </div>
        </div>
        <div className='petPageFormFormItem'>
          <Field
            name='kind'
            isFormPart
            component={CustomSelectNew}
            required
            defaultValue=''
            errors={touched.kind && errors.kind}
            label={t('petForm.placeholders.kind')}
            errorType='transparent'
            selectType={SelectTypes.PopUpSelect}
            options={selectKindOptions}
          />
        </div>
        <div className='petPageFormFormItemName'>
          <Field
            label={t('petForm.placeholders.name')}
            name='name'
            id='name'
            clear={handleClearField}
            component={CustomInput}
            errors={touched.name && errors.name}
            errorType={'transparent'}
            required
          />
        </div>
        <div className='petPageFormFormItemBirth'>
          <Field
            name='birth'
            component={CustomDateInput}
            required
            label={t('petForm.placeholders.birthday')}
            errors={touched.birth && errors.birth}
            errorType={'transparent'}
            onChange={handleDateChange}
            value={values.birth}
          />
        </div>
        <div className='petPageFormFormItem'>
          <Field
            name='breed'
            component={CustomKindSelectWithPagination}
            kindId={values.kind}
            label={t('petForm.placeholders.breed')}
          />
        </div>
        <div className='petPageFormFormItem'>
          <Field
            name='gender'
            label={t('petForm.placeholders.gender')}
            options={genderOptions}
            component={RadioButtonGroup}
          />
        </div>
        <div className='petPageFormFormItem'>
          <Field
            label={t('petForm.placeholders.color')}
            name='color'
            id='color'
            component={CustomInput}
            errorType={'transparent'}
            clear={handleClearField}
            errors={touched.color && errors.color}
          />
        </div>
        <div className='petPageFormFormItem'>
          <Field
            label={t('petForm.placeholders.special_signs')}
            name='special_signs'
            id='special_signs'
            component={CustomInput}
            clear={handleClearField}
            errorType={'transparent'}
            errors={touched.special_signs && errors.special_signs}
          />
        </div>
        <div className='petPageFormFormItem'>
          <Field
            name='food'
            label={t('petForm.placeholders.food')}
            options={foodOptions}
            component={RadioButtonGroup}
          />
        </div>
        <div className='petPageFormFormItem'>
          <Field
            name='sterilization'
            label={t('petForm.placeholders.sterilization')}
            options={sterilizationOptions}
            component={RadioButtonGroup}
          />
        </div>
        <div className='petPageFormFormItem'>
          <Field
            name='living_place'
            component={CustomSelect}
            choices={selectLivingPlaceOptions}
            label={t('petForm.placeholders.living_place')}
          />
        </div>
        <div className='petPageFormFormItem'>
          <Field
            name='outdoor_schedule'
            component={FormOutdoorSchedule}
            label={t('petForm.placeholders.outdoor_schedule')}
          />
        </div>
        <div className='petPageFormFormItem'>
          <Field
            name='id_type'
            label={t('petForm.placeholders.animal_id')}
            options={animalIdOptions}
            component={RadioButtonGroup}
            isInColumn
          />
        </div>
        <div className='petPageFormFormItem'>
          <Field
            name='temperament'
            component={CustomSelect}
            choices={selectTemperamentOptions}
            label={t('petForm.placeholders.temperament')}
          />
        </div>
      </div>
    </Form>
  );
};
