import React, { type ReactElement } from 'react';
import { FooterMenu } from '../FooterMenu/FooterMenu';
import './WithFooterMenu.scss';

export const WithFooterMenu = ({ children }: { children: ReactElement | ReactElement[] }): ReactElement => {
  return (
    <div className='withFooterMenu'>
      <div className='content'>{children}</div>
      <div className='footer'>
        <FooterMenu />
      </div>
    </div>
  );
};
