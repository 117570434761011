import React, { type ChangeEvent, ReactElement } from 'react';
import { CustomInput } from '../CustomInput/CustomInput';
import './PhoneInput.scss';
import type { TPhoneInputProps } from '../types';
import { PhoneCodes } from '../../../../../../utils/enums';

export function PhoneInput({ setField, code, ...props }: TPhoneInputProps): ReactElement {
  const onChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setField('phone', e.target.value.replace(/[\D]+/g, ''));
  };

  const codeClassName = code === PhoneCodes.UK ? 'phone-code-margin-left' : '';

  return (
    <div className={'phone-input'}>
        <CustomInput
          {...props}
          required
          onChange={onChange}
          icons-start={[
            <span
            className={`phone-code ${codeClassName}`}
              >{`+${code}`}</span>,
              <div className='phone-vertical-line'></div>
          ]}
        />
    </div>
  );
}
