import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
} from '@mui/material';
import type { TAutoSelectValue, TCustomSelectProps } from '../types';
import { useCallback, useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import arrowImg from '../../../../../../assets/images/down-anchor.png';
import clearImg from '../../../../../../assets/images/clear.png';
import searchImg from '../../../../../../assets/images/search.png';
import { useData } from './hooks/useData';
import { useHandlers } from './hooks/useHandlers';

export function CustomSelectNew({
  selectType,
  variant,
  focused = false,
  value,
  options,
  hasClearIcon = false,
  label,
  placeholder,
  required = false,
  onClick = () => {},
  errors,
  onClose = () => {},
  onChange = () => {},
  onChangeForm = () => {},
  errorType,
  hasSearchIcon = false,
  isFormPart = true,
  field,
  ...props
}: TCustomSelectProps) {
  const [isFocused, setFocus] = useState<boolean>(focused ?? false);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [currValue, setCurrValue] = useState<TAutoSelectValue>(value ?? null);
  const [prevValue, setPrevValue] = useState<TAutoSelectValue>(currValue);
  const { InputLabelProps, imgBoxProps, StyledImg, StyledSearchImg, noOptionsText, filterOptions, theme } = useData({
    currValue, selectType
  });

  const { onOpen, onChangeDefault, handleKeyDown, onClickAway } = useHandlers({
    setFocus,
    setOpen,
    onClose,
    setCurrValue,
    setPrevValue,
    prevValue,
    onChange,
    onChangeForm,
    currValue,
    defaultValue: value,
    isFormPart,
    field,
  });

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams): JSX.Element => (
      <TextField
        {...params}
        onKeyDown={handleKeyDown}
        label={label}
        placeholder={placeholder}
        required={required}
        variant='outlined'
        InputLabelProps={InputLabelProps}
        focused={isOpen}
        InputProps={{
          ...params.InputProps,
          autoComplete: 'off',
          endAdornment: (
            <>
              {params.InputProps.endAdornment}
              {!isOpen && (
                <InputAdornment key='inputExpandButton' position='end' onClick={(): void => setOpen(true)}>
                  <IconButton onClick={onOpen} edge='end'>
                    <img src={arrowImg} alt='' />
                  </IconButton>
                </InputAdornment>
              )}
            </>
          ),
          startAdornment: (
            <InputAdornment variant='filled' autoFocus={false} position='start'>
              {isOpen ? (
                <IconButton onClick={onClick} edge='start'>
                  {hasSearchIcon ? (
                    <Box {...imgBoxProps} margin='0' left='0rem' top='-0.6rem' position='absolute'>
                      <StyledSearchImg src={searchImg} alt='open' />
                    </Box>
                  ) : null}
                </IconButton>
              ) : value && value.src ? (
                <IconButton>
                  <Box {...imgBoxProps}>
                    <StyledImg loading='lazy' src={value.src} alt='' />
                  </Box>
                </IconButton>
              ) : null}
            </InputAdornment>
          ),
        }}
      />
    ),
    [
      InputLabelProps,
      StyledImg,
      StyledSearchImg,
      handleKeyDown,
      hasSearchIcon,
      imgBoxProps,
      isOpen,
      label,
      onClick,
      onOpen,
      placeholder,
      required,
      value,
    ],
  );

  return (
    <ThemeProvider theme={theme}>
      <ClickAwayListener onClickAway={onClickAway}>
        <Box position='relative' margin='18px 0 18px 0' min-height='60px' width='100%'>
          <Autocomplete
            {...props}
            className={isFocused ? 'focused' : ''}
            options={options}
            clearIcon={hasClearIcon ? <img src={clearImg} alt='' /> : null}
            onOpen={onOpen}
            onChange={onChangeDefault}
            open={isOpen}
            clearOnBlur
            clearOnEscape
            autoSelect
            readOnly={!!currValue}
            filterOptions={filterOptions}
            autoHighlight
            value={currValue}
            noOptionsText={<Box color={theme.palette.text.disabled}>{noOptionsText}</Box>}
            renderInput={renderInput}
          />
        </Box>
      </ClickAwayListener>
    </ThemeProvider>
  );
}
