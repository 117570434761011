export const URLS = {
  // LOGIN: 'auth/login',
  LOGIN: 'auth/jwt/create',
  FACEBOOK_LOGIN: 'auth/facebook',
  GOOGLE_LOGIN: 'auth/google',
  REGISTRATION: 'auth/register',
  VERIFY_PHONE: 'activation/send',
  CODE_VERIFY: 'activation/verify',
  USERS: 'users/',
  CITIES: 'cities',
  FORGOT_PASSWORD: 'auth/password/forgot/send',
  RESET_PASS: 'auth/password/forgot/verify',
  REFRESH: 'auth/jwt/refresh',
  VERIFY: 'auth/jwt/verify',
  FRIENDS: 'friends',
  SEARCHFRIENDS: 'friends/my/search',
  SEARCHUSERS: 'friends/search',
  INVITEEXISTUSER: 'friends/invitation/send/user/exists',
  INVITENOTEXISTUSER: '/friends/invitation/send/user/does/not/exists',
  INVITATIONTOME: 'friends/invitation/to',
  INVITATIONFROMME: 'friends/invitation/from',
  SENDRESPONSEONINV: '/friends/invitation/result',
  PETS: 'pets',
  PETS_PUT: 'pets/',
  DELETE_PET: 'pets',
  GET_PET_INFO: 'pets/info',
  GET_PET_NAME_AVATAR: 'pets/name',
  ALL_USERS_PETS: 'pets/get',
  PETS_BREEDS: 'pets/breed',

  PET_ALLERGY: 'pet_allergy',
  DEL_PET_ALLERGY: 'pet_allergy',

  PET_VACCINATION: 'pet_vaccination',
  DEL_PET_VACCINATION: 'pet_vaccination',

  PET_TREATMENT: 'pet_treatment',
  DEL_PET_TREATMENT: 'pet_treatment',

  PET_VETERINARIAN: 'pet_veterinarian',
  DEL_PET_VETERINARIAN: 'pet_veterinarian',

  PET_SERVICES: 'pet_services',
  DEL_PET_SERVICES: 'pet_services',

  PETS_EVENTS: 'pets/events',
  PETS_EVENTS_MONTH: 'pets/events/month',
};
