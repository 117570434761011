export enum PetEventsTypes {
  ALLERGY = 'allergy',
  SERVICE = 'services',
  VACCINATION = 'vaccination',
  TREATMENT = 'treatment',
  VETERINARIAN = 'veterinarian'
}
export enum PetEventsResponseTypes {
  ALLERGY = 'pet_allergy',
  SERVICE = 'pet_services',
  VACCINATION = 'pet_vaccination',
  TREATMENT = 'pet_treatment',
  VETERINARIAN = 'pet_veterinarian'
}

export enum PetEventTitleFields {
  ALLERGY = 'allergy_type',
  SERVICE = 'service_name',
  TREATMENT = 'treatment_reason',
  VACCINATION = 'vaccination_name',
  VETERINARIAN = 'petclinic_name',
}
