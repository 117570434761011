import { Stack } from '@mui/material';
import React, { ReactElement } from 'react';
import { useRecoilState } from 'recoil';
import { notificationSelector } from '../../../../../utils/selectors';
import { NotificationProvider } from './components/NotificationProvider/NotificationProvider';
import './Notification.scss';
import { notificationState } from './utils/atoms/notification';

export function Notification(props: any): ReactElement {
  const [state] = useRecoilState<Notification.TNotificationState>(notificationState);
  const [, deleteNotification] = useRecoilState(notificationSelector);
  const handleClose = (notification: Notification.TNotification): void => {
    deleteNotification(notification);
  };

  return (
    <Stack spacing={2} className={'notificationsStack'} justifyContent='flex-end'>
      {state?.notifications.length > 0 &&
        state.notifications.map((notification: Notification.TNotification, index: number) => (
          <NotificationProvider
            key={'notification' + index}
            notification={notification}
            handleClose={handleClose}
            {...props}
          />
        ))}
    </Stack>
  );
}
