import { MAX_OUTDOOR_TIME, MIN_OUTDOOR_TIME } from '../../../../utils/const';
import type { PetEventDetails, PetEvents, PetFormSelectField, PetFormTextLayout, TPetInfo } from '../types';
import { PetEventTitleFields, PetEventsTypes } from '../utils/enums/eventTypes';
import type { TArray, TPetForm } from '../components/PetForm/types/types';

import CircleWithPlus from '../../../../assets/images/pet/add_allergy.svg';
import { PetFields } from '../enums';
import { PetProfileRoutePath } from '../utils/enums/routes-path';
import additionalServices from '../../../../assets/images/pet/additional_services.svg';
import allergy from '../../../../assets/images/pet/allergy.svg';
import doctor from '../../../../assets/images/pet/doc.svg';
import parasite from '../../../../assets/images/pet/bug.svg';
import service from '../../../../assets/images/pet/service.svg';
import { sortSelectOptions } from '../../../../utils/helpers';
import { useGetFieldLabels } from '../hooks';
import { useTranslation } from 'react-i18next';
import vaccine from '../../../../assets/images/pet/vaccine.svg';

const petDataState: TPetInfo = {
  avatar: '',
  birth: '',
  breed: null,
  color: '',
  food: '',
  gender: '',
  id: -1,
  id_chip_stigma: '',
  id_type: '',
  kind: -1,
  living_place: '',
  name: '',
  outdoor_schedule: '',
  owners: [],
  registration: '',
  special_signs: '',
  sterilization: '',
  temperament: '',
  has_allergy: false,
};

const ALLERGY_TYPE_SELECT_OPTIONS: TArray[] = [
  {
    id: 'Drug allergy',
    i18KeyName: 'drug',
  },
  {
    id: 'Food allergy',
    i18KeyName: 'food',
  },
  {
    id: 'Insect bite',
    i18KeyName: 'insect',
  },
  {
    id: 'Other',
    i18KeyName: 'other',
  },
];

const ALLERGY_TEXTAREA_FIELDS: PetFormTextLayout[] = [
  {
    i18KeyName: 'notes',
    required: false,
    requestFieldName: 'additional_info',
  },
];
const ALLERGY_INPUT_FIELDS: PetFormTextLayout[] = [
  {
    i18KeyName: 'drug',
    required: true,
    requestFieldName: 'drug_name',
  },
];

const SERVICES_INPUT_FIELDS: PetFormTextLayout[] = [
  {
    i18KeyName: 'name',
    required: true,
    requestFieldName: 'service_name',
  },
  {
    i18KeyName: 'institution',
    required: true,
    requestFieldName: 'institution_name',
  },
  {
    i18KeyName: 'address',
    required: false,
    requestFieldName: 'service_address',
  },
];
const SERVICES_TEXTAREA_FIELDS: PetFormTextLayout[] = [
  {
    i18KeyName: 'notes',
    required: false,
    requestFieldName: 'additional_info',
  },
];

const VACCINATION_INPUT_FIELDS: PetFormTextLayout[] = [
  {
    i18KeyName: 'name',
    required: true,
    requestFieldName: 'vaccination_name',
  },
  {
    i18KeyName: 'drug',
    required: true,
    requestFieldName: 'drug_name',
  },
];
const VACCINATION_TEXTAREA_FIELDS: PetFormTextLayout[] = [
  {
    i18KeyName: 'notes',
    required: false,
    requestFieldName: 'additional_info',
  },
];

const TREATMENT_INPUT_FIELDS: PetFormTextLayout[] = [
  {
    i18KeyName: 'reason',
    required: true,
    requestFieldName: 'treatment_reason',
  },
  {
    i18KeyName: 'drug',
    required: true,
    requestFieldName: 'vaccination_name',
  },
  {
    i18KeyName: 'address',
    required: false,
    requestFieldName: 'veterinary_address',
  },
];
const TREATMENT_TEXTAREA_FIELDS: PetFormTextLayout[] = [
  {
    i18KeyName: 'notes',
    required: false,
    requestFieldName: 'additional_info',
  },
];
const VETERINARIAN_INPUT_FIELDS: PetFormTextLayout[] = [
  {
    i18KeyName: 'name',
    required: true,
    requestFieldName: 'petclinic_name',
  },
  {
    i18KeyName: 'address',
    required: false,
    requestFieldName: 'veterinary_address',
  },
  {
    i18KeyName: 'procedure',
    required: false,
    requestFieldName: 'procedure_name',
  },
];
const VETERINARIAN_TEXTAREA_FIELDS: PetFormTextLayout[] = [
  {
    i18KeyName: 'notes',
    required: false,
    requestFieldName: 'additional_info',
  },
];

const KIND_SELECT_OPTIONS: TArray[] = [
  {
    id: 36,
    i18KeyName: 'selects.kind.crows',
  },
  {
    id: 35,
    i18KeyName: 'selects.kind.owls',
  },
  {
    id: 34,
    i18KeyName: 'selects.kind.parrots',
  },
  {
    id: 33,
    i18KeyName: 'selects.kind.canaries',
  },
  {
    id: 32,
    i18KeyName: 'selects.kind.amadins',
  },
  {
    id: 31,
    i18KeyName: 'selects.kind.snakes',
  },
  {
    id: 30,
    i18KeyName: 'selects.kind.lizards',
  },
  {
    id: 29,
    i18KeyName: 'selects.kind.crocodiles',
  },
  {
    id: 28,
    i18KeyName: 'selects.kind.alligators',
  },
  {
    id: 27,
    i18KeyName: 'selects.kind.turtles',
  },
  {
    id: 26,
    i18KeyName: 'selects.kind.squirrels',
  },
  {
    id: 25,
    i18KeyName: 'selects.kind.guinea_pigs',
  },
  {
    id: 24,
    i18KeyName: 'selects.kind.mice',
  },
  {
    id: 23,
    i18KeyName: 'selects.kind.rats',
  },
  {
    id: 22,
    i18KeyName: 'selects.kind.chinchillas',
  },
  {
    id: 21,
    i18KeyName: 'selects.kind.hamsters',
  },
  {
    id: 20,
    i18KeyName: 'selects.kind.anteaters',
  },
  {
    id: 19,
    i18KeyName: 'selects.kind.bears',
  },
  {
    id: 18,
    i18KeyName: 'selects.kind.lynx',
  },
  {
    id: 17,
    i18KeyName: 'selects.kind.cougars',
  },
  {
    id: 16,
    i18KeyName: 'selects.kind.panthers',
  },
  {
    id: 15,
    i18KeyName: 'selects.kind.lions',
  },
  {
    id: 14,
    i18KeyName: 'selects.kind.tigers',
  },
  {
    id: 13,
    i18KeyName: 'selects.kind.fishes',
  },
  {
    id: 12,
    i18KeyName: 'selects.kind.rabbits',
  },
  {
    id: 11,
    i18KeyName: 'selects.kind.insects',
  },
  {
    id: 10,
    i18KeyName: 'selects.kind.amphibians',
  },
  {
    id: 9,
    i18KeyName: 'selects.kind.birds',
  },
  {
    id: 8,
    i18KeyName: 'selects.kind.reptiles',
  },
  {
    id: 7,
    i18KeyName: 'selects.kind.ferrets',
  },
  {
    id: 6,
    i18KeyName: 'selects.kind.monkeys',
  },
  {
    id: 5,
    i18KeyName: 'selects.kind.foxes',
  },
  {
    id: 4,
    i18KeyName: 'selects.kind.pigs',
  },
  {
    id: 3,
    i18KeyName: 'selects.kind.horses',
  },
  {
    id: 2,
    i18KeyName: 'selects.kind.cats',
  },
  {
    id: 1,
    i18KeyName: 'selects.kind.dogs',
  },
];
const LIVING_PLACE_OPTIONS: TArray[] = [
  {
    id: 'apartment',
    i18KeyName: 'selects.place.apartment',
  },
  {
    id: 'house',
    i18KeyName: 'selects.place.house',
  },
  {
    id: 'other',
    i18KeyName: 'selects.place.other',
  },
];

const TEMPERAMENT_OPTIONS: TArray[] = [
  {
    id: 'friendly',
    i18KeyName: 'selects.temperament.friendly',
  },
  {
    id: 'unfriendly',
    i18KeyName: 'selects.temperament.unfriendly',
  },
  {
    id: 'middle',
    i18KeyName: 'selects.temperament.middle',
  },
];

const initialCreatePetForm: TPetForm = {
  avatar: undefined,
  kind: null,
  name: '',
  birth: null,
  breed: undefined,
  gender: '',
  color: '',
  registration: null,
  special_signs: '',
  food: '',
  sterilization: 'no',
  living_place: '',
  outdoor_schedule: [],
  id_type: 'no chip',
  id_chip_stigma: '',
  temperament: '',
};

type TValue = { label: string; id: string | number; src?: string, i18KeyName: string }

export function useData() {
  const { t } = useTranslation();
  const selectKindOptions: TValue[] = sortSelectOptions(KIND_SELECT_OPTIONS, t).map((item: TArray) => ({
    id: item.id,
    i18KeyName: item.i18KeyName,
    label: t(item.i18KeyName),
  }));
  const selectLivingPlaceOptions: TArray[] = sortSelectOptions(LIVING_PLACE_OPTIONS, t).map((item: TArray) => ({
    id: item.id,
    i18KeyName: item.i18KeyName,
    name: t(item.i18KeyName),
  }));

  const selectTemperamentOptions = sortSelectOptions(TEMPERAMENT_OPTIONS, t).map((item: TArray) => ({
    id: item.id,
    name: t(item.i18KeyName),
  }));

  const genderOptions = [
    { label: t('petForm.male'), value: 'male' },
    { label: t('petForm.female'), value: 'female' },
  ];

  const foodOptions = [
    { label: t('petForm.natural'), value: 'natural' },
    { label: t('petForm.feed'), value: 'feed' },
    { label: t('petForm.mixed'), value: 'mixed' },
  ];

  const sterilizationOptions = [
    { label: t('petForm.yes'), value: 'yes', className: 'petPageFormRadioButtonGreen' },
    { label: t('petForm.no'), value: 'no', className: 'petPageFormRadioButtonRed' },
    { label: t('petForm.not_chosen'), value: 'not chosen' },
  ];

  const animalIdOptions = [
    {
      label: t('petForm.stigma'),
      value: PetFields.Stigma,
    },
    {
      label: t('petForm.chip'),
      value: PetFields.Chip,
    },
    {
      label: t('petForm.no_chip'),
      value: PetFields.NoChip,
    },
  ];

  const petEventsTypes: PetEvents[] = [
    {
      id: 0,
      icon: [allergy],
      title: 'allergies',
    },
    {
      id: 1,
      icon: [vaccine],
      title: 'vaccination',
    },
    {
      id: 2,
      icon: [parasite],
      title: 'treatment',
    },
    {
      id: 3,
      icon: [doctor],
      title: 'vet',
    },
    {
      id: 4,
      icon: [service],
      title: 'services',
    },
  ];

  const allergyForm = 'PetProfilePage.allergyForm.';
  const allergySelectFields: PetFormSelectField[] = [
    {
      fieldName: 'allergyType',
      title: t(`${allergyForm}allergyTypeTitle`),
      requestFieldName: PetEventTitleFields.ALLERGY,
      required: true,
      options: ALLERGY_TYPE_SELECT_OPTIONS.map(({ id, i18KeyName }: TArray) => ({
        id,
        name: t(`${allergyForm}allergyTypeOptions.${i18KeyName}`),
      })),
    },
  ];

  const [allergyInputFields, allergyTextareaFields] = useGetFieldLabels(
    ALLERGY_INPUT_FIELDS,
    ALLERGY_TEXTAREA_FIELDS,
    PetEventsTypes.ALLERGY,
    t,
  );
  const allergiesData: PetEventDetails = {
    type: PetEventsTypes.ALLERGY,
    needToDisablePast: false,
    buttonIcon: CircleWithPlus,
    pathForAdd: PetProfileRoutePath.ADD_ALLERGY,
    pathForBack: PetProfileRoutePath.ALLERGIES,
    pathForEdit: PetProfileRoutePath.EDIT_ALLERGY,
    addPageSelectFields: allergySelectFields,
    addPageInputFields: allergyInputFields,
    addPageTimeSetField: {
      time: 10 * 60,
      required: false,
    },
    addPageTextareaFields: allergyTextareaFields,
    viewDateField: {
      title: t('ViewEventPage.date'),
      required: true,
    },
    mainField: PetEventTitleFields.ALLERGY,
  };

  const [servicesInputFields, servicesTextareaFields] = useGetFieldLabels(
    SERVICES_INPUT_FIELDS,
    SERVICES_TEXTAREA_FIELDS,
    PetEventsTypes.SERVICE,
    t,
  );
  const additionalServicesData: PetEventDetails = {
    type: PetEventsTypes.SERVICE,
    needToDisablePast: true,
    buttonIcon: additionalServices,
    pathForAdd: PetProfileRoutePath.ADD_SERVICES,
    pathForBack: PetProfileRoutePath.SERVICES,
    pathForEdit: PetProfileRoutePath.EDIT_SERVICES,
    addPageInputFields: servicesInputFields,
    addPageTimeSetField: {
      time: 10 * 60,
      required: false,
    },
    addPageTextareaFields: servicesTextareaFields,
    viewDateField: {
      title: t('ViewEventPage.date'),
      required: true,
    },
    mainField: PetEventTitleFields.SERVICE,
  };

  const [vaccinationInputFields, vaccinationTextareaFields] = useGetFieldLabels(
    VACCINATION_INPUT_FIELDS,
    VACCINATION_TEXTAREA_FIELDS,
    PetEventsTypes.VACCINATION,
    t,
  );
  const vaccinationData: PetEventDetails = {
    type: PetEventsTypes.VACCINATION,
    needToDisablePast: false,
    buttonIcon: CircleWithPlus,
    pathForAdd: PetProfileRoutePath.ADD_VACCINATION,
    pathForBack: PetProfileRoutePath.VACCINATION,
    pathForEdit: PetProfileRoutePath.EDIT_VACCINATION,
    addPageInputFields: vaccinationInputFields,
    addPageTimeSetField: {
      time: 10 * 60,
      required: false,
    },
    addPageTextareaFields: vaccinationTextareaFields,
    viewDateField: {
      title: t('ViewEventPage.date'),
      required: true,
    },
    mainField: PetEventTitleFields.VACCINATION,
  };

  const [treatmentInputFields, treatmentTextareaFields] = useGetFieldLabels(
    TREATMENT_INPUT_FIELDS,
    TREATMENT_TEXTAREA_FIELDS,
    PetEventsTypes.TREATMENT,
    t,
  );
  const parasiteTreatmentData: PetEventDetails = {
    type: PetEventsTypes.TREATMENT,
    needToDisablePast: false,
    buttonIcon: CircleWithPlus,
    pathForAdd: PetProfileRoutePath.ADD_TREATMENT,
    pathForBack: PetProfileRoutePath.TREATMENT,
    pathForEdit: PetProfileRoutePath.EDIT_TREATMENT,
    addPageInputFields: treatmentInputFields,
    addPageTimeSetField: {
      time: 10 * 60,
      required: false,
    },
    addPageTextareaFields: treatmentTextareaFields,
    viewDateField: {
      title: t('ViewEventPage.date'),
      required: true,
    },
    mainField: PetEventTitleFields.TREATMENT,
  };

  const [veterinarianInputFields, veterinarianTextareaFields] = useGetFieldLabels(
    VETERINARIAN_INPUT_FIELDS,
    VETERINARIAN_TEXTAREA_FIELDS,
    PetEventsTypes.VETERINARIAN,
    t,
  );
  const vetData: PetEventDetails = {
    type: PetEventsTypes.VETERINARIAN,
    needToDisablePast: true,
    buttonIcon: additionalServices,
    pathForAdd: PetProfileRoutePath.ADD_VET,
    pathForBack: PetProfileRoutePath.VET,
    pathForEdit: PetProfileRoutePath.EDIT_VET,

    addPageInputFields: veterinarianInputFields,
    addPageTimeSetField: {
      time: 10 * 60,
      required: true,
    },
    addPageTextareaFields: veterinarianTextareaFields,
    viewDateField: {
      title: t('ViewEventPage.date'),
      required: true,
    },
    mainField: PetEventTitleFields.VETERINARIAN,
  };

  return {
    petDataState,
    allergiesData,
    additionalServicesData,
    vaccinationData,
    parasiteTreatmentData,
    vetData,
    petEventsTypes,
    initialCreatePetForm,
    selectLivingPlaceOptions,
    selectKindOptions,
    selectTemperamentOptions,
    genderOptions,
    foodOptions,
    sterilizationOptions,
    MIN_OUTDOOR_TIME,
    MAX_OUTDOOR_TIME,
    KIND_SELECT_OPTIONS,
    animalIdOptions,
  };
}
