import type { ReactElement } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import RouteGuard from '../shared/components/RouteGuard';
import { AuthRoutePath } from './utils/const/route-path';
import { ForgotPasswordPage } from './views/Forgot/ForgotPasswordPage/ForgotPasswordPage';
import { NewPasswordPage } from './views/Forgot/NewPasswordPage/NewPasswordPage';
import { LoginPage } from './views/Login/LoginPage';
import { PolicyPage } from './views/Policy/PolicyPage';
import { SignupPage } from './views/Signup/SignupPage';
import { VerifyPhonePage } from './views/VerifyPhone/VerifyPhonePage';
import { VerifyPhoneCode } from './views/VerifyPhoneCode/VerifyPhoneCode';

type PropsType = { url: string };

const AuthRoutes = ({ url }: PropsType): ReactElement => {
  return (
    <Switch>
      <RouteGuard path={`${url}/${AuthRoutePath.LOGIN}`} Component={LoginPage} />
      <RouteGuard path={`${url}/${AuthRoutePath.POLICY}`} Component={PolicyPage} />
      <RouteGuard path={`${url}/${AuthRoutePath.FORGOT}`} Component={ForgotPasswordPage} />
      <RouteGuard path={`${url}/${AuthRoutePath.SIGNUP}`} Component={SignupPage} />
      <RouteGuard path={`${url}/${AuthRoutePath.PHONE_VERIFY}`} Component={VerifyPhonePage} />
      <RouteGuard path={`${url}/${AuthRoutePath.CODE_VERIFY}`} Component={VerifyPhoneCode} />
      <RouteGuard path={`${url}/${AuthRoutePath.NEW_PASSWORD}`} Component={NewPasswordPage} />
      <Redirect from={'*'} to={'/'} />
    </Switch>
  );
};

export default AuthRoutes;
