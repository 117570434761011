import { useCallback } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useHistory } from 'react-router';
import { RequestState } from '../../../../../utils/enums';
import { useIsMounted } from '../../../../../utils/hooks';
import { useUpdate } from '../../utils/hooks/useUpdate';
import type { TEditProfileForm } from '../../utils/types/types';

export function useHandlers(): { handleSubmit: (data: TEditProfileForm) => Promise<void>; updateStatus: RequestState } {
  const isMounted = useIsMounted();
  const phone_number = localStorage.getItem('phone_number');
  const [updateStatus, onUpdate] = useUpdate(phone_number);
  const history = useHistory();
  const handleError = useErrorHandler();

  const handleSubmit = useCallback(
    async (data: TEditProfileForm): Promise<void> => {
      if (typeof data.avatar === 'string') {
        delete data.avatar;
      }
      const response = await onUpdate(data);
      if (!isMounted()) {
        return;
      }

      if (updateStatus === RequestState.Error) {
        handleError(response.error);
        return;
      }
      history.push('/profile/main');
    },
    [history, isMounted, onUpdate, updateStatus],
  );

  return { handleSubmit, updateStatus };
}
