import { Box, Button, IconButton, Stack, Typography, useTheme } from '@mui/material';
import type { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import appleLogo from '../../../../../assets/images/apple_logo.svg';
import facebookLogo from '../../../../../assets/images/facebook_logo.svg';
import header_logo from '../../../../../assets/images/header_img.png';
import { useCheckToken } from '../../../../../utils/hooks';
import { CustomFacebookLogin, CustomGoogleLogin } from './components';
import { useCheckLoggedInOnMount, useClearOnMount, useData, useRedirect } from './hooks';
import { styled } from '@mui/material';

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  background: 'rgb(211, 211, 211)',
  pointerEvents: 'none',
}));

export default function StartPage(): ReactElement {
  const { t } = useTranslation();
  const {
    backgroundImg,
    contentBorderRadius,
    backgroundSettings,
    contentBackground,
    phoneVerified,
    contentPosition,
    actionsBackground,
  } = useData();
  const { redirectToLogin, redirectToSignup } = useRedirect();
  const { tokenVerified, tokenVerificationState } = useCheckToken();
  const theme = useTheme();

  useClearOnMount();
  useCheckLoggedInOnMount(tokenVerificationState, tokenVerified, phoneVerified);

  return (
    <Box position='relative'>
      <Box
        alignItems='center'
        display='flex'
        height='100vh'
        justifyContent='center'
        position='absolute'
        width='100%'
        zIndex={0}>
        <img src={backgroundImg} alt='background' style={backgroundSettings} />
      </Box>
      <Box
        display='flex'
        flexDirection='column'
        height='100vh'
        maxWidth={400}
        margin={0}
        padding={2}
        position={contentPosition}
        right={0}
        sx={contentBackground}
        top={0}
        justifyContent='space-between'
        width='100%'>
        <Stack
          alignItems='center'
          direction='row'
          gap={2.2}
          marginBottom={4}
          marginTop={8}
          justifyContent='center'
          width='100%'>
          <img src={header_logo} alt='logo' />
          <Typography color={theme.palette.text.primary} fontFamily='Nunito' fontSize={41} fontWeight={800}>
            PETSLIFE
          </Typography>
        </Stack>
        <Stack
          id='topButtonsContainer'
          alignItems='center'
          alignSelf='stretch'
          borderRadius={contentBorderRadius}
          gap={1.6}
          marginBottom={4}
          padding={1.6}
          width='100%'
          sx={actionsBackground}>
          <Button variant='primaryContained' onClick={redirectToSignup}>
            <Typography color='#fff' fontSize={16} fontWeight={700} fontFamily='Nunito'>
              {t('startPage.signup')}
            </Typography>
          </Button>
          <Button className='mt-3' variant='secondaryContained' onClick={redirectToLogin}>
            <Typography color={theme.palette.primary.main} fontSize={16} fontWeight={700} fontFamily='Nunito'>
              {t('startPage.login')}
            </Typography>
          </Button>
          <Typography component='span' zIndex={1000} color={theme.palette.text.primary}>
            {t('startPage.or')}
          </Typography>
          <Stack direction='row' gap={2} alignItems='center' justifyContent='center' id='bottomButtonsContainer'>
            <Box
              alignItems='center'
              bgcolor='rgba(243, 247, 248, 1)'
              borderRadius='50%'
              display='flex'
              height={50}
              justifyContent='center'
              width={50}>
              <img src={facebookLogo} alt='facebook login button' />
              <Box
                alignItems='center'
                display='flex'
                height='25px'
                justifyContent='center'
                overflow='hidden'
                position='absolute'
                sx={{ opacity: 0 }}
                width='25px'>
                <CustomFacebookLogin />
              </Box>
            </Box>
            <CustomGoogleLogin />
            <CustomIconButton color='buttonBg' size='large'>
              <img src={appleLogo} alt='apple id login button' width='25px' />
            </CustomIconButton>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
