import React, { useCallback, useMemo, useState } from 'react';

export type TCustomSearchSelectHandlers = {
  isOpen: boolean;
  openSelect: () => void;
  closeSelect: () => void;
  // tslint:disable-next-line:no-any
  visibleOptions: any;
  changeSearchValue: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // tslint:disable-next-line:no-any
  onClickOption: (e: any) => void;
};

export type TCustomSearchSelectHandlersParams = {
  // tslint:disable-next-line:no-any
  options: any[];
  // tslint:disable-next-line:no-any
  onClick: (e: any) => void;
  // tslint:disable-next-line:no-any
  searchMethod: (searchValue: any, item: any) => boolean;
  // tslint:disable-next-line:no-any
};

export function useHandlers({
  options,
  onClick,
  searchMethod,
}: TCustomSearchSelectHandlersParams): TCustomSearchSelectHandlers {
  const [isOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const openSelect = useCallback((): void => {
    setOpen(true);
  }, []);
  const closeSelect = useCallback((): void => {
    setOpen(false);
  }, []);

  const visibleOptions = useMemo((): typeof options => {
    return options.filter((option): boolean => searchMethod(searchValue, option));
  }, [options, searchMethod, searchValue]);

  const changeSearchValue = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.target.value);
  }, []);

  const onClickOption = useCallback(
    (e): void => {
      onClick(visibleOptions[e.target.parentNode.getAttribute('data-id')]);
      closeSelect();
    },
    [closeSelect, onClick, visibleOptions],
  );

  return { isOpen, openSelect, closeSelect, visibleOptions, onClickOption, changeSearchValue };
}
