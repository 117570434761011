import type {
  AnyEventTypeResponse,
  TDailyEventsInitial,
  TDailyEventsRequestData,
  TDailyEventsResponseData,
} from '../types';
import { RequestState } from '../../../../utils/enums';
import { UrlsType, get } from '../../../../utils/services/request';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { notificationSelector } from '../../../../utils/selectors';
import { useIsMounted } from '../../../../utils/hooks/useIsMounted';
import { useRecoilState } from 'recoil';
import { tokenState } from '../../../../utils/atoms';

export function useFetchEventListDataOnMount(
  requestKey: UrlsType,
  dailyEventsRequestData: TDailyEventsRequestData,
  setNeedToCheckData: Dispatch<SetStateAction<boolean>>
): [RequestState, TDailyEventsResponseData | TDailyEventsInitial] {
  const isMounted = useIsMounted();
  const [requestStatus, setRequestStatus] = useState<RequestState>(RequestState.Idle);
  const [fetchedEventsData, setFetchedEventsData] = useState<TDailyEventsResponseData | TDailyEventsInitial>({
    count: null,
    next: null,
    // previous: null,
    results: {
      result: [],
      previous: null,
    },
  });
  const [, addNotification] = useRecoilState(notificationSelector);

  const [token] = useRecoilState(tokenState);

  const fetchEventsData = useCallback(async () => {
    setRequestStatus(RequestState.Pending);

    if (!token) {
      addNotification({
        title: 'Error',
        text: 'Access denied',
        type: 'error',
      });
      setRequestStatus(RequestState.Error);
      return;
    }

    const response = await get(requestKey, dailyEventsRequestData, token, '');
    if (!isMounted()) return;

    if (response.error) {
      addNotification({
        title: response.error.response?.status,
        text: response.error.response?.data,
        type: 'error',
      });
      setRequestStatus(RequestState.Error);
      return response.error;
    }
    setRequestStatus(RequestState.Success);
    // setFetchedEventsData(response.data.result ?? response.data as AnyEventTypeResponse & TDayStatus[]);
    setFetchedEventsData(response.data as TDailyEventsResponseData);
    setNeedToCheckData(() => false)

  }, [addNotification, dailyEventsRequestData, isMounted, requestKey, setNeedToCheckData, token]);

  useEffect(() => {
    fetchEventsData();
  }, [fetchEventsData]);
  return [requestStatus, fetchedEventsData];
}
