import { LocalStorageKeys } from '../../../../../utils/enums';
import { LocalStorageService } from '../../../../../utils/services';
import type { TVerifyPhoneForm } from '../../types/types';

const initialValues = {
  phone: LocalStorageService.getItem(LocalStorageKeys.Phone) || '',
  code: LocalStorageService.getItem(LocalStorageKeys.Code) || '',
  terms: false,
  recaptcha_token: '',
};

export function useData(): { initialValues: TVerifyPhoneForm } {
  return { initialValues };
}
