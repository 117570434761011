import { RefObject, TouchEventHandler, useCallback, useState } from 'react';

export function useEventHandlers(eventRef: RefObject<HTMLDivElement>) {
  const [moveEvent, setMoveEvent] = useState<any | null>(null);
  const [direction, setDirection] = useState<number>(0);

  const startMove: TouchEventHandler<HTMLDivElement> = useCallback(event => {
    setMoveEvent(event);
  }, []);

  const move: TouchEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (moveEvent) {
        setDirection(event.touches[0].pageX - moveEvent.touches[0].pageX);
      }
    },
    [moveEvent],
  );

  const endMove = useCallback(() => {
    if (direction) {
      direction < 0 ? eventRef.current?.scroll(100, 0) : eventRef.current?.scroll(-100, 0);
      setMoveEvent(null);
    }
  }, [direction, eventRef]);

  return { startMove, move, endMove };
}
